import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../../context/AccountContext";
import {
  getCalculationsPDF,
  getPortfolioReport,
  getPortfolioResult,
  getTransactionsPDF,
} from "../../../services/PortfolioService";
import BtnExportData from "./BtnExportData";
import {Box, Button, Skeleton, Stack, Typography, Unstable_Grid2 as Grid, useTheme,} from "@mui/material";
import YearInfor from "./YearInfor";
import AccordionCustom from "./AccordionCustom";
import {useRef} from "react";
import {StyledCircularProgress} from "src/components/StyledComponents/StyledCircularProgress";
import {isMobile} from "react-device-detect";

function Skeletons() {
    var t = [];
    for (let i = 0; i < 3; i++) {
        t.push(
            <Skeleton
                key={i + "Skeletons"}
                variant="rounded"
                sx={{width: isMobile ? "100%" : "33%"}}
                height="220px"
            />
        );
    }
    return t;
}

export default function ReportsTable(filter) {
    const {t} = useTranslation();
    const currentYearDownload = useRef(0);
    const theme = useTheme()
    // const [currentYearDownload, setCurrentYearDownload] = useState(0);
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} =
        useAuth0();
    const {currentAccount} = useAccountContext();
    const {data: resultData, isLoading: isLoadingResultData} = useQuery({
        queryKey: ["getPortfolioResult", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const data = await getPortfolioResult(
                token,
                user?.sub,
                currentAccount.accountId,
                true
            );

            return data;
        },
        enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
    });

    const {
        fetchStatus: fetchStatusGetTransactionsPDF,
        refetch: refetchTransactionsPDF,
    } = useQuery({
        queryKey: ["getTransactionsPDF", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const result = await getTransactionsPDF(
                token,
                user?.sub,
                currentAccount.accountId
            );
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Transaction Report.pdf`);
            document.body.appendChild(link);
            link.click();
            return {};
        },
        enabled: false,
    });

    const {
        fetchStatus: fetchStatusGetCalculationsPDF,
        refetch: refetchCalculationsPDF,
    } = useQuery({
        queryKey: ["calulationsPDF"],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const result = await getCalculationsPDF(
                token,
                user?.sub,
                currentAccount.accountId,
                currentYearDownload.current
            );
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `Calculations Report Year ${currentYearDownload.current}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            return {};
        },
        enabled: false,
    });

    const {
        fetchStatus: fetchStatusGetPortfolioReport,
        refetch: refetchPortfolioReport,
    } = useQuery({
        queryKey: ["portfolioReport"],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const result = await getPortfolioReport(
                token,
                user?.sub,
                currentAccount.accountId,
                currentYearDownload.current
            );
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `report year ${currentYearDownload.current}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            return {};
        },
        enabled: false,
    });

    const handleGetTransactionsPDF = () => {
        currentYearDownload.current = 1;

        console.log("useRef", currentYearDownload.current);
        refetchTransactionsPDF().finally(() => (currentYearDownload.current = 0));
    };

    const handleCalculationsPDF = (data) => {
        currentYearDownload.current = data;
        // setCurrentYearDownload(data);
        setTimeout(() => {
            refetchCalculationsPDF().finally(() => (currentYearDownload.current = 0));
        }, 1000);
    };

    const handlePrint = (data) => {
        currentYearDownload.current = data;
        // setCurrentYearDownload(data);
        setTimeout(() => {
            refetchPortfolioReport().finally(() => (currentYearDownload.current = 0));
        }, 1000);
    };

    return (
        <Stack
            spacing={2}
            sx={{
                p: {xs: 1, md: 4},
                border: `1px solid ${theme.palette.primary[50]}`,
                borderRadius: "1.5rem",
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows.sm,
            }}
        >
            <Stack mb={2} spacing={2} direction="row" justifyContent="space-between">
                <Typography sx={{fontSize: "24px", fontWeight: "600"}}>
                    {t("reports.summary")}
                </Typography>

                <Stack direction={"row"} spacing={2}>
                    <Button
                        onClick={handleGetTransactionsPDF}
                        sx={{
                            backgroundColor: theme.palette.primary[900],
                            color: 'white',
                            padding: '10px 10px',
                            fontSize: '0.85rem',
                            fontWeight: 500,
                            borderRadius: '9999px',
                            textTransform: "none",
                            boxShadow: theme.shadows.sm,
                            '&:hover': {
                                backgroundColor: theme.palette.primary[600],
                            },
                            '&:focusVisible': {
                                outline: '2px solid',
                                outlineColor: theme.palette.primary[600],
                                outlineOffset: 2,
                            },
                        }}
                    >
                        {t("reports.downloadTransactions")}
                    </Button>
                </Stack>
            </Stack>
            {isLoadingResultData || resultData?.length === 0 ? (
                <Stack
                    direction={isMobile ? "column" : "row"}
                    spacing={2}
                    sx={{
                        pb: 4,
                        px: 0,
                        width: "100%",
                    }}
                >
                    <Skeletons></Skeletons>
                </Stack>
            ) : (
                <Box sx={{width: "100%"}}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{xs: 1, sm: 2, md: 3, lg: 5}}
                    >
                        {Array.isArray(resultData) &&
                            resultData?.length > 0 &&
                            resultData.map((item, index) => {
                                return (
                                    <Grid item key={index} xs={12} md={6} lg={4}>
                                        <AccordionCustom item={item}>
                                            <YearInfor data={item}/>
                                            <BtnExportData
                                                data={item.year}
                                                onPrint={handlePrint}
                                                onCalculationsPDF={handleCalculationsPDF}
                                            />
                                        </AccordionCustom>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Box>
            )}
            {(fetchStatusGetTransactionsPDF === "fetching" ||
                fetchStatusGetCalculationsPDF === "fetching" ||
                fetchStatusGetPortfolioReport === "fetching") && (
                <StyledCircularProgress/>
            )}
        </Stack>
    );
}
