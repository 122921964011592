import React, {useEffect, useState} from 'react';
import Markdown from 'react-markdown';

function TermsAndConditionsMD(props) {
    const [markdown, setMarkdown] = useState("")

    useEffect(() => {
        fetch('/t&c.md')
            .then((response) => response.text())
            .then((text) => setMarkdown(text))
            .catch((error) => console.error(error));
    }, []);

    return (
        <Markdown>{markdown}</Markdown>
    );
}

export default TermsAndConditionsMD;