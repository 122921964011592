import {Stack, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import InviteUserModal from "./UserManagement/InviteUserModal";
import {userColumns} from "./UserManagement/UserColumns";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {useGetUserDataByAccountId} from "src/hooks/UserHook";

export default function UsersTable() {
    const {t} = useTranslation();
    const theme = useTheme()

    const userDataByAccountId = useGetUserDataByAccountId();

    const checkValueExits = (obj) => {
        let result = ''
        if (obj.firstname === null) {
            result = obj.email
        } else {
            result = obj.firstname
        }
        return result
    }

    const accountsWithFullName = userDataByAccountId?.data?.map(obj => ({
        ...obj,
        fullName: checkValueExits(obj)
    })) || [];

    return (
        <Stack
            spacing={2} direction={"column"} maxWidth={"xl"} p={3} sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows.sm,
            borderRadius: "1.5rem",
            border: `1px solid ${theme.palette.primary[50]}`
        }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography variant={"subtitle1"}>
                    {t("settings.accountDetailsPage.manageUsers")}
                </Typography>
                <Stack direction="row" spacing={2}>
                    <InviteUserModal/>
                </Stack>
            </Stack>
            <div style={{height: "30rem"}}>
                <DataGridPremium columns={userColumns(t)} rows={accountsWithFullName} sx={{
                    outline: 'none',
                    height: "100%",
                    overflow: 'hidden',  // Ensure overflow is handled
                    '& .MuiDataGrid-columnHeader': {
                        overflow: 'hidden', // Handle header overflow
                    },
                }} sortable={false}/>
            </div>
        </Stack>
    );
}
