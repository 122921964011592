export const CryptoExchangeData = [
    {
        cryptoExchangeName: "NBX",
        cryptoExchangeColor: "#BEFB38",
        cryptoExchangeLogo: "logo_nbx.svg",
        cryptoExchangeLink: "https://nbx.com/",
        exchange: "NBX",
        typeUpload: "uploadNBX",
        styleIcon: {},
        styleHeader: {},
        locationConnectUrl: "",
    },
    {
        cryptoExchangeName: "FIRI",
        cryptoExchangeColor: "#474AEE",
        cryptoExchangeLogo: "logo_firi.svg",
        cryptoExchangeLink: "https://firi.com/no",
        exchange: "FIRI",
        typeUpload: "uploadFiri",
        styleIcon: {},
        styleHeader: {},
        locationConnectUrl: "",
    },
    {
        cryptoExchangeName: "COINBASE",
        cryptoExchangeColor: "#474AEE",
        cryptoExchangeLogo: "logo_coinbase.svg",
        cryptoExchangeLink: "https://www.coinbase.com/",
        exchange: "COINBASE",
        typeUpload: "uploadCoinbase",
        styleIcon: {},
        styleHeader: {},
        locationConnectUrl: `https://www.coinbase.com/oauth/authorize?client_id=a1045c6764832f038be22546105adba78d57ec6bb18b6491e41426040fb0b921&redirect_uri=https%3A%2f%2f${process.env.REACT_APP_COINBASE_REDIRECT_URI}%2fexchangeOAuthResponse&response_type=code&account=all&scope=wallet:accounts:read,wallet:addresses:read,wallet:orders:read,wallet:transactions:read`,
    },
    {
        cryptoExchangeName: "BINACE",
        cryptoExchangeColor: "#513E8F",
        cryptoExchangeLogo: "logo_binance.svg",
        cryptoExchangeLink: "https://www.binance.com/",
        exchange: "BINANCE",
        typeUpload: "uploadBinance",
        styleIcon: {width: "144px"},
        locationConnectUrl: "",
    },
    {
        cryptoExchangeName: "CRYPTO",
        cryptoExchangeColor: "#000",
        cryptoExchangeLogo: "logo_crypto.svg",
        cryptoExchangeLink: "https://crypto.com/exchange/",
        exchange: "CRYPTOCOM",
        typeUpload: "uploadCryptoCom",
        styleIcon: {},
        locationConnectUrl: "",
    },
    {
        cryptoExchangeName: "KRAKEN",
        cryptoExchangeColor: "#5848d5",
        cryptoExchangeLogo: "logo_kraken.svg",
        cryptoExchangeLink: "https://www.kraken.com/",
        exchange: "KRAKEN",
        typeUpload: "uploadKraken",
        styleIcon: {
            width: "60px",
        },
        locationConnectUrl: "",
    },/*
  {
    cryptoExchangeName: "KUCOIN",
    cryptoExchangeColor: "#e8faf2",
    cryptoExchangeLogo: "logo_kucoin.svg",
    cryptoExchangeLink: "https://www.kucoin.com/",
    exchange: "KUCOIN",
    typeUpload: "uploadKucoin",
    styleIcon: {},
    locationConnectUrl: "",
  },
  {
    cryptoExchangeName: "UNISWAP",
    cryptoExchangeColor: "#2e5e8d",
    cryptoExchangeLogo: "logo_uniswap.svg",
    cryptoExchangeLink: "https://uniswap.org/",
    exchange: "UNISWAP",
    typeUpload: "uploadUniSwaxp",
    styleIcon: {},
    locationConnectUrl: "",
  },
  {
    cryptoExchangeName: "PANCAKESWAP",
    cryptoExchangeColor: "#eee1f3",
    cryptoExchangeLogo: "logo_pancakeswap.svg",
    cryptoExchangeLink: "https://pancakeswap.finance/",
    exchange: "PANCAKESWAP",
    typeUpload: "uploadPanCakeSwap",
    styleIcon: {},
    locationConnectUrl: "",
  },
  {
    cryptoExchangeName: "SUSHI",
    cryptoExchangeColor: "red",
    cryptoExchangeLogo: "logo_sushi.svg",
    cryptoExchangeLink: "https://www.sushi.com/swap",
    exchange: "SUSHI",
    typeUpload: "uploadSushi",
    styleIcon: {},
    locationConnectUrl: "",
  },
  {
    cryptoExchangeName: "BITRUE",
    cryptoExchangeColor: "#3a2e55",
    cryptoExchangeLogo: "logo_bitrue.svg",
    cryptoExchangeLink: "https://www.bitrue.com/",
    exchange: "BITRUE",
    typeUpload: "uploadBitrue",
    styleIcon: {},
    locationConnectUrl: "",
  },*/

];
