import { APIBASE_B4BAPPLICATION } from "./ApiConfig";

// PortfolioService
export const URL_GET_PORTFOLIO_OVERVIEW = (accountId) =>
  `${APIBASE_B4BAPPLICATION}/api/portfolio/${accountId}/overview`;

export const URL_GET_ALLOCATION_GRAPH_DATA = (accountId) =>
  `${APIBASE_B4BAPPLICATION}/api/graph/${accountId}/allocationgraphdata`;

export const URL_GET_PORTFOLIO_RESULT = (accountId) =>
  `${APIBASE_B4BAPPLICATION}/api/portfolio/${accountId}/result`;

export const URL_GET_TRANSACTIONS_REPORT = (accountId) =>
`${APIBASE_B4BAPPLICATION}/api/portfolio/${accountId}/transactionreport`;

export const URL_GET_CALCULATIONS_REPORT = (accountId, untilDate) =>
`${APIBASE_B4BAPPLICATION}/api/portfolio/${accountId}/calculationreport?untilDate=${untilDate}`;

export const URL_GET_PORTFOLIO_REPORT = (accountId, data) =>
`${APIBASE_B4BAPPLICATION}/api/portfolio/${accountId}/report${data ? `?year=${data}` : ''}`;

export const URL_GET_PORTFOLIO_GRAPH = (
  accountId,
  typeTimeSpan,
  startDate,
  endDate
) => {
  if (typeTimeSpan !== "YEAR") {
    return `${APIBASE_B4BAPPLICATION}/api/graph/${accountId}/portfoliovaluegraph?typeTimeSpan=${typeTimeSpan}&startDate=${startDate}&endDate=${endDate}`;
  } else {
    return `${APIBASE_B4BAPPLICATION}/api/graph/${accountId}/portfoliovaluegraph?typeTimeSpan=YEAR`;
  }
};
// `${APIBASE_B4BAPPLICATION}/api/graph/${accountId}/portfoliovaluegraph`;

export const URL_GET_TIME_SERIES = `${APIBASE_B4BAPPLICATION}/api/portfolio/overview/timeSeries`;

// export const URL_ADD_COIN_HIDE = `${APIBASE_B4BAPPLICATION}/api/transactions/addCoinHidden`;
export const URL_ADD_COIN_HIDE = `${APIBASE_B4BAPPLICATION}/api/transactions/hideCoin`;

export const URL_GET_RESULT_AND_TAX_GRAPH = (accountId) =>
  `${APIBASE_B4BAPPLICATION}/api/graph/${accountId}/resultAndTaxGraph`;
export const URL_GET_MESSAGE_FOR_USER = (userId) =>
    `${APIBASE_B4BAPPLICATION}/api/user/${userId}/Message`;

// USER
export const URL_GET_USER_BY_AUTH_ID = (auth0Id) =>
  `${APIBASE_B4BAPPLICATION}/api/user/${encodeURIComponent(auth0Id)}`;

export const URL_GET_USER_BY_ACCOUNT_ID = (accountid) =>
  `${APIBASE_B4BAPPLICATION}/api/account/${accountid}/users`;

export const URL_SAVE_USER = (userId) =>
  `${APIBASE_B4BAPPLICATION}/api/user/${userId}`;
export const URL_UPDATE_DISPLAY_WIZARD = (userId) =>
  `${APIBASE_B4BAPPLICATION}/api/user/${userId}/displayWizard`;
