import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider,} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {useGetUserData} from "./hooks/UserHook";
import RootLayout from "./components/RootLayout/RootLayout";
import FrontPage from "./pages/NotAuthenticated/Frontpage/Frontpage";
import Overview from "./pages/Overview/Overview";
import Transactions from "./pages/Transactions/Transactions";
import Settings from "./pages/Settings/Settings";
import AccountDetails from "./pages/Settings/AccountDetails/AccountDetails";
import BlockchainAddress from "./pages/Settings/BlockchainAddress/BlockchainAddress";
import BlockchainExchange from "./pages/Settings/BlockchainExchange/ExchangeSettings";
import SuccessfulStripePayment from "./pages/RegisterUser/StripePaymentSuccess";
import ExchangeAuth02Confirmation from "./pages/Settings/BlockchainExchange/ExchangeAuth02Confirmation";
import ExchangeTransactions from "./pages/ExchangeTransactions/ExchangeTransactions";
import Reports from "./pages/Reports/Reports";
import HelpPage from "./pages/HelpPage/Help";
import SolutionPage from "./pages/NotAuthenticated/SolutionPage/SolutionPage";
import PricesPage from "./pages/NotAuthenticated/PricesPage/PricesPage";
import AboutUsPage from "./pages/NotAuthenticated/AboutUsPage/AboutUsPage";
import {StyledCircularProgress} from "./components/StyledComponents/StyledCircularProgress";

import "./App.css";
import UserSettings from "src/pages/Settings/UserSettings/UserSettings";
import TermsAndConditionsPage from "src/pages/TermsAndConditions/TermsAndConditionsPage";

function App() {
    const {isAuthenticated, isLoading} = useAuth0();
    const userData = useGetUserData();

    const PrivateRoute = (props) => {
        if (!isLoading && !isAuthenticated) return <Navigate to="/"/>;
        else if (userData.isLoading) return <StyledCircularProgress/>;
        return <div>{props.children}</div>;
    };

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RootLayout/>}>
                <Route index element={<FrontPage/>}></Route>

                <Route path="solution" element={<SolutionPage/>}/>
                <Route path="prices" element={<PricesPage/>}/>
                <Route path="aboutUs" element={<AboutUsPage/>}/>
                <Route
                    path="/termsAndConditions"
                    element={
                        <PrivateRoute>
                            <TermsAndConditionsPage/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/overview"
                    element={
                        <PrivateRoute>
                            <Overview/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/transactions"
                    element={
                        <PrivateRoute>
                            <Transactions/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/exchangeTransactions"
                    element={
                        <PrivateRoute>
                            <ExchangeTransactions/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/reports"
                    element={
                        <PrivateRoute>
                            <Reports/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/help"
                    element={
                        <PrivateRoute>
                            <HelpPage/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <Settings/>
                        </PrivateRoute>
                    }
                >
                    <Route index element={<Navigate to="accountDetails" replace/>}/>
                    <Route
                        path="accountDetails"
                        element={
                            <PrivateRoute>
                                <AccountDetails/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="blockchainAddress"
                        element={
                            <PrivateRoute>
                                <BlockchainAddress/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="blockchainExchange"
                        element={
                            <PrivateRoute>
                                <BlockchainExchange/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="userSettings"
                        element={
                            <PrivateRoute>
                                <UserSettings/>
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route
                    path="/successfulPayment"
                    element={
                        <PrivateRoute>
                            <SuccessfulStripePayment/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/exchangeOAuthResponse"
                    element={
                        <PrivateRoute>
                            <ExchangeAuth02Confirmation/>
                        </PrivateRoute>
                    }
                />
            </Route>,
        ),
    );

    return <RouterProvider router={router}/>;
}

export default App;
