import {useTranslation} from "react-i18next";
import {Box, Container, Stack, Typography, useTheme} from "@mui/material";
import {FilledButton} from "src/components/StyledComponents/StyledButtons";
import woman_on_laptop from "src/assets/images/image27.jpeg"
import woman_on_laptop_mobile from "src/assets/images/image27Mobile.png"
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

export default function RegistrationCTA() {
    const {t} = useTranslation();
    const theme = useTheme()
    return (
        <Box
            component="section"
            sx={{
                py: 8, // Padding top and bottom
                backgroundColor: theme.palette.background[250],
                overflow: "hidden",
            }}
        >
            <Container maxWidth="xxl" sx={{position: "relative", xxl: "0px"}}>
                <Grid
                    container
                    sx={{
                        position: "relative",
                        maxWidth: "100%",
                        borderRadius: "1.5rem",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{

                            position: "relative",
                            width: "100%",
                            maxHeight: "46rem"
                        }}
                    >
                        <Box
                            component={"img"}
                            src={woman_on_laptop}
                            alt="Woman on laptop"
                            sx={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                                borderRadius: "1.5rem",
                                display: {xs: "none", lg: "block"}
                            }}
                        />
                        <Box
                            component={"img"}
                            src={woman_on_laptop_mobile}
                            alt="Woman on laptop"
                            sx={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "1.5rem",
                                display: {xs: "block", lg: "none"}
                            }}
                        />
                    </Grid>

                    <Stack
                        sx={{
                            position: {xs: "block", xsm: "absolute"},
                            right: 0,
                            top: 0,
                            height: "100%",
                            width: {xs: "100%", lg: "60%"},
                            margin: "0px auto",
                            p: {xs: 2, lg: 10},
                            color: {xsm: "#ffffff"},
                            //borderRadius: "1.5rem",
                            background: {
                                xsm: "radial-gradient(circle at top left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2))",
                                lg: "none"
                            },
                            borderRadius: "1.5rem",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center"
                        }}
                    >
                        <Typography
                            gutterBottom
                            variant={"h2"}
                            mt={2}
                        >
                            {t("frontPage.section5header")}
                        </Typography>
                        <Typography
                            variant={"h6"}
                            component={"p"}
                            gutterBottom
                        >
                            {t("frontPage.section5content")}
                        </Typography>
                        <FilledButton sx={{margin: "1rem auto"}}>
                            {t("frontPage.register")}
                        </FilledButton>
                    </Stack>
                </Grid>
            </Container>
        </Box>
    );
}