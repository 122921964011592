import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import React, { useState, useMemo } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Sector,
} from "recharts";
import { useAccountContext } from "../../../context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { allocationGraphData } from "../../../services/PortfolioService";
import { separateCommaTrans } from "../../../common/numberFormat";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { StyledCardHeader } from "../styles";
import Lottie from "lottie-react";
import IconChartLoading from "src/assets/iconJson/IconChartLoading.json";

let colorsUsed = [];

const randColor = () => {
  let colorRd =
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
      .toUpperCase();
  if (colorsUsed.includes(colorRd)) {
    randColor();
  } else {
    colorsUsed.push(colorRd);
    return colorRd;
  }
  return "#000";
};

function isLightColor(color) {
  // Check the format of the color, HEX or RGB?
  let r = color[1];
  let g = color[2];
  let b = color[3];
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true;
  } else {
    return false;
  }
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  allocationPercentage,
  fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN) - 25;
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const isLightColorCheck = isLightColor(fill);

  if (allocationPercentage.toFixed(0) < 1) {
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      fill={isLightColorCheck ? "black" : "white"}
      fontSize={24}
      fontWeight={600}
      textAnchor="center"
      dominantBaseline="central"
    >
      {`${allocationPercentage.toFixed(0)}%`}
    </text>
  );
};

export const OverviewAllocation = (props) => {
  const { sx = {} } = props;
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [indexActive, setIndexActive] = useState(0);
  const { currentAccount } = useAccountContext();
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();

  const { data: allocationData, isLoading: isLoadingAllocationData } = useQuery(
    {
      queryKey: ["allocationGraphData", currentAccount.accountId],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        let data = await allocationGraphData(
          token,
          user?.sub,
          currentAccount.accountId
        );
        let allocationListTemp = data?.allocationList || [];
        for (let i = 0; i < allocationListTemp.length; i++) {
          allocationListTemp[i] = {
            ...allocationListTemp[i],
            color: randColor(),
          };
        }
        return { ...data, allocationList: allocationListTemp };
      },
      enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
          fontSize={28}
          fontWeight={600}
        >
          {new Date(allocationData?.date).getFullYear()}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 10}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataTooltip = payload[0].payload || {};
      return (
        <Stack
          sx={{
            bgcolor: "white",
            py: "16px",
            px: "24px",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #D6DAEB",
            borderRadius: "16px",
          }}
        >
          <Typography sx={{ fontWeight: "600", fontSize: "24px" }}>
            {dataTooltip.coin?.alternativeName
              ?.toLowerCase()
              .replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
          </Typography>
          <Typography
            sx={{ fontWeight: "400", fontSize: "18px", color: "#75809F" }}
          >
            {separateCommaTrans(dataTooltip.allocationValue, 2, 2)}
          </Typography>
          <Typography sx={{ fontWeight: "600", fontSize: "48px" }}>
            {separateCommaTrans(dataTooltip.allocationPercentage, 0, 0)}%
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  const memoizedValue = useMemo(() => {
    const data = allocationData?.allocationList || [];
    let indexStart = page * rowsPerPage;
    let indexEnd = rowsPerPage * (page + 1);

    return data.slice(indexStart, indexEnd);
  }, [allocationData, page, rowsPerPage, currentAccount]);

  return (
    <Card sx={sx} variant="outlined">
      <StyledCardHeader
        title={t("overview.allocation")}
        subheader="Subheader"
      />
      <CardContent>
        {isLoadingAllocationData ? (
          <Stack width="100%" minWidth={200} minHeight={isMobile ? 400 : 600}>
            <Lottie
              animationData={IconChartLoading}
              style={{ height: isMobile ? 400 : 600 }}
            />
          </Stack>
        ) : (
          <ResponsiveContainer
            width="100%"
            minWidth={200}
            minHeight={isMobile ? 400 : 600}
          >
            <PieChart>
              <Pie
                activeIndex={indexActive}
                activeShape={renderActiveShape}
                data={allocationData?.allocationList || []}
                cx="50%"
                cy="50%"
                label={renderCustomizedLabel}
                labelLine={false}
                innerRadius="50%"
                outerRadius="90%"
                fill="#8884d8"
                dataKey="allocationPercentage"
                onMouseEnter={(_, index) => setIndexActive(index)}
              >
                {allocationData?.allocationList?.map((allocation, index) => (
                  <Cell
                    key={"cell-" + index}
                    fill={allocation.color}
                    zIndex={10}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        )}

        <Grid container mt={3} ml={1} rowSpacing={2} columnSpacing={3} pr={2}>
          {memoizedValue.map((item, index) => {
            return (
              <Stack
                key={index + "OverviewAllocation"}
                sx={{ height: "50px", width: "100%" }}
              >
                <Stack direction={"row"}>
                  <Typography
                    sx={{
                      color: "#090113",
                      fontSize: "18px",
                      fontWeight: "500",
                      flexGrow: 1,
                    }}
                  >
                    {item.coin.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      color: "#090113",
                      fontSize: "18px",
                    }}
                    mr={4}
                  >
                    {`${item.allocationPercentage.toFixed(0)}%`}
                  </Typography>

                  <Typography
                    sx={{
                      textAlign: "right",
                      fontWeight: "400",
                      color: "#75809F",
                      minWidth: "100px",
                      fontSize: "18px",
                    }}
                  >
                    {separateCommaTrans(item.allocationValue, 0, 2)}
                  </Typography>
                </Stack>

                <Stack
                  bgcolor={"#D9D9D9"}
                  sx={{
                    width: "100%",
                    height: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Stack
                    sx={{
                      bgcolor: item.color,
                      width: item.allocationPercentage.toFixed(0) + "%",
                      height: "16px",
                      borderRadius: "8px",
                    }}
                  ></Stack>
                </Stack>
              </Stack>
            );
          })}
          {allocationData && (
            <TablePagination
              component="div"
              count={allocationData?.allocationList.length}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

OverviewAllocation.propTypes = {
  sx: PropTypes.object,
};
