import elwImage from "src/assets/png/elw.jpg";
import towImage from "src/assets/png/tow.jpg";
import sebImage from "src/assets/png/seb.png";

export const dataContent = [
  {
    contentHeader: "aboutUs.dataContent1.contentHeader",
    contentBodyUpper: "aboutUs.dataContent1.contentBodyUpper",
    contentBodyLower: "aboutUs.dataContent1.contentBodyLower",
  },
  {
    contentHeader: "aboutUs.dataContent2.contentHeader",
    contentBodyUpper: "aboutUs.dataContent2.contentBodyUpper",
    contentBodyLower: "aboutUs.dataContent2.contentBodyLower",
  },
  {
    contentHeader: "aboutUs.dataContent3.contentHeader",
    contentBodyUpper: "aboutUs.dataContent3.contentBodyUpper",
    contentBodyLower: "aboutUs.dataContent3.contentBodyLower",
  },
];

export const dataTeams = [
  {
    contentHeader: "aboutUs.elisabethLieWilhelmsen.contentHeader",
    contentBodyUpper: "aboutUs.elisabethLieWilhelmsen.contentBodyUpper",
    contentBodyLower: "aboutUs.elisabethLieWilhelmsen.contentBodyLower",
    avatar: elwImage,
  },
  {
    contentHeader: "aboutUs.sebastianSamuelsen.contentHeader",
    contentBodyUpper: "aboutUs.sebastianSamuelsen.contentBodyUpper",
    contentBodyLower: "aboutUs.sebastianSamuelsen.contentBodyLower",
    avatar: sebImage,
  },
  {
    contentHeader: "aboutUs.torOskarWilhelmsen.contentHeader",
    contentBodyUpper: "aboutUs.torOskarWilhelmsen.contentBodyUpper",
    contentBodyLower: "aboutUs.torOskarWilhelmsen.contentBodyLower",
    avatar: towImage,
  },
];

