import React, {useState} from 'react';
import {Stack} from "@mui/system";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import {changeCurrency, getCurrencies} from "src/services/AccountService";
import {useAccountContext} from "src/context/AccountContext";
import {useSnackbarContext} from "src/context/SnackbarContext";

function CurrencySelector() {
    const {t} = useTranslation();
    const theme = useTheme()
    const {user, getAccessTokenSilently} = useAuth0();
    const {currentAccount} = useAccountContext();
    const [selectedCurrency, setSelectedCurrency] = useState(currentAccount.account.account.defaultCurrency)
    const {setDataSnackbar} = useSnackbarContext();

    const {data: currencies, isLoading: isLoadingExchangesData} = useQuery({
        queryKey: ["getAccountCurrencies"],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return getCurrencies(token, user?.sub);
        },
    });

    const mutation = useMutation({
            mutationFn: async (currency) => {
                const token = await getAccessTokenSilently();
                await changeCurrency(token, user?.sub, currentAccount.account.account.id, currency);
            },
            onSuccess: (data) => {
                setDataSnackbar({
                    isOpen: true,
                    severity: "success",
                    message: t("settings.accountDetailsPage.currencySelectorError"),
                    action: null,
                });
            },
            onError: (error) => {
                setDataSnackbar({
                    isOpen: true,
                    severity: "error",
                    message: t("settings.accountDetailsPage.currencySelectorSuccess"),
                    action: null,
                });
            }
        }
    );

    const handleChange = (currency) => {
        setSelectedCurrency(currency);
        mutation.mutate(currency.name)
    }

    return (
        <Stack direction={"column"} spacing={2}>
            <Typography
                variant={"subtitle1"}
            >
                {t("settings.accountDetailsPage.currencySelectorHeading")}
            </Typography>
            <Box>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"
                                sx={{color: theme.palette.text.primary}}>{t("settings.accountDetailsPage.currencySelectorLabel")}</InputLabel>
                    {selectedCurrency && (
                        <Select
                            /*Enable when available again*/
                            disabled={true}
                            labelId={"currency-selector-label"}
                            id="currency-simple-select"
                            value={selectedCurrency}
                            displayEmpty={true}
                            renderValue={() => selectedCurrency.name}
                            label="Currency selector"
                            onChange={(e) => handleChange(e.target.value)}
                            sx={{color: theme.palette.text.primary}}
                        >
                            {currencies && currencies.data.map((currency, idx) => {
                                return (
                                    <MenuItem key={idx} value={currency}>
                                        {currency.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    )}

                </FormControl>
            </Box>
        </Stack>
    );
}

export default CurrencySelector;