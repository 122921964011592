import React from 'react';
import {Box, Skeleton, Stack, useMediaQuery, useTheme} from '@mui/material';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import {OverviewItemCoin} from "src/pages/Overview/components/OverviewItemCoin";

// Install Swiper modules
SwiperCore.use([Navigation]);

function Skeletons() {
    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.between('lg', 'xl')); // For up large screen
    const medium = useMediaQuery(theme.breakpoints.between('md', 'lg')); // For medium devices
    const small = useMediaQuery(theme.breakpoints.down('sm')); // For small devices

    let numSkeletons = 4; // Default for large screens
    if (large) numSkeletons = 3;
    if (medium) numSkeletons = 2; // For medium devices
    if (small) numSkeletons = 1; // For small devices

    const skeletons = [];
    for (let i = 0; i < numSkeletons; i++) {
        skeletons.push(
            <Skeleton
                key={i}
                variant="rounded"
                sx={{width: '90%', height: '220px', mx: "auto"}}
            />
        );
    }
    return skeletons;
}

function CoinSummarySwiper({coinSummary, refetchPage}) {

    return (
        <Swiper
            slidesPerView={4}
            navigation
            spaceBetween={30}
            breakpoints={{
                340: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                765: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            }}
        >
            {coinSummary.map((coin, index) => (
                <SwiperSlide key={index}>
                    <OverviewItemCoin
                        id={coin?.coin?.id}
                        blockchain={coin?.blockchain?.blockchain}
                        currentExchangeRate={coin?.currentExchangeRateUserCurrency}
                        coinName={coin?.coin?.name}
                        coinIcon={coin?.coin?.icon}
                        coinValue={coin?.coinValueInUserCurrency}
                        numberOfCoins={coin?.numberOfCoins}
                        staked={coin?.numberOfCoinsStaked}
                        farmed={coin?.numberOfCoinsFarmed}
                        refetchPage={refetchPage}
                        userCurrency={coin?.userCurrency}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default function CoinSummary({coinSummary, isLoading, refetchPage}) {
    return (
        <Box component={"section"} sx={{width: "100%", overflow: "hidden", paddingBottom: "20px"}}>
            {isLoading ? (
                <Stack direction={"row"} spacing={2}
                >
                    <Skeletons/>
                </Stack>
            ) : (
                coinSummary && coinSummary.length > 0 && (
                    <CoinSummarySwiper coinSummary={coinSummary} refetchPage={refetchPage}/>
                )
            )}
        </Box>
    );
}
