import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { markAsScam } from "../../../services/Transactions";
import {
  StyledDialogButtonCancel,
  StyledDialogButtonOK,
} from "../../../components/StyledComponents/StyledDialogButtons";
import StyledDialogContent from "../../../components/StyledComponents/StyledDialogContent";
import StyledDialogTitle from "../../../components/StyledComponents/StyledDialogTitle";

export default function ChangeMarkAsScam(props) {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();

  const [isMarkAsScamModalOpen, setIsMarkAsScamModalOpen] = useState(false);

  const markAsScamAPI = useMutation(["markAsScam"], {
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      await markAsScam(token, user?.sub, props.itemId.id);
    },
    onSuccess: async () => {
      await props.refetchPage({
        id: props.itemId.id,
        transactionType: "SCAM",
        status: "RESOLVED",
      });
    },
  });

  const handleOpenModal = () => {
    setIsMarkAsScamModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsMarkAsScamModalOpen(false);
    props.setAnchorEl(null);
  };

  const handleChangeMarkAsScam = () => {
    markAsScamAPI.mutate();
    setIsMarkAsScamModalOpen(false);
    props.setAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        sx={{ color: "#000", textTransform: "none" }}
        onClick={handleOpenModal}
      >
        {t("transactions.markAsScam")}
      </Button>
      <Dialog open={isMarkAsScamModalOpen} onClose={handleCloseModal}>
        <StyledDialogTitle>
          {t("transactions.editTransaction")}
        </StyledDialogTitle>
        <StyledDialogContent sx={{ width: 500 }}>
          <Typography sx={{ textAlign: "center" }}>
            {t("transactions.confirmMaskAsScam")}
          </Typography>
        </StyledDialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledDialogButtonCancel onClick={handleCloseModal}>
            {t("common.close")}
          </StyledDialogButtonCancel>
          <StyledDialogButtonOK onClick={handleChangeMarkAsScam}>
            {t("common.save")}
          </StyledDialogButtonOK>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
