import {transactionColumns} from "./TransactionColumns";
import * as React from "react";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "@tanstack/react-query";
import {getTransactionsForUser} from "../../../services/Transactions";
import {Box, Container, Stack, Typography, Unstable_Grid2 as Grid, useTheme,} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../../context/AccountContext";
import {GridToolbar, useGridApiRef} from "@mui/x-data-grid-premium";
import StyledDataGrid from "../../../components/StyledComponents/StyledDataGrid";
import CustomItem from "./CustomItem";
import ResultBox from "./ResultBox";

export default function TransactionsTable() {
    const {t} = useTranslation();
    const theme = useTheme()
    const [pageSize, setPageSize] = useState(10);
    const {isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const {currentAccount} = useAccountContext();

    const apiRef = useGridApiRef();

    const {
        data: transactionsData,
        refetch: refetchPage,
        isLoading: isLoadingTransactions,
    } = useQuery({
        queryKey: ["getTransactionsForUser", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const data = getTransactionsForUser(
                token,
                user?.sub,
                currentAccount.accountId
            );
            return data;
        },
        enabled: isAuthenticated,
    });

    const renderBox = (index, row) => {
        switch (index) {
            case 0:
                return (
                    <CustomItem
                        type={t("transactions.information")}
                        data={row}
                        haveHeader={false}
                    />
                );
            case 1:
                return (
                    <CustomItem
                        type={t("transactions.fees")}
                        data={row}
                        coinName={row.coin.name}
                    />
                );
            case 2:
                return (
                    <CustomItem
                        type={t("transactions.prices")}
                        data={row}
                        coinName={row.coin.name}
                    />
                );
            case 3:
                return (
                    <CustomItem
                        type={t("transactions.exchangeRates")}
                        data={row}
                        coinName={row.coin.name}
                    />
                );
            default:
                return <></>;
        }
    };

    const getType = (type) => {
        if (type === "SWAP") return t("transactions.swapInformation");
        if (type === "STAKESTART") return t("transactions.stakeInformation");
        if (type === "STAKEEND") return t("transactions.stakeInformation");
        if (type === "SACRIFICE") return t("transactions.sacrificeInformation");
    };

    function getDetailPanelContent({row}) {
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    py: 4,
                    px: 0,
                }}
            >
                <Container maxWidth="xl" sx={{width: "100%"}}>
                    <Grid container spacing={3}>
                        <>
                            {[6, 1.9, 1.9, 2.2].map((item, index) => (
                                <Grid
                                    key={"91" + index.toString()}
                                    xs={12}
                                    sm={6}
                                    lg={item}
                                    sx={
                                        index === 0
                                            ? {
                                                pl: 0,
                                                borderRight: "1px solid var(--dcdcdc, #DCDCDC)",
                                            }
                                            : index === 3
                                                ? {}
                                                : {
                                                    borderRight: "1px solid var(--dcdcdc, #DCDCDC)",
                                                }
                                    }
                                >
                                    {renderBox(index, row)}
                                </Grid>
                            ))}

                            <Stack
                                sx={{
                                    height: "2px",
                                    width: "100%",
                                    bgcolor: "#000",
                                    my: "16px",
                                }}
                            />
                            {row.result?.map((item, index) => (
                                <Grid
                                    key={"122" + index.toString()}
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    sx={{
                                        pl: 0,
                                        borderRight: "1px solid var(--dcdcdc, #DCDCDC)",
                                    }}
                                >
                                    <ResultBox
                                        resultItem={item}
                                        disablePadding={index % 3 === 0}
                                    />
                                </Grid>
                            ))}

                            {["SWAP", "STAKESTART", "STAKEEND", "SACRIFICE"].includes(
                                row.transactionType
                            ) && (
                                <Grid
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    sx={[
                                        {
                                            borderRight: "1px solid var(--dcdcdc, #DCDCDC)",
                                        },
                                        row.result?.length % 3 === 0 && {pl: 0},
                                    ]}
                                >
                                    <CustomItem
                                        type={getType(row.transactionType)}
                                        disablePadding={row.result?.length % 3 === 0}
                                        data={row}
                                    />
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Container>
            </Box>
        );
    }

    const handleUpdateRow = (dataUpdate) => {
        apiRef.current.updateRows([dataUpdate]);
    };

    return (
        <Stack
            spacing={2}
            p={3}
            sx={{
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows.sm,
                borderRadius: "1.5rem",
                border: `1px solid ${theme.palette.primary[50]}`
            }}
        >
            <Stack spacing={2} direction="row" justifyContent="space-between">
                <Typography color="#000" sx={{fontSize: 20, fontWeight: "600"}}>
                    {t("transactions.yourTransactions")}
                </Typography>
                {/* <Button
          onClick={() =>
            apiRef?.current.exportDataAsExcel({ allColumns: true })
          }
          sx={{
            height: 40,
            color: "#090113",
            border: "1px solid #090113",
            borderRadius: "24px",
            textTransform: "none",
          }}
          variant="outlined"
        >
          {t("transactions.export")}
        </Button> */}
            </Stack>

            <Stack spacing={2}>
                <Box sx={{display: "flex", height: "auto", width: "100%"}}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            "& .super-app-theme--header": {
                                backgroundColor: "#F4F4F4",
                            },
                            "& .super-app-theme--header-status": {
                                backgroundColor: "#F4F4F4",
                                pl: 3,
                            },
                        }}
                    >
                        {!isLoadingTransactions ? (
                            <>
                                <StyledDataGrid
                                    apiRef={apiRef}
                                    sx={{border: "0px solid"}}
                                    rows={transactionsData}
                                    getRowClassName={(params) => {
                                        return params.row.status !== "RESOLVED" ||
                                        params.row.status === null
                                            ? ""
                                            : "highlight";
                                    }}
                                    loading={isLoadingTransactions}
                                    columns={transactionColumns(
                                        t,
                                        (dataUpdate) => handleUpdateRow(dataUpdate),
                                        (params) => {
                                            apiRef?.current?.toggleDetailPanel(params.id);
                                        },
                                        apiRef
                                    )}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    onPageSizeChange={(newPageSize) => {
                                        setPageSize(newPageSize);
                                    }}
                                    disableSelectionOnClick
                                    autoHeight={true}
                                    pagination={true}
                                    getDetailPanelContent={getDetailPanelContent}
                                    getDetailPanelHeight={() => "auto"}
                                    slots={{toolbar: GridToolbar}}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{field: "transactionDate", sort: "desc"}],
                                        },
                                    }}
                                    filterModel={{
                                        items: [],
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <StyledDataGrid
                                    apiRef={apiRef}
                                    sx={{border: "0px solid"}}
                                    rows={[]}
                                    loading={isLoadingTransactions}
                                    autoHeight={true}
                                    pagination={true}
                                    slots={{toolbar: GridToolbar}}
                                    columns={transactionColumns(
                                        t,
                                        refetchPage,
                                        (params) => {
                                            apiRef?.current?.toggleDetailPanel(params.id);
                                        },
                                        apiRef
                                    )}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
}
