import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../context/AccountContext";
import {Box, Container, Grid, useTheme} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "@tanstack/react-query";
import {getPortfolioOverview} from "../../services/PortfolioService";
import CoinSummary from "./components/CoinSummaryBox";
import {OverviewPortfolio} from "./components/OverviewPortfolio";
import {OverviewAllocation} from "./components/OverviewAllocation";
import {OverviewAnnual} from "./components/OverviewAnnual";
import {MessagesBox} from "./components/MessagesBox";

export default function Overview() {
    const {currentAccount} = useAccountContext();
    const {t} = useTranslation();
    const theme = useTheme();
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();

    const {
        data: overviewData,
        isLoading: isLoadingOverViewData,
        refetch: refetchPage,
    } = useQuery({
        queryKey: ["getPortfolioOverview", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return await getPortfolioOverview(
                token,
                user?.sub,
                currentAccount.accountId
            );
        },
        enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
    });

    return (
        <>
            <Helmet>
                <title>{t("overview.pageHelmet")}</title>
                <meta name="Blokc" content="Blokc"/>
            </Helmet>

            <Box
                sx={{
                    flexGrow: 1,
                    py: 8,
                    mx: "auto",
                }}
                component={"main"}
            >
                <Container maxWidth={"xxl"}>
                    {currentAccount.accountId ? (
                        <>
                            <CoinSummary
                                coinSummary={overviewData?.portfolioValuePrCoin}
                                isLoading={isLoadingOverViewData}
                                refetchPage={refetchPage}
                            />

                            <Grid container spacing={3} mt={3}>
                                <Grid item xs={12} xl={8} order={{xs: 1}}>
                                    <OverviewPortfolio
                                        title={t("overview.portfolioValue")}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            boxShadow: theme.shadows.sm,
                                            borderRadius: "1.5rem",
                                            border: `1px solid ${theme.palette.primary[50]}`,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={7} xl={4} order={{xs: 2, md: 3, xl: 2}}>
                                    <MessagesBox/>
                                </Grid>
                                <Grid item xs={12} md={12} xl={8} order={{xs: 3, md: 4, xl: 3}}>
                                    <OverviewAnnual
                                        title={t("overview.annualResult")}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            boxShadow: theme.shadows.sm,
                                            borderRadius: "1.5rem",
                                            border: `1px solid ${theme.palette.primary[50]}`,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5} xl={4} order={{xs: 4, lg: 2, xl: 4}}>
                                    <OverviewAllocation
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            boxShadow: theme.shadows.sm,
                                            borderRadius: "1.5rem",
                                            border: `1px solid ${theme.palette.primary[50]}`,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </Container>
            </Box>
        </>
    );
}
