import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import dameImage from "src/assets/png/dame.png";


export const section2Texts = [
    {
        contentHeader: "frontPage.section2item1header",
        contentBody: "frontPage.section2item1content",
    },
    {
        contentHeader: "frontPage.section2item2header",
        contentBody: "frontPage.section2item2content",
    },
    {
        contentHeader: "frontPage.section2item3header",
        contentBody: "frontPage.section2item3content",
    },
    {
        contentHeader: "frontPage.section2item4header",
        contentBody: "frontPage.section2item4content",
    },
];

export const section3Texts = [
    {
        contentHeader: "frontPage.section3item1header",
        contentBody: "frontPage.section3item1content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item2header",
        contentBody: "frontPage.section3item2content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item3header",
        contentBody: "frontPage.section3item3content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item4header",
        contentBody: "frontPage.section3item4content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item5header",
        contentBody: "frontPage.section3item5content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item6header",
        contentBody: "frontPage.section3item6content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item7header",
        contentBody: "frontPage.section3item7content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item8header",
        contentBody: "frontPage.section3item8content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item9header",
        contentBody: "frontPage.section3item9content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
    {
        contentHeader: "frontPage.section3item10header",
        contentBody: "frontPage.section3item10content",
        icon: <ArticleOutlinedIcon fontSize={"large"}/>,
    },
];
