import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../../common/dateFormat";
import { separateCommaTrans } from "../../../common/numberFormat";

import { t } from "i18next";

function createData(name, value) {
  return { name, value };
}

export default function ResultBox(props) {
  const { resultItem, disablePadding } = props;

  const rows = [
    createData(t("transactions.realisationDate"), resultItem.resultDate),
    createData(
      t("transactions.coinSwappedOrSold"),
      resultItem?.coinSwappedOrSold?.name
    ),
    createData(
      t("transactions.numberOfCoinsSwappedOrSold"),
      separateCommaTrans(resultItem.numberOfCoinsSwappedOrSold, null, 10)
    ),
    createData(
      t("transactions.coinPriceAtRealisationDate"),
      separateCommaTrans(resultItem.coinPriceAtRealisationDate, null, 10)
    ),
  ];

  const sxx = disablePadding
    ? { pl: 0, fontSize: "14px", fontWeight: "600", width: "55%" }
    : { fontSize: "14px", fontWeight: "600", width: "55%" };
  return (
    <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
      <TableContainer>
        <Table sx={{ width: "100%" }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "4px solid rgba(255,255,255, 0)",
              }}
            >
              <TableCell sx={sxx}>{t("transactions.result")}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <React.Fragment key={"ResultRow" + index.toString()}>
                <TableRow
                  sx={{
                    borderBottom: "4px solid rgba(255,255,255, 0)",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={disablePadding ? { pl: 0 } : {}}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.name === t("transactions.realisationDate")
                      ? formatDate(row.value, "DD.MM.YYYY  |  hh:mm:ss")
                      : row.value}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>

        {resultItem?.transactionCrossings?.map((item, indexC) => {
          const rowsCrossings = [
            createData(
              t("transactions.amountCrossed"),
              separateCommaTrans(item.amountCrossed, null, 10)
            ),
            createData(
              t("transactions.priceAtCrossing"),
              separateCommaTrans(item?.coinCostPriceForCrossedTransaction, null, 10)
            ),
            createData(
              t("transactions.transactionCrossed"),
              separateCommaTrans(item.transactionCrossed, 0, 2)
            ),
          ];
          return (
            <Table
              key={"Crossings" + +indexC.toString()}
              sx={{ width: "100%" }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "4px solid rgba(255,255,255, 0)",
                  }}
                >
                  <TableCell sx={sxx}>
                    {t("transactions.realisation")}
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsCrossings.map((row, index) => (
                  <TableRow
                    key={"CrossingsRow" + index.toString()}
                    sx={{
                      borderBottom: "4px solid rgba(255,255,255, 0)",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={disablePadding ? { pl: 0 } : {}}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.name === t("transactions.realisationDate")
                        ? formatDate(row.value, "DD.MM.YYYY  |  hh:mm:ss")
                        : row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })}
      </TableContainer>
    </Paper>
  );
}
