import dameImage from "src/assets/png/dame.png";
import {Box, Container, Stack, Unstable_Grid2 as Grid, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useTranslation} from "react-i18next";

export default function IntroductionCards() {
    const {t} = useTranslation();
    const theme = useTheme()

    const section1Texts = [
        {
            contentHeader: "frontPage.headerCard1Heading",
            contentBody: "frontPage.headerCard1Content",
            color: theme.palette.text.primary,
            contentImage: null,
            backgroundColor: theme.palette.primary[50],
        },
        {
            contentHeader: "frontPage.headerCard2Heading",
            contentBody: "frontPage.headerCard2Content",
            color: theme.palette.text.secondary,
            contentImage: null,
            backgroundColor: theme.palette.primary[200],
        },
        {
            contentHeader: "frontPage.headerCard3Heading",
            contentBody: "",
            contentImage: dameImage,
            color: theme.palette.text.primary,
        },
        {
            contentImage: null,
            contentHeader: "frontPage.headerCard4Heading",
            contentBody: "frontPage.headerCard4Content",
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary[900],
        },
    ];


    return (
        <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
            <Grid
                container
                justifyContent={"space-between"}
                maxWidth={"xxl"}
                margin={"0px auto"}
                spacing={2}
            >
                {section1Texts.map((item, index) => {
                    return (
                        <Grid
                            item
                            key={index}
                            justifyContent={"center"}
                            alignItems={"center"}
                            xs={12}
                            md={6}
                            xl={3}
                            sx={{
                                marginLeft: "-8px",
                                marginRight: "-8px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "stretch",
                            }}
                        >
                            <Stack
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingY: item.contentImage ? 0 : 1.5,
                                    paddingX: item.contentImage ? 0 : 2,
                                    borderRadius: "1.5rem",
                                    backgroundColor: item.backgroundColor,
                                    position: "relative",
                                    color: item.color,
                                    minHeight: {md: "16rem", lg: "14rem", xl: "20rem", xxl: "17rem"},
                                    overflow: "hidden",
                                }}
                            >
                                {item.contentImage && (
                                    <Box component={"img"} src={item.contentImage}
                                         sx={{
                                             width: "100%",
                                             height: "100%",
                                             objectFit: "cover",
                                             borderRadius: "1.5rem",
                                         }}/>
                                )}
                                {item.contentImage ? (
                                    <Box sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderBottom: "1.5rem",
                                        py: 2,
                                        width: "100%",
                                        backgroundColor: "rgba(0, 37, 84, 0.4)",
                                        color: theme.palette.text.secondary
                                    }}>
                                        <Typography
                                            gutterBottom
                                            variant={"h5"}
                                            component={"h3"}
                                            fontWeight={"semibold"}
                                        >
                                            {t(item.contentHeader)}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Typography
                                            gutterBottom
                                            variant={"h5"}
                                            component={"h3"}
                                            fontWeight={"semibold"}
                                        >
                                            {t(item.contentHeader)}
                                        </Typography>
                                        <Typography
                                            variant={"body1"}
                                            component={"p"}
                                        >
                                            {t(item.contentBody)}
                                        </Typography>
                                    </Box>
                                )}

                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    )
}