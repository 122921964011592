import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function HelpPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('help.pageHelmet')}
        </title>
        <meta name="Blokc" content="Blokc" />
      </Helmet>
      <Stack spacing={2}>
        <Typography variant='h4'>{t('help.help')}</Typography>
      </Stack>
    </>
  )
}