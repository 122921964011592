import { DataGridPremium } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material";

export default function StyledDataGrid(props) {
  const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({}));
  return (
    <StripedDataGrid
      {...props}
      sx={{
        ".highlight": {
          bgcolor: "#FFFF00",
          "&:hover": {
            bgcolor: "#b3b308",
          },
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
        {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .css-1vooibu-MuiSvgIcon-root": {
          display: "none",
        },
        border: 0,
      }}
    />
  );
}
