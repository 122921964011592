import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Card,
  CardContent,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";

import { useAccountContext } from "../../../context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { resultAndTaxGraph } from "../../../services/PortfolioService";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { t } from "i18next";
import { StyledSelect,StyledCardHeader } from "../styles";
import Lottie from "lottie-react";
import LoadingAnimation from "src/assets/iconJson/LoadingAnimation.json";
import IconNoData from "src/assets/iconJson/IconNoData.json";

export const OverviewAnnual = (props) => {
  const { sx, title = "", subheader = "" } = props;
  const { currentAccount } = useAccountContext();
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const [currency, setCurrency] = useState("NOK");

  const {
    data: resultAndTaxGraphData,
    isLoading: loadingResultAndTaxGraphData,
  } = useQuery({
    queryKey: ["resultAndTaxGraph", currentAccount.accountId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const data = await resultAndTaxGraph(
        token,
        user?.sub,
        currentAccount.accountId
      );
      return data;
    },
    enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
  });

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <Card sx={sx} variant="outlined">
      <StyledCardHeader
        action={
          <Stack direction={"row"} spacing={2}>
            <FormControl
              sx={{ minWidth: 150, borderWidth: 0.5 }}
              size="small"
              color="info"
            >
              <StyledSelect value={currency} onChange={handleChangeCurrency}>
                <MenuItem value={"USD"}>{t("overview.usd")}</MenuItem>
                <MenuItem value={"NOK"}>{t("overview.nok")}</MenuItem>
              </StyledSelect>
            </FormControl>
          </Stack>
        }
        title={title}
        subheader={subheader}
      />

      <CardContent>
        {loadingResultAndTaxGraphData && (
          <Stack
            sx={{
              width: "100%",
              height: "10px",
              position: "relative",
              top: 150,
            }}
          >
            <Lottie
              animationData={LoadingAnimation}
              style={{ height: "100px" }}
            />
          </Stack>
        )}

        {!loadingResultAndTaxGraphData &&
          resultAndTaxGraphData?.resultsAndTaxes?.length === 0 && (
            <Stack
              sx={{
                width: "100%",
                height: "10px",
                position: "relative",
                top: 150,
              }}
            >
              <Lottie animationData={IconNoData} style={{ height: "100px" }} />
            </Stack>
          )}

        <ResponsiveContainer width={"100%"} height={400}>
          <AreaChart
            width={"100%"}
            height={"100%"}
            data={resultAndTaxGraphData?.resultsAndTaxes || []}
            margin={{ left: 30, bottom: 50, right: 15 }}
          >
            <defs>
              <linearGradient id="colorResult" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#10A365" stopOpacity={0.19} />
                <stop offset="100%" stopColor="#10A365" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorTax" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#BEFB38" stopOpacity={0.19} />
                <stop offset="100%" stopColor="#BEFB38" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="year">
              <Label
                value={t("overview.year")}
                offset={-25}
                position="insideBottom"
              />
            </XAxis>
            <YAxis
              label={{
                value:
                  currency === "USD" ? t("overview.usd") : t("overview.nok"),
                angle: -90,
                position: "insideLeft",
                offset: -15,
              }}
            ></YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey={currency === "USD" ? "resultUsd" : "resultNok"}
              stroke="#10A365"
              fillOpacity={1}
              fill="url(#colorResult)"
            />
            <Area
              type="monotone"
              dataKey={currency === "USD" ? "taxUsd" : "taxNok"}
              stroke="#BEFB38"
              fillOpacity={1}
              fill="url(#colorTax)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

OverviewAnnual.protoTypes = {
  chartSeries: PropTypes.array.isRequired,
  sx: PropTypes.object,
  categories: PropTypes.array,
};
