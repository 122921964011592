import { createContext, useReducer, useContext } from "react";

const SnackbarContext = createContext();

export const SnackbarContextProvider = ({ children }) => {
  const [dataSnackbar, setDataSnackbar] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      isOpen: false,
      severity: "success",
      message: "",
      action: null,
    }
  );

  return (
    <SnackbarContext.Provider
      value={{
        dataSnackbar,
        setDataSnackbar: setDataSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  const { dataSnackbar, setDataSnackbar } = useContext(SnackbarContext);
  return { dataSnackbar, setDataSnackbar };
};
