import {Box, Unstable_Grid2 as Grid, useTheme} from "@mui/material"
import "./styled.scss";
import SubscriptionDetails from "src/pages/Settings/AccountDetails/components/SubscriptionDetails";
import UsersTable from "src/pages/Settings/AccountDetails/components/UsersTable";
import SacrificePayments from "src/pages/Settings/AccountDetails/SacrificePayments";

export default function AccountDetails(props) {
    const theme = useTheme()


    return (
        <Box component="section" sx={{width: '100%'}}>
            <Grid
                container
                sx={{
                    backgroundColor: theme.palette.background.default,
                }}
                spacing={2}
            >
                <Grid item xs={12} lg={7}>
                    <UsersTable/>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <SubscriptionDetails/>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <SacrificePayments/>
                </Grid>
            </Grid>
        </Box>
    );
}
