import {useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Box, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import {separateCommaTrans} from "../../../common/numberFormat";
import {t} from "i18next";

export default function AccordionCustom(props) {
    const {item, children, onExpand} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = async () => {
        setIsOpen(!isOpen);
        if (!isOpen && onExpand) await onExpand();
    };

    return (
        <Box component={motion.div} layout>
            <Stack
                gap={2}
                sx={{
                    backgroundColor: "var(--b4bGrayBackground)",
                    minHeight: "3rem",
                    placeContent: "center",
                    p: {xs: "16px", md: '32px'},
                    border: "1px solid var(--dcdcdc, #DCDCDC)",
                    borderRadius: "16px",
                }}
            >
                <Box
                    direction="column"
                    sx={{
                        width: "100%",
                        cursor: "pointer",
                        display: "flex",
                        placeItems: "center",
                        justifyContent: "space-between",
                    }}
                    gap={4}
                    onClick={toggleOpen}
                >
                    <Stack
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                paddingBottom: "0.75rem",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "32px",
                                    fontWeight: "600",
                                }}
                            >
                                {item.year}
                            </Typography>
                            {!isOpen ? (
                                <ExpandMoreOutlinedIcon
                                    fontSize="large"
                                    sx={{float: "right"}}
                                />
                            ) : (
                                <ExpandLessOutlinedIcon
                                    fontSize="large"
                                    sx={{float: "right"}}
                                />
                            )}
                        </Stack>
                        <Stack
                            sx={{
                                bgcolor: "#E9EAF1",
                                flex: 1,
                                p: "16px",
                            }}
                            spacing={1}
                        >
                            <Typography sx={{textAlign: "left", color: "#000"}}>
                                {t("reports.totalResult")}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "#000",
                                    fontSize: "32px",
                                    fontWeight: "600",
                                }}
                            >
                                {`${separateCommaTrans(item?.totalResultUserCurrency, 2, 2)} ${item?.userCurrency.name}`}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "#626262",
                                    fontSize: "20px",
                                    fontWeight: "400",
                                }}
                            >
                                {`${separateCommaTrans(item?.totalResultUsd, 2, 2)} USD`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <AnimatePresence>
                    {isOpen && (
                        <AccordionCustomContent sx={{height: "100%"}}>
                            {children}
                        </AccordionCustomContent>
                    )}
                </AnimatePresence>
            </Stack>
        </Box>
    );
}

AccordionCustom.propTypes = {
    // exchangeImg: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onExpand: PropTypes.func,
};

function AccordionCustomContent(props) {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <Stack gap={2}>{props.children}</Stack>
        </motion.div>
    );
}
