import { Button } from "@mui/material";
import { useState } from "react";
import { StyledMenuItem } from "../../../components/StyledComponents/StyledMenuItem";
import StyledMenu from "../../../components/StyledComponents/StyledMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ChangeTransactionType from "./ChangeTransactionType";
import ChangeMarkAsScam from "./ChangeMarkAsScam";
import ChangeCostPrice from "./ChangeCostPrice";
import ChangeSalesPrice from "./ChangeSalesPrice";

export default function TableActionsTransactions(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClickOpenMenu}>
        <MoreHorizIcon sx={{ color: "#000000" }} />
      </Button>
      <StyledMenu
        id="edit-delete-transaction-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{ textShadow: "none" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledMenuItem>
          <ChangeTransactionType
            itemId={props.params.row}
            refetchPage={props.refetchPage}
            setAnchorEl={setAnchorEl}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ChangeMarkAsScam
            itemId={props.params.row}
            refetchPage={props.refetchPage}
            setAnchorEl={setAnchorEl}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ChangeCostPrice
            itemId={props.params.row}
            refetchPage={props.refetchPage}
            setAnchorEl={setAnchorEl}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ChangeSalesPrice
            itemId={props.params.row}
            refetchPage={props.refetchPage}
            setAnchorEl={setAnchorEl}
          />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
