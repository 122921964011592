import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { t } from "i18next";
import { formatDate } from "../../../common/dateFormat";

function createData(name, value) {
  return { name, value };
}

export default function Information(props) {
  const { type = t("transactions.information"), data } = props;

  const rows = [
    createData(t("transactions.txHash"), data.exchangeTransactionId),
    createData(
      t("transactions.transactionTimestamp"),
      formatDate(data.transactionDate, "DD.MM.YYYY  |  HH:MM:SS")
    ),
  ];

  if (Array.isArray(rows) && rows?.length > 0) {
    return (
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "4px solid rgba(255,255,255, 0)",
                  width: "100%",
                }}
              >
                <TableCell
                  sx={[
                    {
                      pl: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      flex: 2,
                    },
                  ]}
                >
                  {type}
                </TableCell>

                <TableCell
                  sx={{ fontSize: "14px", fontWeight: "600", flex: 1 }}
                  align={
                    type === t("transactions.information") ? "left" : "right"
                  }
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={`${data.id} ${row.name} ${index} ${type}`}
                  sx={{
                    borderBottom: "4px solid rgba(255,255,255, 0)",
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    {row.name}
                  </TableCell>
                  <TableCell
                    align={
                      type === t("transactions.information") ? "left" : "right"
                    }
                  >
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  } else {
    return null;
  }
}
