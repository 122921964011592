export function separateCommaTrans(val, minF = null, maxF = null) {
  if (!val) return "0";
  if (minF !== null && maxF !== null) {
    return new Intl.NumberFormat("fr", {
      style: "decimal",
      minimumFractionDigits: minF,
      maximumFractionDigits: maxF,
    })
      .format(val)
      .replace(",", ".");
  }

  if (minF !== null) {
    return new Intl.NumberFormat("fr", {
      style: "decimal",
      minimumFractionDigits: minF,
    })
      .format(val)
      .replace(",", ".");
  }

  if (maxF !== null) {
    return new Intl.NumberFormat("fr", {
      style: "decimal",
      maximumFractionDigits: maxF,
    })
      .format(val)
      .replace(",", ".");
  }
  const intl = new Intl.NumberFormat("fr", {
    style: "decimal",
  }).format(val);
  return intl.toString().replace(",", ".");
}

export function separateComma(val) {
  if (!val) return "";
  // remove sign if negative
  var sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }
  // trim the number decimal point if it exists
  let num = val.toString().includes(".")
    ? val.toString().split(".")[0]
    : val.toString();
  let len = num.toString().length;
  let result = "";
  let count = 1;

  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = " " + result;
    }
    count++;
  }

  // add number after decimal point
  if (val.toString().includes(".")) {
    result = result + "." + val.toString().split(".")[1];
  }
  // return result with - sign if negative
  return sign < 0 ? "-" + result : result;
}
