import {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Dialog, DialogActions, Stack} from '@mui/material';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {deleteSacrificePayments} from 'src/services/SacrificePayments'
import {useAccountContext} from '../../../../context/AccountContext';
import {StyledDialogButton} from '../../../../components/StyledComponents/StyledDialogButtons';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledDialogContent from '../../../../components/StyledComponents/StyledDialogContent';
import StyledDialogTitle from '../../../../components/StyledComponents/StyledDialogTitle';
import PropTypes from 'prop-types';
import {useSnackbarContext} from "src/context/SnackbarContext";

export default function DeleteSacrificePaymentsModal(props) {
    const {t} = useTranslation();
    const [isDeleteSacrificePaymentsModalOpen, setIsDeleteSacrificePaymentsModalOpen] = useState(false);
    const [itemId, setItemId] = useState();
    const {user, getAccessTokenSilently} = useAuth0();
    const {currentAccount} = useAccountContext();
    const {setDataSnackbar} = useSnackbarContext();

    const actionDeleteSacrificePayments = useMutation(['deleteSacrificePayments'],
        {
            mutationFn: async () => {
                const token = await getAccessTokenSilently();
                const dataResult = await deleteSacrificePayments(token, user?.sub, currentAccount.accountId, itemId)
                if (dataResult.status === 200) {
                    setDataSnackbar({
                        isOpen: true,
                        severity: "success",
                        message: "success",
                        action: null,
                    });
                } else {
                    setDataSnackbar({
                        isOpen: true,
                        severity: "error",
                        message: "Error",
                        action: null,
                    });
                }
            },
            onSuccess: async () => {
                await props.refetchSacrificePayment();
            }
        }
    )

    const handleOpenModal = () => {
        setItemId(props.itemId)
        setIsDeleteSacrificePaymentsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsDeleteSacrificePaymentsModalOpen(false);
        props.setAnchorEl(null);
    }

    const handleDeleteSacrificePayments = () => {
        actionDeleteSacrificePayments.mutate();
        setIsDeleteSacrificePaymentsModalOpen(false);
        props.setAnchorEl(null);
    }

    return (
        <Stack>
            <Button sx={{color: 'var(--b4bDarblue)'}} onClick={handleOpenModal}><DeleteIcon/></Button>
            <Dialog open={isDeleteSacrificePaymentsModalOpen} onClose={handleCloseModal}>
                <StyledDialogTitle>{t('settings.sacrificePaymentsSettingsPage.deleteSacrificePayments')}</StyledDialogTitle>
                <StyledDialogContent>
                    <Stack spacing={3} sx={{marginTop: 3}}>
                        {t('settings.sacrificePaymentsSettingsPage.deleteSacrificePaymentsWarning')}
                    </Stack>
                </StyledDialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleCloseModal}>{t('settings.cancel')}</StyledDialogButton>
                    <StyledDialogButton
                        onClick={handleDeleteSacrificePayments}>{t('settings.delete')}</StyledDialogButton>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

DeleteSacrificePaymentsModal.propTypes = {
    itemId: PropTypes.number,
    refetchSacrificePayment: PropTypes.func,
}