import moment from "moment";

export const format_yyyyMMdd_HHmmssz = "YYYY-MM-DD HH:mm:ss";
export const format_YYYYMMDD = "YYYY-MM-DD";
export const format_YYYYMMDDz = "YYYY - MM - DD";
export const format_HHmm = "HH:mm";
export const format_HHmmss = "HH:mm:ss";
export const format_DMMMMYYYY = "D MMMM, YYYY";
export const format_dddDMMMMYYYY = "ddd D MMMM, YYYY";
export const format_DDMMYYYY = "DD.MM.YYYY";
export const format_DDMMYY = "DD.MM.YY";
export const format_DMMMYYY = "D MMM YYYY";
export const format_DMMMMYYY = "D MMMM YYYY";
export const format_DDMMYYYY_hhmm = "DD/MM/YYYY HH:mm";

export const TimeHelpers = {
  // Get local date from server date string
  getLocalDate: function (date) {
    if (!date) {
      return "";
    }

    return moment.utc(date).local();
  },

  getUTCDateIOS: (date) => {
    if (!date) {
      return "";
    }

    return moment.utc(date).toISOString();
  },

  checkDateAfter: (date1, date2) => {
    return moment(date1).isAfter(date2);
  },

  formatFromSeconds: (number) => {
    return new Date(number * 1000).toISOString().substring(11, 19);
  },

  getDateFromCalendar: (timestamp) => {
    return moment(timestamp).set({ h: 8 }).format();
  },

  momentGetDiffTime: (data) => {
    let currentTime = moment(new Date()).format(format_yyyyMMdd_HHmmssz);
    let requestTime = moment(new Date(data)).format(format_yyyyMMdd_HHmmssz);
    var diff = moment(requestTime).from(moment(currentTime));

    if (diff && diff === "a few seconds ago") diff = "now";

    return diff;
  },
};

export function formatDate(dateTime, format = "DD.MM.YYYY", isLocal = false) {
  if (!dateTime) {
    return "";
  }
  const formatDate = format ? format : "DD.MM.YYYY";
  const date = isLocal ? TimeHelpers.getLocalDate(dateTime) : dateTime;
  return moment(date).format(formatDate);
}
