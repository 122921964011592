import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Box, Container, Stack, useTheme} from "@mui/material";
import {FilledButton, OutlinedButton} from "src/components/StyledComponents/StyledButtons";
import {useTranslation} from "react-i18next";
import {logoutHelpCrunch} from "src/common/helpcrunch";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import logo_blue from "src/assets/png/logo_blue.png";
import logo_blue_small from "src/assets/png/logo_blue_small.png";
import {useLocation, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {Clear} from "@mui/icons-material";
import Divider from "@mui/material/Divider";

const navItems = [
    {title: "frontPage.home", path: "/"},
    {title: "frontPage.prices", path: "/prices"},
    {title: "frontPage.aboutUs", path: "/aboutUs"},
];

export default function RootHeaderNotAuthent(props) {
    const {window} = props;
    const location = useLocation();
    const pathname = location.pathname;
    const theme = useTheme()
    const {loginWithRedirect} = useAuth0();
    const {t} = useTranslation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    return (
        <Box component="header" sx={{backgroundColor: theme.palette.background.paper,}}>
            <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
                <AppBar
                    component="nav"
                    position={"static"}
                    sx={{
                        borderBottom: "2px solid",
                        borderColor: theme.palette.primary[200],
                        justifyContent: "center",
                        boxShadow: "none",
                        alignItems: "center",
                        maxWidth: "xxl",
                        margin: "0 auto",
                        left: 0,
                        paddingY: 2.5,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <Toolbar
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: {xs: 170, md: 268},
                            }}
                            src={logo_blue} alt="logo_blue"
                        />
                        <Grid container alignItems="center" spacing={3} sx={{display: {xs: "none", lg: "flex"}}}>
                            {/* Links Section */}
                            <Grid item>
                                <Grid container spacing={2}>
                                    {navItems.map((item, index) => {
                                        let colorText = "black";
                                        if (index === 0 && pathname === "/") {
                                            colorText = "#002554";
                                        }
                                        if (index === 1 && pathname === "/prices") {
                                            colorText = "#002554";
                                        }
                                        if (index === 2 && pathname === "/aboutUs") {
                                            colorText = "#002554";
                                        }
                                        return (
                                            <Grid item key={item.path}>
                                                <Button
                                                    onClick={() => navigate(item.path)}
                                                    sx={{
                                                        color: colorText,
                                                        fontSize: "20px",
                                                        fontWeight: "400",
                                                        textTransform: "none",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {t(item.title)}
                                                </Button>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                            {/* Buttons Section */}
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <OutlinedButton
                                        onClick={() => {
                                            logoutHelpCrunch();
                                            loginWithRedirect();
                                        }}
                                    >
                                        {t("common.logIn")}
                                    </OutlinedButton>
                                    <FilledButton
                                        sx={{textTransform: "capitalize"}}
                                        onClick={() => loginWithRedirect({screen_hint: "signup"})}

                                    >
                                        {t("common.signUp")}
                                    </FilledButton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Button onClick={() => setMobileOpen(!mobileOpen)} sx={{
                            display: {
                                xs: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                lg: "none",
                            }
                        }}>
                            <MenuIcon sx={{
                                margin: "auto",
                                color: theme.palette.primary[900],
                                height: "42px",
                                width: "30px",
                                padding: "6px, 0px, 6px, 0px"
                            }}/>
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor={"left"}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => setMobileOpen(!mobileOpen)}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {xs: "block", lg: "none"},
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%", // Ensure the Box takes the full height of the Drawer
                            backgroundColor: theme.palette.background[150],
                            width: "20rem",
                        }}
                        pt={4}
                        px={2}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: `2px solid ${theme.palette.primary[600]}`,
                                pb: 2,
                                mx: "auto",
                                width: "100%"
                            }}
                        >
                            <Box component={"img"} src={logo_blue_small} alt={""} maxWidth={"2.563rem"}/>
                            <Button>
                                <Clear fontSize={"large"}/>
                            </Button>
                        </Stack>

                        <List sx={{
                            flexGrow: 1,
                            overflow: "auto",
                        }}> {/* Allow list to grow and scroll if necessary */}
                            {navItems.map((item, index) => {
                                let colorText = "black";
                                if (index === 0 && pathname === "/") {
                                    colorText = "#002554";
                                }
                                if (index === 1 && pathname === "/prices") {
                                    colorText = "#002554";
                                }
                                if (index === 2 && pathname === "/aboutUs") {
                                    colorText = "#002554";
                                }
                                return (
                                    <ListItem
                                        key={item.path}
                                        disablePadding
                                        onClick={() => navigate(item.path)}
                                        sx={{borderBottom: `1px solid ${theme.palette.primary[600]}`}}
                                    >
                                        <ListItemButton sx={{textAlign: "left", color: colorText}}>
                                            <ListItemText primary={t(item.title)}/>
                                        </ListItemButton>
                                        <Divider/>
                                    </ListItem>
                                );
                            })}
                        </List>

                        <Stack
                            sx={{
                                pb: 3,
                                flexDirection: "column",
                                alignItems: "center", // Center buttons horizontally
                                width: "100%", // Ensure buttons take full width
                            }}
                        >
                            <OutlinedButton
                                sx={{
                                    width: "100%",
                                    marginBottom: 2,
                                    minWidth: 200,
                                }}
                                onClick={() => {
                                    logoutHelpCrunch();
                                    loginWithRedirect();
                                }}
                            >
                                {t("common.logIn")}
                            </OutlinedButton>
                            <FilledButton
                                sx={{
                                    width: "100%",
                                    minWidth: 200,
                                }}
                                onClick={() => loginWithRedirect({screen_hint: "signup"})}
                            >
                                {t("common.signUp")}
                            </FilledButton>
                        </Stack>
                    </Box>
                </Drawer>
            </Container>
        </Box>

    );
}
