import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { startCoinbaseHarvester } from "../../../services/ExchangeService";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "../../../context/AccountContext";
import { StyledCircularProgress } from "../../../components/StyledComponents/StyledCircularProgress";

export default function ExchangeAuth02Confirmation() {
  const { currentAccount } = useAccountContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();

  const { refetch: startHarvester, isLoading: isHarvesterLoading } = useQuery({
    queryKey: ["coinbaseHarvester"],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const harvesterResult = await startCoinbaseHarvester(
        searchParams.get("code"),
        token,
        user?.sub,
        currentAccount.accountId
      );
      return harvesterResult;
    },
    enabled: false,
  });

  useEffect(() => {
    if (searchParams.get("code")) startHarvester();
  }, [searchParams, startHarvester]);

  return <div>{isHarvesterLoading && <StyledCircularProgress />}</div>;
}
