import PropTypes from "prop-types";
import {Box, ButtonBase, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const AppBarItem = (props) => {
    const {active = false, disabled, icon, path, title, onClose} = props;
    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <Link to={path}>
            <ButtonBase
                sx={{
                    alignItems: "center",
                    color: theme.palette.text.secondary,
                    py: "12px",
                    ...(active && {
                        borderBottom: 2,
                    }),
                    "&:hover": {
                        borderColor: theme.palette.primary[600],
                        borderBottom: 2,
                        color: theme.palette.primary[200],
                    },
                }}
            >
                {icon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: "center",
                            color: "inherit",
                            display: "inline-flex",
                            justifyContent: "center",
                            mr: 0.5,
                        }}
                    >
                        {icon}
                    </Box>
                )}
                <Typography
                    component="span"
                    sx={{
                        fontSize: {md: "16px", lg: "17px", xl: "18px"},
                        fontWeight: "500",
                        lineHeight: "24px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {t(title)}
                </Typography>
            </ButtonBase>
        </Link>
    );
};

AppBarItem.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
};
