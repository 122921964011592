import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import HeaderFrontPage from "./components/HeaderFrontPage";
import {useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {Box, Container, useTheme} from "@mui/material";
import IntroductionCards from "src/pages/NotAuthenticated/Frontpage/components/IntroductionCards";
import SalesPitchGrid from "src/pages/NotAuthenticated/Frontpage/components/SalesPitchGrid";
import Investments from "src/pages/NotAuthenticated/Frontpage/components/Investments";
import RegistrationCTA from "src/pages/NotAuthenticated/Frontpage/components/RegistrationCTA";
import Carousel from "src/pages/NotAuthenticated/Frontpage/components/Carousel";

export default function FrontPage(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth0();
    const theme = useTheme()

    useLayoutEffect(() => {
        if (isAuthenticated) {
            navigate("/overview");
        } else {
            sessionStorage.setItem("visiableTutorialSession", 1);
        }
    }, [isAuthenticated, navigate]);

    return (
        <>
            <Helmet>
                <title>{t("frontPage.pageHelmet")}</title>
                <meta name="Blokc" content="Blokc"/>
            </Helmet>
            <Box
                component="main"
                backgroundColor={theme.palette.background.paper}
                sx={{
                    flexGrow: 1,
                }}
            >
            <HeaderFrontPage/>
            <IntroductionCards/>
            <SalesPitchGrid/>
            <Carousel/>
            <Investments/>
            <RegistrationCTA/>
            </Box>
        </>
    );
}
