import {useAuth0} from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  useTheme,
} from "@mui/material";
import {Stack} from "@mui/system";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {addAddressForUser} from "../../../services/AddressService";
import {getAvailableBlockchains} from "../../../services/BlockchainService";
import {useAccountContext} from "../../../context/AccountContext";
import StyledDialogContent from "../../../components/StyledComponents/StyledDialogContent";
import PropTypes from "prop-types";
import {regexCommon} from "src/constant/regax.const";
import AddIcon from "@mui/icons-material/Add";

export default function AddAddressModal(props) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
    const [error, setError] = useState("");
    const [flagError, setFlagError] = useState(null);
    const theme = useTheme()

    const {currentAccount} = useAccountContext();
    const {t} = useTranslation();

    const {data: blockchainData, isLoading: isLoadingBlockchainData} = useQuery(
        {
            queryKey: ["getAvailableBlockchains"],
            queryFn: async () => {
                const token = await getAccessTokenSilently();
                const data = getAvailableBlockchains(
                    token,
                    user?.sub,
                    currentAccount.accountId
                );
                return data;
            },
            enabled: isAddAddressModalOpen,
        }
    );

    const addNewAddress = useMutation(["addAddress"], {
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();
            await addAddressForUser(token, user?.sub, currentAccount.accountId, data);
        },
        onSuccess: async () => {
            await props.refetchAddresses();
            reset();
        },
    });

    const handleOpenAddAddressModal = () => {
        setIsAddAddressModalOpen(true);
    };
    const handleCloseAddAddressModal = () => {
        setIsAddAddressModalOpen(false);
        reset(setFlagError(null), setError(""));
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        register,
    } = useForm({
        defaultValues: {
            address: "",
            blockchainId: 1,
            description: "",
        },
    });

    const onSubmit = async (data) => {
        const {address} = data;

        if (!!regexCommon.test(address)) {
            setFlagError(null);
            setError("");

            setIsAddAddressModalOpen(false);
            addNewAddress.mutate(data);
            reset();
        } else {
            setFlagError(true);
            setError("Invalid Value!");
        }
    };

    return (
        <Stack>
            <Button
                onClick={handleOpenAddAddressModal}
                sx={{
                    backgroundColor: theme.palette.primary[900],
                    color: 'white',
                    padding: '10px 10px',
                    fontSize: '0.85rem',
                    fontWeight: 500,
                    borderRadius: '9999px',
                    textTransform: "capitalize",
                    boxShadow: theme.shadows.sm,
                    '&:hover': {
                        backgroundColor: theme.palette.primary[600],
                    },
                    '&:focusVisible': {
                        outline: '2px solid',
                        outlineColor: theme.palette.primary[600],
                        outlineOffset: 2,
                    },
                }}
                startIcon={<AddIcon/>}
            >
                {t("settings.addressSettingsPage.addAddress")}
            </Button>

            {!isLoadingBlockchainData ? (
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={isAddAddressModalOpen}
                    onClose={handleCloseAddAddressModal}
                >
                    <StyledDialogContent
                        sx={{
                            alignItems: "center",
                            alignContent: "center",
                            px: {xs: "16px", md: "64px"},
                            py: {xs: "32px", md: "64px"},
                        }}
                    >
                        <Stack sx={{flex: 1}}>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        textAlign: "center",
                                        color: "#000000",
                                        mb: 2,
                                    }}
                                >
                                    {t("settings.addressSettingsPage.addBlockchainAddress")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        textAlign: "center",
                                        color: "#626262",
                                    }}
                                >
                                    {t("settings.addressSettingsPage.addBlockchainAddressDes")}
                                </Typography>
                            </Stack>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid
                                    container
                                    xs={12}
                                    lg={12}
                                    direction={"row"}
                                    spacing={2}
                                    columns={12}
                                    my={"48px"}
                                >
                                    <Grid xs={12} lg={3}>
                                        <Controller
                                            render={() => (
                                                <FormControl
                                                    error={!!errors.blockchain}
                                                    sx={{width: "100%"}}
                                                >
                                                    <InputLabel id="select-blockchainId">
                                                        {t("settings.addressSettingsPage.blockchain")}
                                                    </InputLabel>
                                                    <Select
                                                        size="small"
                                                        defaultValue={1 || ""}
                                                        labelId="select-blockchainId"
                                                        sx={{width: "100%", flexGrow: 1}}
                                                        input={
                                                            <OutlinedInput
                                                                label={t(
                                                                    "settings.addressSettingsPage.blockchain"
                                                                )}
                                                            />
                                                        }
                                                        {...register("blockchainId", {
                                                            required: t(
                                                                "settings.addressSettingsPage.blockchainError"
                                                            ),
                                                        })}
                                                    >
                                                        {blockchainData?.map((blockchain) => (
                                                            <MenuItem
                                                                key={blockchain.id}
                                                                value={blockchain.id}
                                                            >
                                                                {blockchain.blockchain}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            name="blockchainId"
                                            control={control}
                                        />
                                    </Grid>

                                    <Grid xs={12} lg={9}>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t("settings.addressSettingsPage.address")}
                                                    {...register("address", {
                                                        required: t(
                                                            "settings.addressSettingsPage.addressError"
                                                        ),
                                                    })}
                                                    error={!!errors.address || !!flagError}
                                                    helperText={errors.address?.message || error}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={12} lg={12}>
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t("settings.addressSettingsPage.description")}
                                                    {...register("description")}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                            <Stack
                                gap={2}
                                direction={"row"}
                                justifyContent={"center"}
                                sx={{
                                    flexShrink: 0,
                                    alignSelf: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={handleCloseAddAddressModal}
                                    sx={{
                                        px: "32px",
                                        height: 48,
                                        color: "#090113",
                                        border: "1px solid #090113",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            backgroundColor: "#000000",
                                            color: "#fff",
                                            border: "1px solid #000000",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    {t("settings.cancel")}
                                </Button>

                                <Button
                                    sx={{
                                        px: "32px",
                                        height: 48,
                                        color: "#FFFFFF",
                                        border: "1px solid #000000",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                        bgcolor: "#000000",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            color: "#000000",
                                            border: "1px solid #000000",
                                        },
                                    }}
                                    size="small"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("settings.submit")}
                                </Button>
                            </Stack>
                        </Stack>
                    </StyledDialogContent>
                </Dialog>
            ) : null}
        </Stack>
    );
}

AddAddressModal.propTypes = {
    refetchAddresses: PropTypes.func,
};
