import {useTranslation} from "react-i18next";
import {Button, Stack, Typography, useTheme} from "@mui/material";
import UploadFileModal from "./UploadFileModal";
import {isMobile} from "react-device-detect";

export default function Footer(props) {
    const {t} = useTranslation();
    const {
        onConnect = () => {
        }, type = "uploadNBX"
    } = props;
    const theme = useTheme()
    const renderSaveButton = () => (
        <Button
            type="submit"
            sx={{
                backgroundColor: theme.palette.primary[900],
                color: 'white',
                padding: '10px 15px',
                fontSize: '0.85rem',
                fontWeight: 500,
                borderRadius: '9999px',
                textTransform: "capitalize",
                boxShadow: theme.shadows.sm,
                '&:hover': {
                    backgroundColor: theme.palette.primary[600],
                },
                '&:focusVisible': {
                    outline: '2px solid',
                    outlineColor: theme.palette.primary[600],
                    outlineOffset: 2,
                },
            }}
        >
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "var(--ffffff, #FFF)",
                    textAlign: "center",
                }}
            >
                {t("settings.save")}
            </Typography>
        </Button>
    );

    return (
        <>
            <Stack
                sx={{
                    flexDirection: "row",
                    display: "flex",
                    paddingTop: "8px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "16px",
                    alightSelf: "stretch",
                }}
            >
                <UploadFileModal type={type} refetchPage={() => {
                }}/>
                <Button
                    onClick={onConnect}
                    variant="text"
                    sx={{textTransform: "none"}}
                >
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#000",
                        }}
                    >
                        {t("settings.exchangeSettingsPage.connectToExchange")}
                    </Typography>
                </Button>

                {!isMobile && renderSaveButton()}
            </Stack>
            {isMobile && renderSaveButton()}
        </>
    );
}
