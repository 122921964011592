import { Stack } from "@mui/material";
import { separateCommaTrans } from "../../../common/numberFormat";

export const coinColumns = (t) => {
  return [
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: "Coin Name",
      flex: 1,
      renderHeader: () => <strong>{t("reports.infor.coinName")}</strong>,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ fontWeight: "600", pl: 0 }}
          >
            <img
              src={params.row.icon}
              alt={params.row.name}
              style={{ width: "32px", height: "32px", marginRight: "10px" }}
            />
            {params.row.name}
          </Stack>
        );
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "numberOfCoins",
      type: "number",
      renderHeader: () => <strong>{t("reports.infor.number")}</strong>,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ fontWeight: "400", pl: 0 }}
          >
            {separateCommaTrans(params.row.numberOfCoins, 2, 2)}
          </Stack>
        );
      },
      flex: 1,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "coinValueInNOK",
      type: "number",
      renderHeader: () => <strong>{t("reports.infor.value")}</strong>,
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ fontWeight: "400", pl: 0 }}
          >
            {separateCommaTrans(params.row.coinValueInUserCurrency, 2, 2)}
          </Stack>
        );
      },
      flex: 1,
    },
  ];
};
