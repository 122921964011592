import {useTranslation} from "react-i18next";
import {Box, Modal, Stack, Typography} from "@mui/material";
import {useMutation} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useRef, useState} from "react";
import logo from "src/assets/png/logo.png";
import {StyledButtonBlack, StyledButtonWhite,} from "src/components/StyledComponents/StyledButtons";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CloseIcon from "@mui/icons-material/Close";

import {Keyboard, Navigation, Pagination} from "swiper";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import BlockchainTable from "../../../pages/Settings/BlockchainAddress/BlockchainTable";
import AccountDetails from "../../../pages/Settings/AccountDetails/AccountDetails";
import SacrificePayments from "../../../pages/Settings/AccountDetails/SacrificePayments";
import {updateDisplayWizard} from "../../../services/UserService";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "#002554",
    border: "2px solid #000",
    boxShadow: 24,
    px: 4,
    pb: 10,
    pt: 4,
};

const styleContainerSwiper = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "#ffffff",
    border: "2px solid #000",
    boxShadow: 24,
    px: 4,
    py: 2,
};

const styleBox = {
    overflow: "auto",
    px: 4,
    py: 2,
    bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "grey.100"),
    color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
    border: "1px solid",
    borderColor: (theme) =>
        theme.palette.mode === "dark" ? "grey.800" : "grey.300",
    borderRadius: 2,
    fontSize: "0.875rem",
    fontWeight: "700",
    height: "70%",
    mt: 2,
};

export default function CustomTutorial(props) {
    const {userId, onCloseTutorial} = props;
    const {user, getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();
    const [isStaring, setIsStaring] = useState(false);
    const [isAccept, setAccept] = useState("");
    const [checked, setChecked] = useState(false);
    const [disabledNext, setDisabledNext] = useState(false);
    const [trigger, setTrigger] = useState(0);

    const updateWizard = useMutation(["updateDisplayWizard"], {
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();

            await updateDisplayWizard(token, user?.sub, userId, data);
        },
    });

    const sliderRef = useRef(null);

    let isPressing = false;
    const handleChange = (isCheck) => {
        if (isPressing) return;
        isPressing = true;
        setChecked(isCheck);
        setTimeout(() => {
            if (isCheck) {
                updateWizard.mutate(JSON.stringify(false));
            } else {
                updateWizard.mutate(JSON.stringify(true));
            }
        }, 500);
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        setDisabledNext(false);
        if (sliderRef.current.swiper.clickedIndex === 0) {
            setIsStaring(false);
        } else {
            sliderRef.current.swiper.slidePrev();
        }
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        if (sliderRef.current?.swiper.activeIndex === 3 && isAccept !== "accept") {
            setDisabledNext(true);
        } else {
            setDisabledNext(false);
        }
        if (sliderRef.current.swiper.clickedIndex === 5) {
            onCloseTutorial();
            updateWizard.mutate(JSON.stringify(false));
        } else if (sliderRef.current.swiper.clickedIndex === 0) {
            setTrigger((trigger) => trigger + 1);
            // sliderRef.current.swiper.slideNext();
        } else {
            sliderRef.current.swiper.slideNext();
        }
    }, [isAccept]);

    const renderButtonFooter = () => {
        return (
            <Stack direction={"row"} mt={2} justifyContent={"flex-end"}>
                <StyledButtonWhite
                    onClick={handlePrev}
                    sx={{
                        minHeight: "12px",
                        fontWeight: "400",
                        mx: 2,
                    }}
                >
                    {t("common.back")}
                </StyledButtonWhite>
                <StyledButtonBlack
                    disabled={disabledNext}
                    onClick={handleNext}
                    sx={{
                        minHeight: "12px",
                        fontWeight: "400",
                        mx: 2,
                    }}
                >
                    {t("common.next")}
                </StyledButtonBlack>
            </Stack>
        );
    };

    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {!isStaring ? (
                <Box sx={style} my={4}>
                    <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        onClick={onCloseTutorial}
                    >
                        <CloseIcon sx={{color: "#ffffff"}}/>
                    </Stack>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        mb={4}
                    >
                        <img src={logo} alt="logo"/>
                    </Box>

                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        color="white"
                        sx={{textAlign: "center"}}
                    >
                        {t("overview.tutorial.title")}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: "center"}}
                        color="white"
                    >
                        {t("overview.tutorial.desception")}
                    </Typography>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                        mt={4}
                    >
                        <StyledButtonWhite onClick={() => setIsStaring(true)}>
                            <Typography>{t("overview.tutorial.start")}</Typography>
                        </StyledButtonWhite>
                    </Box>
                    <Stack
                        pl={4}
                        mt={4}
                        color="white"
                        direction={"row"}
                        alignItems={"center"}
                    >
                        <Box
                            onClick={() => handleChange(!checked)}
                            display="flex"
                            bgcolor={"white"}
                            sx={{width: "36px", height: "36px"}}
                            alignItems={"center"}
                            justifyContent={"center"}
                            mr={2}
                        >
                            {checked && <CheckOutlinedIcon sx={{color: "black"}}/>}
                        </Box>
                        <Typography>{t("common.dontShowThisAgain")}</Typography>
                    </Stack>
                </Box>
            ) : (
                <Box sx={styleContainerSwiper} my={2}>
                    <Stack
                        direction={"row"}
                        mt={2}
                        justifyContent={"flex-end"}
                        onClick={onCloseTutorial}
                    >
                        <CloseIcon/>
                    </Stack>
                    <Swiper
                        ref={sliderRef}
                        slidesPerView={1}
                        spaceBetween={30}
                        keyboard={{
                            enabled: true,
                        }}
                        pagination={{
                            clickable: false,
                        }}
                        scrollbar={true}
                        modules={[Keyboard, Pagination, Navigation]}
                        className="mySwiper"
                        allowTouchMove={false}
                    >
                        <SwiperSlide style={{height: "38rem"}}>
                            <Box component="div" sx={styleBox}>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        mb: "16px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("settings.accountDetailsPage.personalDetails")}
                                </Typography>
                                <AccountDetails
                                    triggerProps={trigger}
                                    isTutorial={true}
                                    updateSuccess={() => {
                                        setTrigger(0);
                                        sliderRef?.current.swiper?.slideNext();
                                    }}
                                    updateError={() => setTrigger(0)}
                                />
                            </Box>
                            {renderButtonFooter()}
                        </SwiperSlide>
                        <SwiperSlide style={{height: "38rem"}}>
                            <Box component="div" sx={styleBox}>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        mb: "16px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("settings.addressSettingsPage.yourBlockchainAddress")}
                                </Typography>
                                <BlockchainTable isTutorial={true}/>
                            </Box>
                            {renderButtonFooter()}
                        </SwiperSlide>

                        <SwiperSlide style={{height: "38rem"}}>
                            <Box component="div" sx={styleBox}>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        mb: "16px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("common.exchanges")}
                                </Typography>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: t("common.exchangeDescription"),
                                    }}
                                ></Typography>
                            </Box>
                            {renderButtonFooter()}
                        </SwiperSlide>

                        <SwiperSlide style={{height: "38rem"}}>
                            <Box component="div" sx={styleBox}>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        mb: "16px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t(
                                        "settings.sacrificePaymentsSettingsPage.yourSacrificePayments"
                                    )}
                                </Typography>
                                <SacrificePayments isTutorial={true}/>
                            </Box>
                            {renderButtonFooter()}
                        </SwiperSlide>
                        <SwiperSlide style={{height: "38rem"}}>
                            <Box component="div" sx={styleBox}>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        mb: "16px",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("common.termsAndConditionsTitle")}
                                </Typography>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: t("common.termsAndConditionsDescription"),
                                    }}
                                ></Typography>
                            </Box>
                            <FormControl>
                                <RadioGroup
                                    sx={{flexDirection: "row"}}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    onChange={(_, value) => {
                                        setAccept(value);
                                        if (value === "decline") {
                                            setDisabledNext(true);
                                        } else {
                                            setDisabledNext(false);
                                        }
                                    }}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="accept"
                                        control={<Radio/>}
                                        label={t("common.accept")}
                                    />
                                    <FormControlLabel
                                        value="decline"
                                        control={<Radio/>}
                                        label={t("common.decline")}
                                    />
                                </RadioGroup>
                            </FormControl>
                            {renderButtonFooter()}
                        </SwiperSlide>
                        <SwiperSlide style={{height: "38rem"}}>
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    mb: "16px",
                                    textAlign: "center",
                                }}
                            >
                                {t("common.payment")}
                            </Typography>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: t("common.paymentdescription"),
                                }}
                            ></Typography>
                            {renderButtonFooter()}
                        </SwiperSlide>
                    </Swiper>
                </Box>
            )}
        </Modal>
    );
}
