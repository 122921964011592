import { default as _axios } from "axios";
import { getCurrentAccountId } from "../context/AccountContext";

const axiosInstance = _axios.create();
function axios(token, auth0Id) {
  axiosInstance.interceptors.request.use(
    (config) => {
      var accountId = getCurrentAccountId();
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["userId"] = auth0Id;
      if (accountId) config.headers["accountId"] = accountId;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}
const HOST_B4BAPPLICATION = process.env.REACT_APP_HOST_B4BAPPLICATION;
const HOST_B4BHARVESTER = process.env.REACT_APP_HOST_B4BHARVESTER;
const PROTOCOL = window.location.protocol;

const APIBASE_B4BAPPLICATION = `${PROTOCOL}//${HOST_B4BAPPLICATION}`;
const APIBASE_B4BHARVESTER = `${PROTOCOL}//${HOST_B4BHARVESTER}`;

export { axios, APIBASE_B4BAPPLICATION, APIBASE_B4BHARVESTER };
