import {Box, Stack, useTheme} from "@mui/material";
import {itemsAppBar} from "src/components/RootLayout/components/Authenticated/config";
import Drawer from "@mui/material/Drawer";
import logo_blue_small from "src/assets/png/logo_blue_small.png";
import Button from "@mui/material/Button";
import {Clear} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";


export default function MobileNavigation({mobileOpen, setMobileOpen}) {
    const theme = useTheme()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    return (
        <Drawer
            anchor={"left"}
            variant="temporary"
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{
                display: {xs: "block", lg: "none"},
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    backgroundColor: theme.palette.primary[250],
                    width: "20rem",
                }}
                pt={4}
                px={2}
            >
                <Stack
                    direction={"row"}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: `2px solid ${theme.palette.primary[600]}`,
                        pb: 2,
                        mx: "auto",
                        width: "100%"
                    }}
                >
                    <Box component={"img"} src={logo_blue_small} alt={""} maxWidth={"2.563rem"}/>
                    <Button onClick={() => setMobileOpen(!mobileOpen)}>
                        <Clear fontSize={"large"}/>
                    </Button>
                </Stack>

                <List sx={{
                    flexGrow: 1,
                    overflow: "auto",
                }}> {/* Allow list to grow and scroll if necessary */}
                    {itemsAppBar.map((item, index) => {
                        let colorText = "black";
                        const active = item.path ? pathname === item.path : false;
                        return (
                            <React.Fragment key={index}>
                                <ListItem
                                    key={index}
                                    disablePadding
                                    onClick={() => navigate(item.path)}
                                    sx={{
                                        borderRadius: "0.5rem",
                                        ...(active && {
                                            borderColor: theme.palette.primary[400],
                                            backgroundColor: theme.palette.primary[100]
                                        }),
                                    }}

                                >
                                    <ListItemButton py={2} sx={{textAlign: "left", color: colorText}}>
                                        <ListItemText primary={t(item.title)}/>
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant={"middle"} textAlign={"center"}/>
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Drawer>
    )
}