import {
  axios,
  APIBASE_B4BAPPLICATION,
  APIBASE_B4BHARVESTER,
} from "./ApiConfig";

export const getExchangeData = async (token, auth0Id, accountId, exchange) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/exchange/${accountId}/${exchange}`)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });

export const saveExchangedata = async (token, auth0Id, data) =>
  axios(token, auth0Id)
    .post(`${APIBASE_B4BAPPLICATION}/api/exchange/saveNBX`, data)
    .then((response) => {
      return response.data;
    });

export const startCoinbaseHarvester = async (
  authorizationCode,
  token,
  auth0Id,
  accountId
) =>
  axios(token, auth0Id)
    .post(`${APIBASE_B4BHARVESTER}/api/exchange/startCoinBaseHarvesting`, {
      authorizationCode: authorizationCode,
      accountId: accountId,
    })
    .then((response) => {
      return response.data;
    });

export const getExchanges = async (token, auth0Id, accountId) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/account/${accountId}/getExchanges`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e.response.data;
    });

export const addExchangeToAccount = async (token, auth0Id, accountId, data) =>
  axios(token, auth0Id)
    .post(
      `${APIBASE_B4BAPPLICATION}/api/account/${accountId}/addExchangeToAccount`,
      data
    )
    .then((response) => {
      return response.data;
    });
