import { DialogContent, styled } from "@mui/material"

export default function StyledDialogContent(props) {
  const StyledDialogContent = styled(DialogContent)(() => ({
    paddingTop: 25,
  }))

  return (
    <StyledDialogContent {...props} />
  )
}