import {
  regexCommon,
  regaxEmail,
  regexNumber,
  regexName,
} from "src/constant/regax.const";

export const accountDetailOptions = {
  firstname: {
    required: "First Name is required",
    maxLength: {
      value: 100,
      message: "First name cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexName,
      message: "Invalid Value!",
    },
  },
  lastname: {
    required: "Last Name is required",
    maxLength: {
      value: 100,
      message: "Last name cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexName,
      message: "Invalid Value!",
    },
  },
  postcode: {
    maxLength: {
      value: 100,
      message: "Postcode cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexCommon,
      message: "Invalid Value!",
    },
  },
  city: {
    maxLength: {
      value: 100,
      message: "City cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexCommon,
      message: "Invalid Value!",
    },
  },
  address: {
    maxLength: {
      value: 5000,
      message: "Address  cannot be longer than 5000 characters.",
    },
    pattern: {
      value: regexCommon,
      message: "Invalid Value!",
    },
  },
  email: {
    required: "Email is required",
    maxLength: {
      value: 100,
      message: "Email cannot be longer than 100 characters.",
    },
    pattern: {
      value: regaxEmail,
      message: "Invalid Value!",
    },
  },
  aptSuiteEtc: {
    maxLength: {
      value: 100,
      message: "AptSuiteEtc cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexCommon,
      message: "Invalid Value!",
    },
  },
  personalNumber: {
    required: "Personal Number is required",
    maxLength: {
      value: 100,
      message: "Personal Number cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexCommon,
      message: "Invalid Value!",
    },
  },
  phoneNumber: {
    required: "Phone Number is required",
    maxLength: {
      value: 100,
      message: "Phone Number cannot be longer than 100 characters.",
    },
    pattern: {
      value: regexNumber,
      message: "Invalid Value!",
    },
  },
};
