import { useForm, Controller } from 'react-hook-form';
import { saveUser } from '../../services/UserService';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '../../context/AccountContext';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export default function RegisterUser(props) {
  const { userData, refetchUserData } = props;
  const { updateCurrentAccount } = useAccountContext();
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();


  const { handleSubmit, control, formState: { errors }, register } = useForm({
    defaultValues: {
      name: '',
      address: '',
      postcode: '',
      place: '',
      country: '',
      phone: '',
      ssn: ''
    }
  });

  const mutateUser = useMutation({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      const accountId = userData.accounts[0].account.id;
      saveUser(token, auth0User.sub, accountId, userData.id, {
        auth0Userid: auth0User.sub,
        name: data.name,
        email: auth0User.email,
        address: data.address,
        postcode: data.postcode,
        place: data.place,
        country: data.country,
        ssn: data.ssn,
        pricePlanId: 1,
        paymentInfo: {
          id: 1,
          cardHolder: "string",
          cardNumber: "string",
          ccv: "string",
          expiryMonth: 0,
          expiryYear: 0
        },
      })
    },
    onSuccess: async () => {
      refetchUserData();
      updateCurrentAccount({ accountId: userData.accounts[0].account.id });
      navigate('/overview');
    }
  })

  const onSubmitUserData = data => { mutateUser.mutate(data) };

  return (
    <>
      <Helmet>
        <title>
          {t('registerUser.pageHelmet')}
        </title>
        <meta name="Blokc" content="Blokc" />
      </Helmet>
      <Stack spacing={2} sx={{ backgroundColor: 'var(--b4bGrayBackground)', p: '2rem', borderRadius: '25px' }}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          {t('registerUser.registerUser')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmitUserData)}>
          <Stack sx={{ alignItems: 'center', pt: '2rem' }} spacing={2}>
            <Box sx={{ display: 'flex' }} gap={2}>
              <Controller
                name="name"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.fullNameLabel')}
                    {...register('name', {
                      required: t('registerUser.fullNameError')
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                }
              />
            </Box>
            <Box sx={{ display: 'flex' }} gap={2}>
              <Controller
                name="address"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.addressLabel')}
                    {...register('address', {
                      required: t('registerUser.addressError')
                    })}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />}
              />
              <Controller
                name="postcode"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.postcodeLabel')}
                    {...register('postcode', {
                      required: t('registerUser.postcodeError')
                    })}
                    error={!!errors.postcode}
                    helperText={errors.postcode?.message}
                  />}
              />
            </Box>
            <Box sx={{ display: 'flex' }} gap={2}>
              <Controller
                name="place"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.placeLabel')}
                    {...register('place', {
                      required: t('registerUser.placeError')
                    })}
                    error={!!errors.place}
                    helperText={errors.place?.message}
                  />}
              />
              <Controller
                name="country"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.countryLabel')}
                    {...register('country', {
                      required: t('registerUser.countryError')
                    })}
                    error={!!errors.country}
                    helperText={errors.country?.message}
                  />}
              />
            </Box>
            <Box sx={{ display: 'flex' }} gap={2}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.phoneLabel')}
                    {...register('phone', {
                      required: t('registerUser.phoneError')
                    })}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />}
              />
              <Controller
                name="ssn"
                control={control}
                render={({ field }) =>
                  <TextField {...field} label={t('registerUser.socialSecurityLabel')}
                    {...register('ssn')}
                    error={!!errors.ssn}
                    helperText={errors.ssn?.message}
                  />}
              />
            </Box>
            <Button type='submit'>
              {t('registerUser.submit')}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>

  )
}

RegisterUser.propTypes = {
  userData: PropTypes.object,
  refetchUserData: PropTypes.func,
}