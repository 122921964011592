import PropTypes from "prop-types";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {separateCommaTrans} from "../../../common/numberFormat";
import {useEffect, useState} from "react";
import StyledDataGrid from "../../../components/StyledComponents/StyledDataGrid";
import {coinColumns} from "./coinColumns";

const getColor = (value) =>
    value > 0 ? "#10A365" : value < 0 ? "#D32F2F" : "#DCDCDC";

export default function YearInfor(props) {
    const {data} = props;
    const {t} = useTranslation();

    const [pageSize, setPageSize] = useState(5);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let coins = [];
        if (data) {
            if (
                data?.portfolioOverview?.portfolioValuePrCoin &&
                Array.isArray(data?.portfolioOverview?.portfolioValuePrCoin)
            ) {
                let allCoinsOfYear = data?.portfolioOverview?.portfolioValuePrCoin;
                for (let coin of allCoinsOfYear) {
                    coin = {
                        ...coin,
                        ...coin.coin,
                    };
                    coins.push(coin);
                }
                setRows(coins);
            }
        }
    }, [data]);

    return (
        <Stack
            sx={{
                "& .super-app-theme--header": {
                    backgroundColor: "#F4F4F4",
                },
            }}
        >
            <Stack
                sx={{
                    flex: 1,
                    bgcolor: "#F4F4F4",
                    borderBottom: "1px solid var(--626262, #626262)",
                    px: "16px",
                    py: "12px",
                }}
            >
                <Typography>{t("reports.infor.breakdown")}</Typography>
            </Stack>

            <Stack sx={{width: "100%", flexDirection: {xs: "column", md: "row"}}}>
                <Stack sx={{flex: 1}}>
                    <Stack sx={{px: "16px", py: "8px"}}>
                        <Typography>{data.gainLossUserCurrency < 0 ? "Loss" : "Gain"}</Typography>
                        <Typography
                            variant={"body2"}
                            textAlign={"right"}
                        >
                            {data.userCurrency.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: getColor(data.gainLossUserCurrency),
                            }}
                            textAlign={"right"}
                        >
                            {separateCommaTrans(data.gainLossUserCurrency, 2, 2)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: getColor(data.gainLossUsd),
                            }}
                            textAlign={"right"}
                        >
                            {`${separateCommaTrans(data.gainLossUsd, 2, 2)} ${t(
                                "reports.infor.usd"
                            )}`}
                        </Typography>
                    </Stack>

                    <Stack sx={{px: "16px", py: "8px"}}>
                        <Typography> {t("reports.infor.slippages")}</Typography>
                        <Typography
                            sx={{fontSize: "14px", fontWeight: "400", color: "#626262"}}
                            textAlign={"right"}
                        >
                            {data.userCurrency.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: getColor(data.slippagesUserCurrency),
                            }}
                            textAlign={"right"}
                        >
                            {separateCommaTrans(data.slippagesUserCurrency, 2, 2)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: getColor(data.slippagesUsd),
                            }}
                            textAlign={"right"}
                        >
                            {`${separateCommaTrans(data.slippagesUsd, 2, 2)} ${t(
                                "reports.infor.usd"
                            )}`}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack sx={{bgcolor: "#DCDCDC", width: "1px", mx: "16px"}}/>
                <Stack sx={{flex: 1}}>
                    <Stack sx={{px: "16px", py: "8px"}}>
                        <Typography>{t("reports.infor.fees")}</Typography>
                        <Typography
                            sx={{fontSize: "14px", fontWeight: "400", color: "#626262"}}
                            textAlign={"right"}
                        >
                            {data.userCurrency.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: getColor(data.feesUserCurrency),
                            }}
                            textAlign={"right"}
                        >
                            {separateCommaTrans(data.feesUserCurrency, 2, 2)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: getColor(data.feesUsd),
                            }}
                            textAlign={"right"}
                        >
                            {`${separateCommaTrans(data.feesUsd, 2, 2)} ${t(
                                "reports.infor.usd"
                            )}`}
                        </Typography>
                    </Stack>

                    <Stack sx={{px: "16px", py: "8px"}}>
                        <Typography>{t("reports.infor.interest")}</Typography>
                        <Typography
                            sx={{fontSize: "14px", fontWeight: "400", color: "#626262"}}
                            textAlign={"right"}
                        >
                            {data.userCurrency.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                fontWeight: "600",
                                color: getColor(data.interestUserCurrency),
                            }}
                            textAlign={"right"}
                        >
                            {separateCommaTrans(data.interestUserCurrency, 2, 2)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: getColor(data.interestUsd),
                            }}
                            textAlign={"right"}
                        >
                            {`${separateCommaTrans(data.interestUsd, 2, 2)} ${t(
                                "reports.infor.usd"
                            )}`}
                        </Typography>{" "}
                    </Stack>
                </Stack>
            </Stack>

            <Stack
                sx={{
                    flex: 1,
                    bgcolor: "#F4F4F4",
                    borderBottom: "1px solid var(--626262, #626262)",
                    px: "16px",
                    py: "12px",
                }}
            >
                <Typography>{t("reports.infor.capital")}</Typography>
            </Stack>
            <Stack sx={{px: "16px", py: "8px"}}>
                <Typography
                    sx={{fontSize: "14px", fontWeight: "400", color: "#626262"}}
                    textAlign={"right"}
                >
                    {data.userCurrency.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: getColor(data.portfolioOverview.portfolioValueUserCurrency),
                    }}
                    textAlign={"right"}
                >
                    {separateCommaTrans(data.portfolioOverview.portfolioValueUserCurrency, 2, 2)}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: getColor(data.portfolioOverview.portfolioValueUSD),
                    }}
                    textAlign={"right"}
                >
                    {`${separateCommaTrans(data.portfolioOverview.portfolioValueUSD, 2, 2)} ${t(
                        "reports.infor.usd"
                    )}`}
                </Typography>
            </Stack>

            <StyledDataGrid
                sx={{border: "0px solid"}}
                rows={rows}
                disableColumnFilter
                disableColumnMenu
                disableColumnSorting
                columns={coinColumns(t)}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                autoHeight={true}
                pagination={true}
                getDetailPanelHeight={() => "auto"}
            />
        </Stack>
    );
}

YearInfor.propTypes = {
    data: PropTypes.object.isRequired,
};
