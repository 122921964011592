import {Button, Dialog, DialogActions, Typography, useTheme} from "@mui/material";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledDialogButton } from "src/components/StyledComponents/StyledDialogButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import StyledDialogContent from "../../../../../components/StyledComponents/StyledDialogContent";
import StyledDialogTitle from "../../../../../components/StyledComponents/StyledDialogTitle";

export default function RemoveUserModal(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsRemoveUserModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsRemoveUserModalOpen(false);
    props.setAnchorEl(null);
  };

  const handleDeleteAddress = () => {
    props.setAnchorEl(null);
    setIsRemoveUserModalOpen(false);
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Button sx={{ color: theme.palette.background[700] }} onClick={handleOpenModal}>
        <DeleteIcon />
        <Typography variant={"body2"}>{t("settings.accountDetailsPage.removeUser")}</Typography>
      </Button>

      <Dialog open={isRemoveUserModalOpen} onClose={handleCloseModal}>
        <StyledDialogTitle>
          {t("settings.accountDetailsPage.removeUser")}
        </StyledDialogTitle>
        <StyledDialogContent sx={{ width: 500 }}>
          <Stack spacing={3} sx={{ marginTop: 3 }}>
            <Stack direction="row" justifyContent="center">
              {t("settings.accountDetailsPage.removeUserWarning")}{" "}
              {props.itemId.name + "?"}
            </Stack>
          </Stack>
        </StyledDialogContent>
        <DialogActions>
          <StyledDialogButton onClick={handleCloseModal}>
            {t("settings.accountDetailsPage.cancel")}
          </StyledDialogButton>
          <StyledDialogButton onClick={handleDeleteAddress}>
            {t("settings.accountDetailsPage.removeUser")}
          </StyledDialogButton>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
