import {Button, MenuItem, Stack, Typography, useTheme} from "@mui/material";
import { useState } from "react";
import { StyledMenuItem } from "../../../../../components/StyledComponents/StyledMenuItem";
import StyledMenu from "../../../../../components/StyledComponents/StyledMenu";
import RemoveUserModal from "./RemoveUserModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function TableActionsUsers(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClickOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ width:"10rem", color: theme.palette.text.primary}}>
      <Button
        sx={{
          px: 0,
          justifyContent: "left",
        }}
        onClick={handleClickOpenMenu}
      >
        <MoreHorizIcon />
      </Button>
      <StyledMenu
        id="edit-delete-member-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <RemoveUserModal
            itemId={props.params.row}
            setAnchorEl={setAnchorEl}
          />
        </MenuItem>
      </StyledMenu>
    </Stack>
  );
}
