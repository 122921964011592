import TableActionsUsers from "./TableActionsUsers";
import {getCurrentAccountId} from "src/context/AccountContext";

export const userColumns = (t) => {
    const accountId = getCurrentAccountId();

    return [
        {
            field: "fullName",
            headerName: t("settings.accountDetailsPage.name"),
            flex: 1,
            renderHeader: () => (
                <strong>{t("settings.accountDetailsPage.name")}</strong>
            ),
            valueFormatter: ({value}) => {
                if (!value) {
                    return null;
                }
                return value;
            },
        },
        {
            field: "accounts",
            headerName: t("settings.accountDetailsPage.role"),
            flex: 1,
            sortable: true,
            renderHeader: () => (
                <strong>{t("settings.accountDetailsPage.role")}</strong>
            ),
            valueFormatter: ({value}) => {
                if (!value || value?.length === 0) {
                    return null;
                }

                return value.find((item) => item.account.id === parseInt(accountId))?.role
                    ?.roleType || ""
            },
        },
        {
            field: "actions",
            headerName: t("settings.accountDetailsPage.actions"),
            flex: 1,
            maxWidth: 100,
            sortable: false,
            renderHeader: () => (
                <strong>{t("settings.accountDetailsPage.actions")}</strong>
            ),
            renderCell: (params) => {
                return <TableActionsUsers params={params}/>;
            },
        },
    ];
};
