import { Typography, Stack } from "@mui/material";
import { motion } from "framer-motion";
import CheckIcon from '@mui/icons-material/Check';
import { StyledButtonPrimary } from "../../components/StyledComponents/StyledButtons";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function SuccessfulStripePayment() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirectToOverView = () => {
    navigate("/overview")
  }


  const containerCheckMark = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      width: "150px",
      height: "150px",
      display: "grid",
      overflow: "hidden",
      margin: 0,
      background: "var(--b4bTurquoise)",
      gap: "15px",
      padding: "15px",
      borderRadius: "50px",
    }
  };

  const containerText = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      width: "auto",
      height: "100%",
      display: "grid",
      overflow: "hidden",
      margin: 0,
      gap: "15px",
      padding: "15px",
      borderRadius: "50px",
    }
  };

  const containerButton = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      width: "auto",
      height: "100%",
      display: "grid",
      overflow: "hidden",
      margin: 0,
      gap: "15px",
      padding: "15px",
      borderRadius: 10,
    }
  };

  return (
    <Stack
      spacing={10}
      direction="column"
      sx={{
        background: "var(--b4bGrayBackground)",
        padding: 10,
        borderRadius: 10,
      }}
    >
      <Stack direction="row" justifyContent="center">
        <motion.ul
          variants={containerCheckMark}
          initial="hidden"
          animate="visible"
        >
          <CheckIcon sx={{ fontSize: "900%", color: "white" }} />
        </motion.ul>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <motion.ul variants={containerText} initial="hidden" animate="visible">
          <Typography variant="h3">
            {t("registerUser.paymentSuccessful")}
          </Typography>
        </motion.ul>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <motion.ul
          variants={containerButton}
          initial="hidden"
          animate="visible"
        >
          <StyledButtonPrimary
            sx={{ width: "10rem", height: "5rem", borderRadius: 5 }}
            onClick={() => redirectToOverView()}
          >
            {t("registerUser.continue")}
          </StyledButtonPrimary>
        </motion.ul>
      </Stack>
    </Stack>
  );
}



