import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import nb from './locales/nb';

const lng = window.navigator.language || "nb";
const languages = { en, nb };
i18n.use(initReactI18next).init({
  resources: {},
  lng: lng,
  fallbackLng: "en",

  ns: ["ns.translate"],
  defaultNS: ["ns.translate"],

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

Object.keys(languages).forEach(lang => {
  i18n.addResourceBundle(lang, 'ns.translate', languages[lang], true);
});

export default i18n;