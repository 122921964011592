import { useGetUserData } from "../../hooks/UserHook";

export default function SubscriptionPage(props) {
  const { accountId } = props;
  const userData = useGetUserData();

  return (
    <div>
      <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        client-reference-id={accountId}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        customer-email={userData.data.email}
      >
      </stripe-pricing-table>
    </div>
  )
}