import { useState } from "react";
import { Button, Dialog, DialogActions, TextField, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { updateCostPrice } from "../../../services/Transactions";
import {
  StyledDialogButtonCancel,
  StyledDialogButtonOK,
} from "../../../components/StyledComponents/StyledDialogButtons";
import StyledDialogContent from "../../../components/StyledComponents/StyledDialogContent";
import StyledDialogTitle from "../../../components/StyledComponents/StyledDialogTitle";

export default function ChangeCostPrice(props) {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isEditTransactionModalOpen, setIsEditTransactionModalOpen] =
    useState(false);
  
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    register,
    getValues,
  } = useForm({
    defaultValues: {
      price: props.itemId?.manualCostPrice || 0,
      comment: props.itemId?.manualCostPriceComment || "",
    },
  });

  const saveTransactionUpdate = useMutation(["updateCostPrice"], {
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      updateCostPrice(token, user?.sub, props.itemId.id, data);
    },
    onSuccess: async () => {
      await props.refetchPage({
        id: props.itemId.id,
        manualCostPrice: getValues("price"),
        manualCostPriceComment: getValues("comment"),
        status: "RESOLVED",
      });
      reset();
    },
    onError: () => {
      alert("Error");
    },
  });

  const handleOpenModal = () => {
    setIsEditTransactionModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditTransactionModalOpen(false);
    props.setAnchorEl(null);
    reset();
  };

  

  const onSubmit = async (data) => {
    saveTransactionUpdate.mutate(data);
    setIsEditTransactionModalOpen(false);
    props.setAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        sx={{ color: "#000", textTransform: "none" }}
        onClick={handleOpenModal}
      >
        {t("transactions.setManualCostPrice")}
      </Button>
      <Dialog open={isEditTransactionModalOpen} onClose={handleCloseModal}>
        <StyledDialogTitle>
          {t("transactions.editTransaction")}
        </StyledDialogTitle>
        <StyledDialogContent sx={{ width: 500 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ marginTop: 3 }}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    {...field}
                    label={t("transactions.manualCostPrice")}
                    {...register("price", {
                      required: t("common.required"),
                    })}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                  />
                )}
              />
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    {...field}
                    label={t("transactions.manualCostPriceComment")}
                    {...register("comment", {
                      required: t("common.required"),
                    })}
                    error={!!errors.comment}
                    helperText={errors.comment?.message}
                  />
                )}
              />
            </Stack>
          </form>
        </StyledDialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledDialogButtonCancel onClick={handleCloseModal}>
            {t("transactions.close")}
          </StyledDialogButtonCancel>
          <StyledDialogButtonOK onClick={handleSubmit(onSubmit)}>
            {t("transactions.save")}
          </StyledDialogButtonOK>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
