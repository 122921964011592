import { Select, CardHeader, styled } from "@mui/material";

export function StyledSelect(props) {
  const StyledSelect = styled(Select)(() => ({
    borderWidth: 0.5,
    borderRadius: "20px",
  }));

  return (
    <StyledSelect
      {...props}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      sx={{ fontSize: { xs: "14px", lg: "16px" } }}
    />
  );
}

export function StyledCardHeader(props) {
  const StyledCardHeader = styled(CardHeader)(() => ({}));

  return (
    <StyledCardHeader
      {...props}
      sx={{}}
      titleTypographyProps={{
        fontWeight: { xs: "500", lg: "600" },
        fontSize: { xs: "18px", lg: "24px" },
        color: "#090113",
      }}
    />
  );
}
