import {Box, Container, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {section2Texts} from "src/pages/NotAuthenticated/Frontpage/FrontpageTexts";
import React from "react";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";


export default function SalesPitchGrid() {
    const {t} = useTranslation();
    return (
        <Box
            component={"section"}
            sx={{
                py: {xs: 3, md: 10},
                mt: 16,
                overflow: "hidden",
                backgroundColor: "#F7F9FF"
            }}
        >
            <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
                <Typography
                    variant={"h2"}
                    gutterBottom
                >
                    {t("frontPage.section2header")}
                </Typography>
                <Stack
                    pt={3}
                    direction={{xs: "column", xl: "row"}}
                    sx={{width: "100%"}}
                    gap={2}
                >
                    <Typography
                        variant={"subtitle2"}
                        fontWeight={"400"}
                        sx={{
                            maxWidth: {sm: "40ch"},
                        }}
                        gutterBottom
                    >
                        {t("frontPage.section2content")}
                    </Typography>


                    <Grid container columns={12} sx={{width: "100%"}}
                          spacing={2}>
                        {section2Texts.map((item, index) => (
                            <Grid
                                item
                                key={index}
                                xs={12}
                                sm={6}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "stretch",
                                }}
                            >
                                <Stack
                                    sx={{
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                        flexGrow: 1,
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant={"h5"}
                                        component={"h3"}
                                    >
                                        {t(item.contentHeader)}
                                    </Typography>
                                    <Divider/>
                                    <Typography
                                        pt={1.5}
                                        variany={"body1"}
                                    >
                                        {t(item.contentBody)}
                                    </Typography>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
                {/* commented out since image may not be wanted
                                <Box
                    my={6}
                    component={"div"}
                    sx={{
                        backgroundImage: `url(${man_on_laptop_desktop})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                    maxWidth={"100%"}
                    height={"600px"}
                    borderRadius={"1.5rem"}
                />
                */}

            </Container>
        </Box>

    )
}