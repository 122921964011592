import {useAuth0} from "@auth0/auth0-react";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    Unstable_Grid2 as Grid,
    useTheme,
} from "@mui/material"

import {useMutation} from "@tanstack/react-query";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {StyledCircularProgress} from "../../../components/StyledComponents/StyledCircularProgress";
import {useAccountContext} from "../../../context/AccountContext";
import {useGetUserData,} from "../../../hooks/UserHook";
import {saveUser} from "../../../services/UserService";
import {useEffect, useState} from "react";
import {countries} from "./countries"
import {useSnackbarContext} from "../../../context/SnackbarContext";
import {accountDetailOptions} from "./helper";

export default function UserSettings(props) {
    const {
        isTutorial = false,
        triggerProps,
        updateSuccess = () => {
        },
        updateError = () => {
        },
    } = props;
    const {t} = useTranslation();
    const theme = useTheme()
    const userData = useGetUserData();
    const {user, getAccessTokenSilently} = useAuth0();
    const [countryValue, setCountryValue] = useState("NO");
    const {currentAccount} = useAccountContext();
    const {setDataSnackbar} = useSnackbarContext();

    const {
        handleSubmit,
        control,
        formState: {errors},
        register,
        getValues,
        clearErrors,
        trigger,
    } = useForm({
        defaultValues: {
            auth0Userid: userData?.data?.auth0Userid,
            name: userData?.data?.firstname + userData?.data?.lastname,
            firstname: userData?.data?.firstname,
            lastname: userData?.data?.lastname,
            personalNumber: userData?.data?.personalNumber,
            phoneNumber: userData?.data?.phoneNumber,
            email: userData?.data?.email,
            address: userData?.data?.address,
            postcode: userData?.data?.postcode,
            place: userData?.data?.place,
            country: userData?.data?.country,
            pricePlanId: userData?.data?.pricePlanId || 1,
            aptSuiteEtc: userData?.data?.aptSuiteEtc || "",
            paymentInfo: {
                id: 1,
                cardHolder: "",
                cardNumber: "",
                ccv: "",
                expiryMonth: "",
                expiryYear: "",
            },
        },
    });

    const saveUpdate = useMutation(["saveUpdate"], {
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();
            saveUser(
                token,
                user?.sub,
                currentAccount.accountId,
                userData.data?.id,
                data
            );
        },
        onSuccess: async () => {
            clearErrors();
            await userData.refetch();
            if (isTutorial) {
                updateSuccess();
            }
            setDataSnackbar({
                isOpen: true,
                type: "success",
                message: t("settings.accountDetailsPage.updateSuccess"),
                action: null,
            });
        },
        onError: async () => {
            if (isTutorial) {
                updateError();
            }
            setDataSnackbar({
                isOpen: true,
                severity: "error",
                message: t("settings.accountDetailsPage.updateError"),
                action: null,
            });
        },
    });

    const clickSubmit = async (data) => {
        saveUpdate.mutate(data);
        await userData.refetch();
    };

    const checkTrigger = async () => {
        const dataTrigger = await trigger();
        if (dataTrigger) {
            const data = getValues();
            clickSubmit(data);
        } else {
            updateError();
        }
    };

    useEffect(() => {
        if (triggerProps && isTutorial) {
            checkTrigger();
        }
    }, [triggerProps, isTutorial]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setCountryValue(newValue);
    };

    return (
        <Box sx={{width: "100%"}}>
            <Stack
                direction={"column"}
                maxWidth={"xl"}
            >
                {!userData.isLoading ? (
                    <>
                        <form onSubmit={handleSubmit(clickSubmit)}>
                            <Grid
                                direction={"column"}
                                sx={{
                                    border: `1px solid ${theme.palette.primary[50]}`,
                                    boxShadow: theme.shadows.sm,
                                    borderRadius: "1.5rem",
                                    backgroundColor: theme.palette.background.paper
                                }}
                                px={3}
                                py={3}
                            >
                                {!isTutorial && (
                                    <Typography
                                        variant={"h5"}
                                        gutterBottom
                                    >
                                        {t("settings.accountDetailsPage.personalDetails")}
                                    </Typography>
                                )}

                                <Grid container xs={12} lg={12} direction={"row"} spacing={2}>
                                    <Grid xs={12} lg={6}>
                                        <Controller
                                            name="firstname"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: theme.palette.background[900],
                                                        }
                                                    }}
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t("settings.accountDetailsPage.firstName")}
                                                    {...register(
                                                        "firstname",
                                                        accountDetailOptions.firstname
                                                    )}
                                                    error={!!errors.firstname}
                                                    helperText={errors.firstname?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={12} lg={6}>
                                        <Controller
                                            name="lastname"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: theme.palette.background[900],
                                                        }
                                                    }}
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t("settings.accountDetailsPage.lastName")}
                                                    {...register(
                                                        "lastname",
                                                        accountDetailOptions.lastname
                                                    )}
                                                    error={!!errors.lastname}
                                                    helperText={errors.lastname?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={12} lg={2.5}>
                                        <Controller
                                            name="personalNumber"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: theme.palette.background[900],
                                                        }
                                                    }}
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t(
                                                        "settings.accountDetailsPage.personalNumber"
                                                    )}
                                                    {...register(
                                                        "personalNumber",
                                                        accountDetailOptions.personalNumber
                                                    )}
                                                    error={!!errors?.personalNumber}
                                                    helperText={errors.personalNumber?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={12} lg={3.5}>
                                        <Stack direction={"row"}>
                                            <FormControl
                                                size="small"
                                                sx={{
                                                    minWidth: 75,
                                                }}
                                            >
                                                <Select
                                                    sx={{
                                                        borderRight: "0px solid white",
                                                        borderRadius: 0,
                                                    }}
                                                    value={countryValue}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    renderValue={() => {
                                                        if (countryValue) {
                                                            return (
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    srcSet={`https://flagcdn.com/w40/${countryValue?.toLowerCase()}.png 2x`}
                                                                    src={`https://flagcdn.com/w20/${countryValue?.toLowerCase()}.png`}
                                                                    alt=""
                                                                />
                                                            );
                                                        } else return <></>;
                                                    }}
                                                >
                                                    {countries.map((item, index) => (
                                                        <MenuItem key={index} value={item.code}>
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                                                                src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                                                                alt=""
                                                            />
                                                            {` +${item.phone} ${item.label}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <Controller
                                                name="phoneNumber"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: theme.palette.background[900],
                                                            }
                                                        }}
                                                        size="small"
                                                        {...field}
                                                        label={t(
                                                            "settings.accountDetailsPage.phoneNumber"
                                                        )}
                                                        {...register(
                                                            "phoneNumber",
                                                            accountDetailOptions.phoneNumber
                                                        )}
                                                        error={!!errors.phoneNumber}
                                                        helperText={errors.phoneNumber?.message}
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid xs={12} lg={6}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: theme.palette.background[900],
                                                        }
                                                    }}
                                                    size="small"
                                                    sx={{width: "100%"}}
                                                    {...field}
                                                    label={t("settings.accountDetailsPage.email")}
                                                    {...register("email", accountDetailOptions.email)}
                                                    error={!!errors.email}
                                                    helperText={errors.email?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                {!isTutorial && (
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: "600",
                                                mt: "32px",
                                                mb: "16px",
                                            }}
                                        >
                                            {t("settings.accountDetailsPage.address")}
                                        </Typography>
                                        <Grid
                                            container
                                            xs={12}
                                            lg={12}
                                            direction={"row"}
                                            spacing={2}
                                        >
                                            <Grid xs={12} lg={6}>
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: theme.palette.background[900],
                                                                }
                                                            }}
                                                            size="small"
                                                            sx={{width: "100%"}}
                                                            {...field}
                                                            label={t(
                                                                "settings.accountDetailsPage.streetAddress"
                                                            )}
                                                            {...register(
                                                                "address",
                                                                accountDetailOptions.address
                                                            )}
                                                            error={!!errors.address}
                                                            helperText={errors.address?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={12} lg={6}>
                                                <Controller
                                                    name="aptSuiteEtc"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: theme.palette.background[900],
                                                                }
                                                            }}
                                                            size="small"
                                                            sx={{width: "100%"}}
                                                            {...field}
                                                            label={t(
                                                                "settings.accountDetailsPage.aptSuiteEtc"
                                                            )}
                                                            {...register(
                                                                "aptSuiteEtc",
                                                                accountDetailOptions.aptSuiteEtc
                                                            )}
                                                            error={!!errors.aptSuiteEtc}
                                                            helperText={errors.aptSuiteEtc?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={12} lg={2.5}>
                                                <Controller
                                                    name="postcode"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: theme.palette.background[900],
                                                                }
                                                            }}
                                                            size="small"
                                                            sx={{width: "100%"}}
                                                            {...field}
                                                            label={t(
                                                                "settings.accountDetailsPage.postcode"
                                                            )}
                                                            {...register(
                                                                "postcode",
                                                                accountDetailOptions.postcode
                                                            )}
                                                            error={!!errors.postcode}
                                                            helperText={errors.postcode?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={12} lg={3.5}>
                                                <Controller
                                                    name="place"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: theme.palette.background[900],
                                                                }
                                                            }}
                                                            size="small"
                                                            sx={{width: "100%"}}
                                                            {...field}
                                                            label={t("settings.accountDetailsPage.city")}
                                                            {...register(
                                                                "place",
                                                                accountDetailOptions.city
                                                            )}
                                                            error={!!errors.place}
                                                            helperText={errors.place?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid xs={12} lg={6}>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            InputLabelProps={{
                                                                sx: {
                                                                    color: theme.palette.background[900],
                                                                }
                                                            }}
                                                            size="small"
                                                            sx={{width: "100%"}}
                                                            {...field}
                                                            label={t("settings.accountDetailsPage.country")}
                                                            {...register("country", {
                                                                required: t(
                                                                    "settings.accountDetailsPage.countryError"
                                                                ),
                                                            })}
                                                            error={!!errors.country}
                                                            helperText={errors.country?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Button
                                                onClick={clickSubmit}
                                                type="submit"
                                                sx={{
                                                    backgroundColor: '#002554',
                                                    color: 'white',
                                                    padding: '10px 16px',
                                                    fontSize: '1rem',
                                                    fontWeight: 500,
                                                    borderRadius: '9999px',
                                                    textTransform: "capitalize",
                                                    boxShadow: 0,
                                                    '&:hover': {
                                                        backgroundColor: "#7D9BC1",
                                                    },
                                                    '&:focusVisible': {
                                                        outline: '2px solid',
                                                        outlineColor: '#002554',
                                                        outlineOffset: 2,
                                                    },
                                                }}
                                            >
                                                <Typography
                                                    variant={"body1"}
                                                    fontWeight={"500"}
                                                >
                                                    {t("settings.save")}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </form>
                    </>
                ) : (
                    <StyledCircularProgress/>
                )}
            </Stack>
        </Box>
    );
}
