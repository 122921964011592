import { Button, Dialog, Typography, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import StyledDialogContent from "../../../../components/StyledComponents/StyledDialogContent";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { uploadFile } from "../../../../services/BlockchainService";
import { useAccountContext } from "../../../../context/AccountContext";
import ConfirmDialog from "src/components/dialog/ConfirmDialog";
import BaseButton from "src/components/button/BaseButton";
import { separateComma } from "src/common/numberFormat";

import './styled.scss'

const fileTypes = ["CSV"];
const MAX_FILE_SIZE = 5;
export default function UploadFileModal(props) {
  const { type = "uploadNBX" } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const { currentAccount } = useAccountContext();

  const { t } = useTranslation();
  const [isAddExchangeModalOpen, setIsAddExchangeModalOpen] = useState(null);
  const [file, setFile] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState("");
  const [status, setStatus] = useState("");
  const [fileSizeOver, setFileSizeOver] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const callAPIUploadFile = useMutation(["uploadFile"], {
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      await uploadFile(token, user?.sub, currentAccount.accountId, type, data);
    },
    onSuccess: async () => {
      await props.refetchPage();
      handleCloseAddExchangeModal();
      setOpenConfirmDialog(true);
      setStatus("success");
      setContentDialog(t("settings.uploadSuccess"));
      setFile("");
    },
    onError: async () => {
      setOpenConfirmDialog(true);
      setStatus("failed");
      setContentDialog(t("settings.uploadFail"));
      setFile("");
    },
  });

  const handleChange = (file) => {
    setFile(file);
    const fileSize = file?.size / 1024 / 1024;
    setFileSize(fileSize);
    if (fileSize > MAX_FILE_SIZE) {
      setFileSizeOver(true);
    } else {
      setFileSizeOver(false);
    }
  };

  const handleRemove = () => {
    setFile(null);
  };

  const handleOpenAddExchangeModal = () => {
    setIsAddExchangeModalOpen(true);
  };
  const handleCloseAddExchangeModal = () => {
    setIsAddExchangeModalOpen(false);
    setErrorMsg("")
    setFile(null);
  };

  const validateSelectedFile = (file) => {
    // const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 102400; // 100MB

    const fileSizeKiloBytes = file.size / 1024;

    let checkStatus = false

    // todo : in the feature will be continue develop
    // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
    //   setErrorMsg("File size is less than minimum limit");
    //   return checkStatus = true
    // }
    if (fileSizeKiloBytes >= MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      return checkStatus = true
    }
    return checkStatus
  };

  const handleUploadFile = () => {
    if (!validateSelectedFile(file)) {
      const formData = new FormData();
      formData.append("file", file);
      callAPIUploadFile.mutate({ file: file });
    }
  };

  return (
    <>
      <Stack>
        <Button
          onClick={handleOpenAddExchangeModal}
          variant="text"
          sx={{ color: "black", textTransform: "none" }}
          endIcon={<FileUploadOutlinedIcon />}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {t("settings.exchangeSettingsPage.uploadFile")}
          </Typography>
        </Button>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={isAddExchangeModalOpen}
          onClose={handleCloseAddExchangeModal}
        >
          <StyledDialogContent
            sx={{
              alignItems: "center",
              alignContent: "center",
              px: { xs: "16px", md: "64px" },
              py: { xs: "16px", md: "64px" },
            }}
          >
            <Stack
              maxWidth="xl"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
                px: { xs: "16px", md: "64px" },
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    textAlign: "center",
                    color: "#000000",
                    mb: 2,
                  }}
                >
                  {t("settings.uploadFile")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    textAlign: "center",
                    color: "#626262",
                  }}
                >
                  {t("settings.uploadDesception")}
                </Typography>

                <FileUploader
                  multiple={false}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                >
                  <Stack
                    sx={{
                      width: "100%",
                      height: "100%",
                      gap: "26px",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid green",
                      my: "48px",
                      py: "24px",
                    }}
                  >
                    <UploadFileOutlinedIcon size={40} />
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "#000000",
                        mb: 2,
                        fontFamily: "Roobert",
                      }}
                    >
                      {t("settings.dragAndDrop")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                        textAlign: "center",
                        fontFamily: "Roobert",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "143%",
                        letterSpacing: "0.17px",
                      }}
                    >
                      {t("settings.or")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        fontFamily: "Roobert",
                        fontSize: "20px",
                        fontWeight: "300",
                        lineHeight: "120%" /* 24px */,
                      }}
                    >
                      {t("settings.clickToUploadFile")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                        textAlign: "center",
                        fontFamily: "Roobert",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "143%",
                        letterSpacing: "0.17px",
                      }}
                    >
                      {t("settings.maximumFileSize")}
                    </Typography>
                  </Stack>
                </FileUploader>

                <Stack>
                  {!!file && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <PictureAsPdfIcon
                          sx={{ width: "56px", height: "56px" }}
                        />

                        <Stack ml={1}>
                          <Typography
                            sx={{
                              color:
                                "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                              fontFamily: "Roobert",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "143%",
                              letterSpacing: "0.17px",
                            }}
                          >
                            {`File name: ${file?.name}`}
                          </Typography>
                          <Typography
                            sx={{
                              color:
                                "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                              fontFamily: "Roobert",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "143%",
                              letterSpacing: "0.17px",
                            }}
                          >
                            {`${separateComma(fileSize?.toFixed(4))} MB`}
                          </Typography>
                          {fileSizeOver && (
                            <Typography
                              sx={{
                                color: "red",
                                fontFamily: "Roobert",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "143%",
                                letterSpacing: "0.17px",
                              }}
                            >
                              {t("settings.fileSizeOver")}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>

                      <CloseIcon onClick={() => handleRemove()} />
                    </Stack>
                  )}
                </Stack>
                {errorMsg.length > 0 ? (
                  <div>
                    <p className="error-message">{errorMsg}</p>
                  </div>
                ) : (
                  ""
                )}
                <Stack
                  gap={2}
                  direction={"row"}
                  justifyContent={"center"}
                  mt={2}
                  sx={{
                    flex: 1,
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleCloseAddExchangeModal}
                    sx={{
                      flex: 1,
                      px: "32px",
                      height: 48,
                      color: "#090113",
                      border: "1px solid #090113",
                      borderRadius: "24px",
                      textTransform: "none",
                      fontSize: "18px",
                      fontWeight: "500",
                      "&:hover": {
                        backgroundColor: "#000000",
                        color: "#fff",
                        border: "1px solid #000000",
                      },
                    }}
                    variant="outlined"
                  >
                    {t("settings.back")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (!file) {
                        setErrorMsg("Please choose file!");
                      } else {
                        handleUploadFile();
                      }
                    }}
                    sx={{
                      flex: 1,
                      px: "32px",
                      height: 48,
                      color: "#FFFFFF",
                      border: "1px solid #000000",
                      borderRadius: "24px",
                      textTransform: "none",
                      bgcolor: "#000000",
                      fontSize: "18px",
                      fontWeight: "500",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#000000",
                        border: "1px solid #000000",
                      },
                      "&:disabled": {
                        backgroundColor: "#11315880",
                        border: "solid 0px #11315880",
                        color: "white",
                      },
                    }}
                    size="small"
                  >
                    {t("settings.send")}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </StyledDialogContent>
        </Dialog>
      </Stack>
      <ConfirmDialog open={openConfirmDialog} width={800} title={status}>
        <Box
          sx={{
            color: "text.primary",
            mt: 2,
          }}
        >
          <Typography variant="h5">{contentDialog}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            bgcolor: "background.paper",
            borderRadius: 1,
            mt: 2,
          }}
        >
          <BaseButton
            title="OK"
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          />
        </Box>
      </ConfirmDialog>
    </>
  );
}
