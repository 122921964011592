import {useForm} from "react-hook-form";
import {useAuth0} from "@auth0/auth0-react";
import {useMutation} from "@tanstack/react-query";
import {saveExchangedata} from "../../../../services/ExchangeService";
import {useSnackbarContext} from "src/context/SnackbarContext";
import {Stack} from "@mui/material";
import Footer from "./Footer";

export default function ExchangeBox(props) {
    const {
        exchange = "NBX",
        typeUpload = "uploadNBX",
        locationConnectUrl = "",
    } = props;
    const {user, getAccessTokenSilently} = useAuth0();
    const {setDataSnackbar} = useSnackbarContext();

    const {handleSubmit, reset} = useForm({
        defaultValues: {
            apiAccountId: "",
            apikey: "",
            apiPassPhrase: "",
            apiSecret: "",
        },
    });

    const {mutate: mutateData} = useMutation({
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();

            const dataResult = await saveExchangedata(token, user?.sub, {
                ...data,
                exchange: exchange,
            });
            if (dataResult) {
                setDataSnackbar({
                    isOpen: true,
                    severity: "success",
                    message: "Success",
                    action: null,
                });
                setTimeout(() => {
                    setDataSnackbar({
                        isOpen: false,
                        severity: "success",
                        message: "",
                        action: null,
                    });
                }, 3000);
            } else {
                setDataSnackbar({
                    isOpen: true,
                    severity: "error",
                    message: "Error",
                    action: null,
                });
            }
        },
    });

    const onSubmit = (data) => {
        mutateData(data);
        reset();
    };

    function connectExchange() {
        if (!locationConnectUrl) {
            alert("Comming soon...");
        } else {
            window.location.replace(locationConnectUrl);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {(
                <Stack sx={{px: "32px", pb: "32px"}}>
                    <Footer type={typeUpload} onConnect={() => connectExchange()}/>
                </Stack>
            )}
        </form>
    );
}
