import {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Box, ButtonBase, Divider, MenuItem, Popover, Typography,} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import {useAccountContext} from "src/context/AccountContext";
import {logoutHelpCrunch} from "src/common/helpcrunch";
import {useTranslation} from "react-i18next";

export const AccountPopover = (props) => {
    const {
        anchorEl, onClose = () => {
        }, open, accountData = {},
        setTermsDialogOpen
    } = props;
    const {logout} = useAuth0();
    const [accounts, setAccounts] = useState([]);
    const [valueAccountId, setAccountId] = useState("");
    const {updateCurrentAccount} = useAccountContext();
    const {t} = useTranslation()

    useEffect(() => {
        if (accountData) {
            if (accountData?.accounts?.length > 0) {
                let accountsTemp = [];
                for (let acc of accountData?.accounts) {
                    accountsTemp.push({
                        label: acc.account?.name,
                        value: acc.account?.id,
                    });
                }

                setAccounts(accountsTemp);
                const id = accountData.accounts[0].account.id;
                updateCurrentAccount({
                    accountId: id,
                    account: {account: accountData.accounts[0].account},
                });
                sessionStorage.setItem("accountId", id);
                setAccountId(id);
            }
        }
    }, [accountData]);

    const handleChange = (newValue) => {
        onClose();
        let accountTemp = {};
        for (let i = 0; i < accountData.accounts?.length; i++) {
            if (accountData.accounts[i].account.id !== newValue) continue;
            accountTemp = accountData.accounts[i].account;
        }

        updateCurrentAccount({
            accountId: newValue,
            account: {account: accountTemp},
        });
        sessionStorage.setItem("accountId", newValue);
        setAccountId(newValue);
    };

    const handleSignOut = useCallback(() => {
        onClose();
        logout({returnTo: window.location.origin});
        sessionStorage.removeItem("accountId");
        sessionStorage.removeItem("lng");

        logoutHelpCrunch();
    }, [onClose]);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
            }}
            sx={{mt: "8px"}}
            onClose={onClose}
            open={open}
            PaperProps={{sx: {width: {sx: 100, md: 200, lg: 300}}}}
        >
            <Box>
                <Typography px={2} variant="overline">
                    {t("common.account")}
                </Typography>
                {accounts.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item.value}
                        onClick={() => handleChange(item.value)}
                        sx={{
                            backgroundColor: valueAccountId === item.value ? "#E9EAF1" : "white",
                        }}
                    >
                        <Typography variant={"body2"}>
                            {item.label}
                        </Typography>
                    </MenuItem>
                ))}
            </Box>
            <Divider/>
            <Box>
                <Typography px={2} variant={"overline"}>{t("common.links")}</Typography>
                <MenuItem
                    onClick={() => setTermsDialogOpen(true)}
                >
                    <Typography variant={"body2"}>
                        {t("common.termsAndConditions")}
                    </Typography>
                </MenuItem>
            </Box>
            <Divider/>
            <ButtonBase
                onClick={handleSignOut}
                sx={{
                    alignItems: "center",
                    py: 1.5,
                    px: 2,
                    textAlign: "left",
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                    },
                }}
            >
                <Box
                    component="span"
                    sx={{
                        alignItems: "center",
                        display: "inline-flex",
                        justifyContent: "center",
                        mr: 2,
                    }}
                >
                    <LogoutIcon/>
                </Box>
                <Box
                    component="span"
                    sx={{
                        flexGrow: 1,
                        fontFamily: "Inter",
                        fontSize: "16px",
                    }}
                >
                    {t("common.logOut")}
                </Box>
            </ButtonBase>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
};
