import {createContext, useContext, useMemo, useReducer} from "react";
import {useGetUserData} from "../hooks/UserHook";

const AccountContext = createContext();

export const getCurrentAccountId = () => {
    return sessionStorage.getItem("accountId");
};

export const AccountContextProvider = ({children}) => {
    const userData = useGetUserData();
    const defaultAccount = sessionStorage.getItem("accountId");
    const [currentAccount, changeCurrentAccount] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {accountId: defaultAccount, account: null}
    );

    useMemo(() => {
        if (!currentAccount.account) {
            const selectedAccount = userData.data?.accounts.find(
                (t) => t.account.id.toString() === defaultAccount
            );
            changeCurrentAccount({
                account: selectedAccount,
            });
        }
    }, [defaultAccount, userData.data?.accounts, currentAccount.account]);

    return (
        <AccountContext.Provider
            value={{currentAccount, updateCurrentAccount: changeCurrentAccount}}
        >
            {children}
        </AccountContext.Provider>
    );
};

export const useAccountContext = () => {
    const {currentAccount, updateCurrentAccount} = useContext(AccountContext);
    return {currentAccount, updateCurrentAccount};
};
