import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import {AccountPopover} from "./AccountPopover";
import {usePopover} from "src/hooks/use-popover";
import {itemsAppBar} from "./config";
import {AppBarItem} from "./AppBarItem";
import {Link, useLocation} from "react-router-dom";
import {Container, Stack, useTheme} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useAccountContext} from "src/context/AccountContext";
import logo from 'src/assets/png/logo.png'
import logo_mobile from 'src/assets/png/logo_mobile_white.png'
import MobileNavigation from "src/components/RootLayout/components/Authenticated/MobileNavigation";
import MissingTermsPopup from "src/components/RootLayout/components/Authenticated/MissingTermsPopup";

function ResponsiveAppBar(props) {
    const {accountData = {}} = props;
    const {user} = useAuth0();
    const {currentAccount} = useAccountContext();
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [termsDialogOpen, setTermsDialogOpen] = useState(false)
    const location = useLocation();
    const pathname = location.pathname;
    const accountPopover = usePopover();

    useEffect(() => {
        if (!currentAccount) return
        if (currentAccount.account && !currentAccount.account.account.termsAccpeted) {
            setTermsDialogOpen(true)
        }
    }, [currentAccount])

    return (
        <AppBar position="static" sx={{backgroundColor: theme.palette.primary[900], paddingY: "0.3rem"}}>
            <Container maxWidth="xxl">
                <Toolbar disableGutters>
                    <Stack direction="row" spacing={2}
                           sx={{flexGrow: 1, alignItems: 'center', justifyContent: "space-between"}}>
                        <Stack direction={"row"} spacing={2}>
                            <Box component={Link} to="/overview">
                                <Box component={"img"} src={logo} alt={"logo"}
                                     sx={{display: {xs: "none", xxl: "block"}}} mr={1}/>
                                <Box component={"img"} src={logo_mobile} alt={""} sx={{display: {xxl: "none"}}} mr={1}/>
                            </Box>
                            <Stack direction={"row"} sx={{display: {xs: "none", lg: "flex"}}}
                                   spacing={{xs: 1, lg: 1.5, xl: 2, xxl: 2.5}}>
                                {itemsAppBar.map((item) => {
                                    const active = item.path ? pathname === item.path : false;
                                    const activeSettings =
                                        [
                                            "/settings/accountDetails",
                                            "/settings/blockchainAddress",
                                            "/settings/blockchainExchange",
                                            "settings/sacrificePayments",
                                        ].includes(pathname) && item.path === "/settings";
                                    return (
                                        <AppBarItem
                                            active={active || activeSettings}
                                            disabled={item.disabled}
                                            external={item.external}
                                            icon={item.icon}
                                            key={item.title}
                                            path={item.path}
                                            title={item?.title}
                                        />
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                            <IconButton
                                sx={{display: {lg: "none"}}}
                                size="xl"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setMobileOpen(!mobileOpen)}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                onClick={accountPopover.handleOpen}
                            >
                                <Avatar
                                    ref={accountPopover.anchorRef}
                                    sx={{
                                        cursor: "pointer",
                                        height: 40,
                                        width: 40,
                                    }}
                                    src="/assets/avatars/avatar-anika-visser.png"
                                />
                                <Stack
                                    sx={{
                                        display: {xs: "none", xl: "flex"},
                                    }}
                                >
                                    <Typography variant={"subtitle2"}>
                                        {`${
                                            currentAccount?.account?.account?.name || "Your name"
                                        } - ${user?.nickname || "Nick name"}`}
                                    </Typography>
                                    <Typography variant={"body2"} sx={{lineHeight: "20px"}}>
                                        {user?.email || "Email"}
                                    </Typography>
                                </Stack>

                                <KeyboardArrowDownIcon/>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
                <MobileNavigation mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
                <AccountPopover
                    anchorEl={accountPopover.anchorRef.current}
                    open={accountPopover.open}
                    onClose={accountPopover.handleClose}
                    accountData={accountData}
                    termsDialogOpen={termsDialogOpen}
                    setTermsDialogOpen={setTermsDialogOpen}
                />
            </Container>
            {currentAccount && currentAccount.account && (
                <MissingTermsPopup
                    open={termsDialogOpen}
                    setOpen={setTermsDialogOpen}
                    termsAccepted={currentAccount.account.account.termsAccpeted}/>
            )}

        </AppBar>

    );
}

export default ResponsiveAppBar;