import React from 'react';
import {Box, Container, useTheme} from "@mui/material";
import TermsAndConditionsMD from "src/pages/TermsAndConditions/TermsAndConditionsMD";

function TermsAndConditionsPage() {
    const theme = useTheme();
    return (
        <Box
            component="main"
            backgroundColor={theme.palette.background.paper}
            sx={{
                flexGrow: 1,
                py: 12
            }}
        >
            <Container maxWidth={"xxl"} px={{xxl: "0px"}}>
                <Box sx={{
                    p: 3,
                    borderRadius: "1.5rem",
                    backgroundColor: "#ffffff",
                    border: `1px solid ${theme.palette.background[100]}}`,
                    boxShadow: theme.shadows.md
                }}>
                    <TermsAndConditionsMD/>
                </Box>
            </Container>
        </Box>
    );
}

export default TermsAndConditionsPage;