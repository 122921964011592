import {styled, alpha} from "@mui/material/styles";
import {Menu} from "@mui/material";

export default function StyledMenu(props) {
    const StyledMenu = styled((props) => <Menu elevation={3} {...props} />)(
        ({theme}) => ({
            "& .MuiPaper-root": {
                borderRadius: 6,
                marginTop: theme.spacing(1),
                minWidth: 180,
                boxShadow:
                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                "& .MuiMenu-list": {
                    padding: "4px 0",
                },
                "& .MuiMenuItem-root": {
                    "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: theme.palette.text.primary,
                        marginRight: theme.spacing(1.5),
                    },
                    "&:active": {
                        backgroundColor: alpha(
                            theme.palette.primary[900],
                            theme.palette.action.selectedOpacity
                        ),
                    },
                },
            },
        })
    );

    return (
        <StyledMenu
            {...props}
            PaperProps={{
                elevation: 3,
            }}
        >
            {props.children}
        </StyledMenu>
    );
}
