import { axios } from "./ApiConfig";
import {
  URL_GET_PORTFOLIO_OVERVIEW,
  URL_GET_PORTFOLIO_RESULT,
  URL_GET_TRANSACTIONS_REPORT,
  URL_GET_CALCULATIONS_REPORT,
  URL_GET_PORTFOLIO_REPORT,
  URL_GET_TIME_SERIES,
  URL_GET_RESULT_AND_TAX_GRAPH,
  URL_GET_PORTFOLIO_GRAPH,
  URL_GET_ALLOCATION_GRAPH_DATA,
  URL_GET_MESSAGE_FOR_USER,
  URL_ADD_COIN_HIDE,
} from "./config";

export const getPortfolioOverview = async (token, auth0Id, accountId) => {
  const URL = URL_GET_PORTFOLIO_OVERVIEW(accountId);
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const getPortfolioResult = async (
  token,
  auth0Id,
  accountId,
  needFormatData = false
) => {
  const URL = URL_GET_PORTFOLIO_RESULT(accountId);
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      if (needFormatData) {
        let dataTemp = [];

        for (let year of response.data.years) {
          dataTemp.push({ ...year, id: year.year });
        }
        return dataTemp;
      }

      return response.data;
    });
};

export const getPortfolioReport = async (
  token,
  auth0Id,
  accountId,
  data,
  needFormatData = false
) => {
  const URL = URL_GET_PORTFOLIO_REPORT(accountId, data);
  const axiosConfig = {
    responseType: "arraybuffer",
    header: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(token, auth0Id, data)
    .get(URL, axiosConfig)
    .then((response) => {
      if (needFormatData) {
        let dataTemp = [];

        for (let year of response.data.years) {
          dataTemp.push({ ...year, id: year.year });
        }
        return dataTemp;
      }

      return response.data;
    });
};

export const getTransactionsPDF = async (token, auth0Id, accountId, data) => {
  const URL = URL_GET_TRANSACTIONS_REPORT(accountId);
  const axiosConfig = {
    responseType: "arraybuffer",
    header: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(token, auth0Id, data)
    .get(URL, axiosConfig)
    .then((response) => {
      return response.data;
    });
};

export const getCalculationsPDF = async (token, auth0Id, accountId, data) => {
  const URL = URL_GET_CALCULATIONS_REPORT(accountId, data);
  const axiosConfig = {
    responseType: "arraybuffer",
    header: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(token, auth0Id, data)
    .get(URL, axiosConfig)
    .then((response) => {
      return response.data;
    });
};

export const resultAndTaxGraph = async (token, auth0Id, accountId) => {
  const URL = URL_GET_RESULT_AND_TAX_GRAPH(accountId);
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const getMessageForAccount = async (token, auth0Id, accountId) => {
  const URL = URL_GET_MESSAGE_FOR_USER(accountId);
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const portfolioValueGraph = async (
  token,
  auth0Id,
  accountId,
  typeTimeSpan,
  startDate,
  endDate
) => {
  const typeTimeSpanTemp =
    typeTimeSpan === "MONTH1"
      ? "MONTH"
      : typeTimeSpan === "MONTH2"
      ? "MONTH"
      : typeTimeSpan;
  const URL = URL_GET_PORTFOLIO_GRAPH(
    accountId,
    typeTimeSpanTemp,
    startDate,
    endDate
  );
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const allocationGraphData = async (token, auth0Id, accountId) => {
  const URL = URL_GET_ALLOCATION_GRAPH_DATA(accountId);
  return axios(token, auth0Id)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const getTimeSeries = async (token, auth0Id, data) =>
  axios(token, auth0Id)
    .post(URL_GET_TIME_SERIES, data)
    .then((response) => {
      return response.data;
    });

export const hideCoin = async (token, auth0Id, data) =>
  axios(token, auth0Id)
    .post(URL_ADD_COIN_HIDE, data)
    .then((response) => {
      return response.data;
    });
