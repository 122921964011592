import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { t } from "i18next";
import { formatDate } from "../../../common/dateFormat";
import { separateCommaTrans } from "../../../common/numberFormat";

function createData(name, value) {
  return { name, value };
}

function getDataRows(type, row, coinName = null) {
  if (type === t("transactions.information"))
    return [
      createData(t("transactions.txHash"), row.txHash),
      createData(t("transactions.addressFrom"), row.fromAddress),
      createData(t("transactions.addressTo"), row.toAddress),
      createData(t("transactions.date"), row.transactionDate),
      createData(t("transactions.transactionType"), row.transactionType),
      row.manualCostPrice
        ? createData(t("transactions.manualCostPrice"), row.manualCostPrice)
        : undefined,
      row.manualCostPriceComment
        ? createData(
            t("transactions.manualCostPriceComment"),
            row.manualCostPriceComment
          )
        : undefined,
      row.manualRealisationPrice
        ? createData(
            t("transactions.manualRealisationPrice"),
            row.manualRealisationPrice
          )
        : undefined,
      row.manualRealisationPriceComment
        ? createData(
            t("transactions.manualRealisationPriceComment"),
            row.manualRealisationPriceComment
          )
        : undefined,
    ];
  if (type === t("transactions.fees"))
    return [
      createData(
        t("transactions.coin"),
        separateCommaTrans(row.prices.feeInCoin, null, 5)
      ),
      createData(
        t("transactions.usd"),
        separateCommaTrans(row.prices.feeInUSD, 2, 2)
      ),
      createData(
        t("transactions.nok"),
        separateCommaTrans(row.prices.feeInNOK, 2, 2)
      ),
      createData(
        t("transactions.blockchainCoin"),
        separateCommaTrans(row.prices.feeInBlockchainCoin, null, 5)
      ),
    ];

  if (type === t("transactions.prices"))
    return [
      createData(
        t("transactions.coin"),
        separateCommaTrans(
          row.prices.amountInCoin?.toLocaleString(undefined, {
            maximumFractionDigits: 5,
          })
        )
      ),
      createData(
        t("transactions.usd"),
        separateCommaTrans(row.prices.amountInUSD, 2, 2)
      ),
      createData(
        t("transactions.nok"),
        separateCommaTrans(row.prices.amountInNOK, 2, 2)
      ),
    ];
  if (type === t("transactions.exchangeRates")) {
    for (let exchangeRate of row.exchangeRates) {
      if (exchangeRate?.coin !== null && coinName === exchangeRate.coin?.name) {
        return [
          createData(t("transactions.coin"), exchangeRate?.coin?.name),
          createData(
            t("transactions.pricePerUSD"),
            `${separateCommaTrans(exchangeRate?.price, 0, 3)} ${
              exchangeRate?.baseCurrency
            }`
          ),
        ];
      }
    }

    return [];
  }

  if (type === t("transactions.stakeInformation"))
    return [
      createData(t("transactions.stakeType"), row?.stakes[0]?.type),
      createData(t("transactions.stakeId"), row?.stakes[0]?.stakeId),
      createData(
        t("transactions.stakeEnded"),
        row?.stakes[0]?.ended ? t("common.yes") : t("common.no")
      ),
      createData(
        t("transactions.interest"),
        separateCommaTrans(row?.stakes[0]?.interest, null, 5)
      ),
      createData(t("transactions.penalty"), row?.stakes[0]?.penalty),
      createData(
        t("transactions.coinsPaidAtEnd"),
        row?.stakes[0]?.coinPayedAtEnd
      ),
    ];
  if (type === t("transactions.swapInformation"))
    return [
      createData(t("transactions.coinSwappedFrom"), row?.coinSwappedFrom.name),
      createData(
        t("transactions.amountSwappedFrom"),
        row.amountSwappedFrom?.toLocaleString(undefined, {
          maximumFractionDigits: 5,
        })
      ),
      createData(t("transactions.coinSwappedTo"), row?.coin.name),
      createData(
        t("transactions.amountSwappedTo"),
        separateCommaTrans(row.amount, null, 5)
      ),
      createData(
        t("transactions.slippageUSD"),
        row?.slippages[0]?.slippageInUsd
      ),
      createData(
        t("transactions.slippageNOK"),
        row?.slippages[0]?.slippageInNok
      ),
    ];

  if (type === t("transactions.sacrificeInformation"))
    return [
      createData(
        t("transactions.sacrificedTo"),
        row.sacrifices[0]?.sacrificedTo
      ),
      createData(
        t("transactions.amountSacrificed"),
        separateCommaTrans(row.sacrifices[0]?.noCoinSacrificed, null, 5) +
          " " +
          row.coin.name
      ),
    ];
}

export default function CustomItem(props) {
  const {
    type = t("transactions.information"),
    data,
    haveHeader = true,
    coinName,
  } = props;

  const rows = getDataRows(type, data, coinName);

  if (Array.isArray(rows) && rows?.length > 0) {
    return (
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "4px solid rgba(255,255,255, 0)",
                  width: "100%",
                }}
              >
                <TableCell
                  sx={[
                    {
                      pl: 0,
                      fontSize: "14px",
                      fontWeight: "600",
                      flex: 2,
                    },
                  ]}
                >
                  {type}
                </TableCell>

                {haveHeader ? (
                  <TableCell
                    sx={[{ fontSize: "14px", fontWeight: "600", flex: 1 }]}
                    align={
                      type === t("transactions.information") ? "left" : "right"
                    }
                  >
                    {t("transactions.header")}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ fontSize: "14px", fontWeight: "600", flex: 1 }}
                    align={
                      type === t("transactions.information") ? "left" : "right"
                    }
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                if (row !== undefined) {
                  return (
                    <TableRow
                      key={`${data.id} ${row.name} ${index} ${type}`}
                      sx={{
                        borderBottom: "4px solid rgba(255,255,255, 0)",
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                        {row.name}
                      </TableCell>
                      <TableCell
                        align={
                          type === t("transactions.information")
                            ? "left"
                            : "right"
                        }
                      >
                        {row.name === t("transactions.date")
                          ? formatDate(row.value, "DD.MM.YYYY  |  hh:mm:ss")
                          : row.value}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  } else {
    return null;
  }
}
