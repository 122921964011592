import {useQuery} from '@tanstack/react-query';
import {useAuth0} from '@auth0/auth0-react';
import {getUserByAccountId, getUserByAuthId} from '../services/UserService';
import {getCurrentAccountId} from "src/context/AccountContext";

const useGetUserData = () => {
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();

    return useQuery({
        queryKey: ['getUserByAuthId', user?.sub],
        queryFn: async () => {
            const token = await getAccessTokenSilently({redirect_uri: window.location.origin, audience: "https://blokc.dev"});
            return getUserByAuthId(token, user?.sub.trim(), null);
        },
        enabled: !isLoading && isAuthenticated,
        staleTime: 60 * 1000, //1min
    });
}

const useGetUserDataByAccountId = () => {
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
    const accountId = getCurrentAccountId();

    return useQuery({
        queryKey: ['getUserByAccountId'],
        queryFn: async () => {
            const token = await getAccessTokenSilently({redirect_uri: window.location.origin, audience: "https://blokc.dev"});
            return getUserByAccountId(token, user?.sub.trim(), accountId);
        },
        enabled: !isLoading && isAuthenticated,
        staleTime: 60 * 1000, //1min
    });
}

export {useGetUserData, useGetUserDataByAccountId}