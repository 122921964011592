import React from "react";
import {
  Button,
  CircularProgress,
} from "@mui/material";
import classNames from "classnames";

const BaseButton = ({
  title,
  width,
  height,
  margin,
  color = "primary",
  variant = "contained",
  startIcon,
  disabledIcon,
  endIcon,
  isLoading,
  className = "",
  borderColor = "#004F94",
  backgroundColor = "#edf9ff",
  disabled = false,
  ...other
}) => {
  const useStyles = () => ({
    root: {
      fontSize: "14px",
      lineHeight: "16px",
      width: width || "94px",
      height: height || "32px",
      margin: margin || 0,
      padding: "8px",

      "&.MuiButton-outlinedPrimary": {
        border: disabled ? "" : `1px solid ${borderColor}`,
        backgroundColor,
      },

      "&.MuiButton-root.Mui-disabled": {
        border: "1px solid #adafb1",
        background: "#E9ECEE",
        color: "rgba(0, 0, 0, 0.38)",
        pointerEvents: "none",
      },

      "& .MuiButton-startIcon": {
        marginRight: "10px",
        maxHeight: "24px",
        maxWidth: "24px",
      },

      "& .MuiButton-endIcon": {
        marginLeft: "10px",
        maxHeight: "24px",
        maxWidth: "24px",
      },
    },
  });

  return (
    <Button
      className={classNames(className, useStyles().root)}
      {...other}
      color={color}
      variant={variant}
      startIcon={disabled ? disabledIcon : startIcon}
      endIcon={endIcon}
      disabled={isLoading || disabled}
    >
      {isLoading && <CircularProgress size={22} />}
      {!isLoading && `${title}`}
    </Button>
  );
};

export default BaseButton;
