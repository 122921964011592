import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useMutation} from "@tanstack/react-query";
import {Box, Card, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {separateComma} from "src/common/numberFormat";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {useAuth0} from "@auth0/auth0-react";
import {hideCoin} from "src/services/PortfolioService";
import {useAccountContext} from "src/context/AccountContext";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const OverviewItemCoin = (props) => {
    const {
        id,
        sx,
        coinName,
        coinIcon,
        coinValue,
        numberOfCoins,
        staked,
        currentExchangeRate,
        blockchain,
        farmed,
        userCurrency
    } = props;
    const {t} = useTranslation();
    const {currentAccount} = useAccountContext();
    const {user, getAccessTokenSilently} = useAuth0();
    const theme = useTheme();

    const hideIcon = useMutation(["hideIcon"], {
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();
            hideCoin(token, user?.sub, data);
        },
        onSuccess: async () => {
            await props.refetchPage({});
        },
        onError: () => {
            alert("Error");
        },
    });

    const onClickHideCoin = async () => {
        hideIcon.mutate({
            // accountID: currentAccount.accountId,
            coinId: id,
            accountId: currentAccount.accountId,
        });
    };


    return (

        <Card
            sx={{
                ...sx,
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows.sm,
                borderRadius: "0.5rem",
                border: `1px solid ${theme.palette.primary[50]}`,

                maxWidth: {xs: "28rem"},
                mx: "auto",
                position: "relative"
            }}
        >
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Box>
                        <Typography
                            variant={"subtitle1"}
                            noWrap={true}
                        >
                            {coinName}
                        </Typography>
                        <Typography variant={"body2"} color={theme.palette.background[800]} fontWeight={"500"}>
                            {blockchain}
                        </Typography>
                    </Box>


                    <Stack direction="row">
                        <img
                            src={coinIcon}
                            alt={coinName}
                            style={{width: "48px", height: "48px"}}
                        />
                        <Tooltip
                            title={t("overview.hide")}
                            sx={{
                                position: "absolute",
                                top: "-6px",
                                right: "-6px",
                                color: theme.palette.background[650]
                            }}
                        >
                            <IconButton color={"inherit"} size={"medium"} onClick={onClickHideCoin}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    spacing={3}
                    pr={2}
                >
                    <Stack sx={{width: "100%"}}>
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            color={theme.palette.background[750]}
                            sx={{textDecoration: "underline"}}
                        >
                            {t("overview.balance")}
                        </Typography>
                        <Typography
                            color={theme.palette.text.primary}
                            noWrap={true}
                            variant={"h3"}
                            textAlign={"right"}
                            fontWeight={"500"}
                            overflow={"auto"}
                        >
                            {`${separateComma(numberOfCoins?.toFixed(4))} `}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    justifyContent={"space-between"}
                    pr={2}
                >
                    <Stack
                        alignItems="center"
                        sx={{paddingRight: "10px", borderRight: "1px solid #C4C4C4"}}
                    >
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            color={theme.palette.background[750]}
                        >
                            {t("overview.staked")}
                        </Typography>
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            noWrap={true}
                            color={theme.palette.background[800]}
                        >
                            {separateComma(staked?.toFixed(2))}
                        </Typography>
                    </Stack>
                    <Stack
                        alignItems="center"
                        sx={{paddingRight: "10px", borderRight: "1px solid #C4C4C4"}}
                    >
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            color={theme.palette.background[750]}
                        >
                            {t("overview.farmed")}
                        </Typography>
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            noWrap={true}
                            color={theme.palette.background[800]}
                        >
                            {separateComma(farmed?.toFixed(2))}
                        </Typography>
                    </Stack>
                    <Stack
                        alignItems="center"
                        sx={{paddingRight: "10px", borderRight: "1px solid #C4C4C4"}}
                    >
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            color={theme.palette.background[750]}
                        >
                            {t("overview.currentExchangeRate")}
                        </Typography>
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            noWrap={true}
                            color={theme.palette.background[800]}
                        >
                            {separateComma(currentExchangeRate?.toFixed(2))} {userCurrency.name}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center">
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            color={theme.palette.background[750]}
                        >
                            {t("overview.value")}
                        </Typography>
                        <Typography
                            variant={"body3"}
                            fontWeight={"500"}
                            noWrap={true}
                            color={theme.palette.background[800]}
                        >
                            {`${separateComma(coinValue?.toFixed(2))}`} {userCurrency.name}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

OverviewItemCoin.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
};
