import {createTheme} from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

const colors = {
    brand: {
        50: '#dbe2e9ff',   // Pantone 649
        100: '#c5cfdaff',  // Pantone 538
        200: '#a2b2c8ff',  // Pantone 536
        300: '#f8e59aff',  // Pantone 2001
        400: '#ffc845ff',  // Pantone 1225
        500: '#ffad00ff',  // Pantone 2010
        600: '#72829fff',  // Pantone 2373
        700: '#4e5b73ff',  // Pantone 2376
        800: '#e31c79ff',  // Pantone 213
        900: '#002554ff',  // Pantone 655 (main)
    },
    grayscale: {
        50: '#fcfcfc',
        100: '#f2f2f7',
        150: '#f6f7fd',
        250: '#f7f9ff',
        200: '#f1f1ff',
        300: '#f9f9f9',
        350: '#f4f4f4',
        400: '#dcdcdc',
        450: '#d9d9d6',
        500: '#d0d0ce',
        550: '#c8c9c7',
        600: '#bbbcbc',
        650: '#b1b3b3',
        700: '#a7abaa',
        750: '#97999b',
        800: '#888b8d',
        850: '#75787b',
        900: '#63666a',
        950: '#313131',
    },
    supplementary: {
        limeGreen50: '#befb38',
        limeGreen300: '#90d100',
        teal700: '#10a365',
        blue600: '#2196f3',
        yellowAccent100: '#f9dc66',
        deepBlue900: '#001846',
        blueHighlight: '#267aff',
    }
};

// Define typography tokens
const typography = {
    header01: {
        fontSize: '64px',
        fontWeight: 600,
        lineHeight: '76.8px',
    },
    header02: {
        fontSize: '48px',
        fontWeight: 600,
        lineHeight: '57.6px',
    },
    header03: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '38.4px',
    },
    header04: {
        fontSize: '28px',
        fontWeight: 400,
        lineHeight: '33.6px',
    },
    header05: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '28.8px',
    },
    header06: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '28.8px',
    },
    header07: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    subheader01: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '21.6px',
    },
    body01: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19.2px',
    },
    body02: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16.8px',
    },
    body03: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
    },
    body04: {
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '12px',
    },
};

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.brand[900],
            light: colors.brand[200],
            dark: colors.brand[700],
            50: colors.brand[50], // Pantone 649
            100: colors.brand[100], // Pantone 538
            200: colors.brand[200], // Pantone 536
            300: colors.brand[300], // Pantone 2001
            400: colors.brand[400], // Pantone 1225
            500: colors.brand[500], // Pantone 2010
            600: colors.brand[600], // Pantone 2373
            700: colors.brand[700], // Pantone 2376
            800: colors.brand[800], // Pantone 213
            900: colors.brand[900], // Pantone 655 (main)
            contrastText: colors.grayscale[50],
        },
        secondary: {
            main: colors.brand[200],
            light: colors.brand[100],
            dark: colors.brand[300],
            contrastText: colors.grayscale[50],
        },
        error: {
            main: '#d32f2f',
        },
        background: {
            default: colors.grayscale[250],
            paper: colors.grayscale[50],
            50: colors.grayscale[50],
            100: colors.grayscale[100],
            150: colors.grayscale[150],
            200: colors.grayscale[200],
            250: colors.grayscale[250],
            300: colors.grayscale[300],
            350: colors.grayscale[350],
            400: colors.grayscale[400],
            450: colors.grayscale[450],
            500: colors.grayscale[500],
            550: colors.grayscale[550],
            600: colors.grayscale[600],
            650: colors.grayscale[650],
            700: colors.grayscale[700],
            750: colors.grayscale[750],
            800: colors.grayscale[800],
            850: colors.grayscale[850],
            900: colors.grayscale[900],
            950: colors.grayscale[950],
        },
        text: {
            primary: colors.grayscale[950],
            secondary: colors.grayscale[50],
        },
    },
    shadows: {
        ...shadows,
        sm: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'
    },
    breakpoints: {
        values: {
            xs: 0,
            xxsm: 400,
            xsm: 500,
            sm: 650,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1600,
            xxxl: 1920,
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            fontSize: typography.header01.fontSize,
            fontWeight: typography.header01.fontWeight,
            lineHeight: typography.header01.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '56px',
                lineHeight: '67.2px',
            },
            '@media (max-width: 600px)': {
                fontSize: '48px',
                lineHeight: '57.6px',
            },
        },
        h2: {
            fontSize: typography.header02.fontSize,
            fontWeight: typography.header02.fontWeight,
            lineHeight: typography.header02.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '40px',
                lineHeight: '48px',
            },
            '@media (max-width: 600px)': {
                fontSize: '36px',
                lineHeight: '43.2px',
            },
        },
        h3: {
            fontSize: typography.header03.fontSize,
            fontWeight: typography.header03.fontWeight,
            lineHeight: typography.header03.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '30px',
                lineHeight: '36px',
            },
            '@media (max-width: 600px)': {
                fontSize: '28px',
                lineHeight: '33.6px',
            },
        },
        h4: {
            fontSize: typography.header04.fontSize,
            fontWeight: typography.header04.fontWeight,
            lineHeight: typography.header04.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '26px',
                lineHeight: '31.2px',
            },
            '@media (max-width: 600px)': {
                fontSize: '24px',
                lineHeight: '28.8px',
            },
        },
        h5: {
            fontSize: typography.header05.fontSize,
            fontWeight: typography.header05.fontWeight,
            lineHeight: typography.header05.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '22px',
                lineHeight: '26.4px',
            },
            '@media (max-width: 600px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
        },
        h6: {
            fontSize: typography.header06.fontSize,
            fontWeight: typography.header06.fontWeight,
            lineHeight: typography.header06.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
            '@media (max-width: 600px)': {
                fontSize: '18px',
                lineHeight: '21.6px',
            },
        },
        subtitle1: {
            fontSize: typography.header07.fontSize,
            fontWeight: typography.header07.fontWeight,
            lineHeight: typography.header07.lineHeight,
            '@media (max-width: 960px)': {
                fontSize: '18px',
                lineHeight: '21.6px',
            },
            '@media (max-width: 600px)': {
                fontSize: '16px',
                lineHeight: '19.2px',
            },
        },
        subtitle2: {
            fontSize: typography.subheader01.fontSize,
            fontWeight: typography.subheader01.fontWeight,
            lineHeight: typography.subheader01.lineHeight,
            '@media (max-width: 600px)': {
                fontSize: '16px',
                lineHeight: '19.2px',
            },
        },
        body1: {
            fontSize: typography.body01.fontSize,
            fontWeight: typography.body01.fontWeight,
            lineHeight: typography.body01.lineHeight,
        },
        body2: {
            fontSize: typography.body02.fontSize,
            fontWeight: typography.body02.fontWeight,
            lineHeight: typography.body02.lineHeight,
        },
        body3: {
            fontSize: typography.body03.fontSize,
            fontWeight: typography.body03.fontWeight,
            lineHeight: typography.body03.lineHeight,
        },
        caption: {
            fontSize: typography.body03.fontSize,
            fontWeight: typography.body03.fontWeight,
            lineHeight: typography.body03.lineHeight,
        },
        overline: {
            fontSize: typography.body04.fontSize,
            fontWeight: typography.body04.fontWeight,
            lineHeight: typography.body04.lineHeight,
        },
    },
});