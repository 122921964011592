import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAccountContext } from "../../../context/AccountContext";
import { getExchangeTransactionsForUser } from "../../../services/Transactions";
import { exchangeTransactionsColumns } from "./ExchangeTransactionColumns";
import { useGridApiRef, GridToolbar } from "@mui/x-data-grid-premium";
import StyledDataGrid from "../../../components/StyledComponents/StyledDataGrid";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import Information from "./Information";

export default function ExchangeTransactionsTable(filter) {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const { currentAccount } = useAccountContext();

  const {
    data: exchangeTransactionsData,
    refetch: refetchPage,
    isLoading: isLoadingExchangeTransactions,
  } = useQuery({
    queryKey: ["getExchangeTransactionsForUser", currentAccount.accountId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const data = getExchangeTransactionsForUser(
        token,
        user?.sub,
        currentAccount.accountId
      );
      return data;
    },
    enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
  });

  const apiRef = useGridApiRef();

  function getDetailPanelContent({ row }) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
          px: 0,
        }}
      >
        <Container maxWidth="xl" sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid
              xs={12}
              sm={12}
              lg={12}
              sx={{
                pl: 0,
              }}
            >
              <Information data={row} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Stack
      spacing={2}
      sx={{
        p: 4,
        border: "1px solid #DCDCDC",
        borderRadius: "24px",
        bgcolor: "white",
      }}
    >
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Typography color="#000" sx={{ fontSize: 20, fontWeight: "600" }}>
          {t("exchangeTransactions.yourTransactions")}
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <Box sx={{ display: "flex", height: "auto", width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              "& .super-app-theme--header": {
                backgroundColor: "#F4F4F4",
              },
              "& .super-app-theme--header-status": {
                backgroundColor: "#F4F4F4",
                pl: 3,
              },
            }}
          >
            {!isLoadingExchangeTransactions ? (
              <>
                <StyledDataGrid
                  apiRef={apiRef}
                  sx={{ border: "0px solid" }}
                  rows={exchangeTransactionsData}
                  loading={isLoadingExchangeTransactions}
                  columns={exchangeTransactionsColumns(
                    t,
                    apiRef,
                    (params) => {
                      apiRef?.current?.toggleDetailPanel(params.id);
                    },
                    refetchPage
                  )}
                  pageSize={pageSize}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                  autoHeight={true}
                  pagination={true}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={() => "auto"}
                  initialState={{
                    sorting: {
                      sortModel: [
                        { field: "transactionTimestamp", sort: "desc" },
                      ],
                    },
                  }}
                  filterModel={{
                    items: filter.filter,
                  }}
                />
              </>
            ) : (
              <>
                <StyledDataGrid
                  apiRef={apiRef}
                  sx={{ border: "0px solid" }}
                  rows={[]}
                  loading={isLoadingExchangeTransactions}
                  autoHeight={true}
                  pagination={true}
                  slots={{ toolbar: GridToolbar }}
                  columns={exchangeTransactionsColumns(
                    t,
                    apiRef,
                    (params) => {
                      apiRef?.current?.toggleDetailPanel(params.id);
                    },
                    refetchPage
                  )}
                />
              </>
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}
