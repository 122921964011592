import React, {useState} from 'react';
import {Button, Checkbox, Dialog, Divider, FormGroup, Stack} from "@mui/material";
import TermsAndConditionsMD from "src/pages/TermsAndConditions/TermsAndConditionsMD";
import {useMutation} from '@tanstack/react-query';
import StyledDialogTitle from "src/components/StyledComponents/StyledDialogTitle";
import StyledDialogContent from "src/components/StyledComponents/StyledDialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {acceptTerms} from "src/services/AccountService";
import {useAuth0} from "@auth0/auth0-react";
import {useAccountContext} from "src/context/AccountContext";
import {useSnackbarContext} from "src/context/SnackbarContext";

function MissingTermsPopup({open, setOpen, termsAccepted}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const {user, getAccessTokenSilently} = useAuth0();
    const {currentAccount} = useAccountContext();
    const {setDataSnackbar} = useSnackbarContext();

    // UseMutation hook from React Query
    const mutation = useMutation(["acceptTerms"], {
        mutationFn: async () => {
            const token = await getAccessTokenSilently();
            await acceptTerms(token, user?.sub, currentAccount.accountId);
        },
        onSuccess: () => {
            setDataSnackbar({
                isOpen: true,
                severity: "success",
                message: t("common.success"),
                action: null,
            });
        },
        onError: (e) => {
            setDataSnackbar({
                isOpen: true,
                severity: "error",
                message: t("common.acceptTermsError"),
                action: null,
            });
        },
    });

    const handleSaveClick = () => {
        if (isChecked) {
            mutation.mutate();
            setOpen(false)
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    return (
        <Dialog open={open}>
            <StyledDialogTitle component="h2">{t("common.termsAndConditions")}</StyledDialogTitle>
            <StyledDialogContent sx={{overflowY: "scroll"}}>
                <TermsAndConditionsMD/>
            </StyledDialogContent>
            <Divider></Divider>
            {!termsAccepted ? (
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{
                        backgroundColor: theme.palette.primary[900],
                        px: 2,
                        py: 1,
                        color: theme.palette.text.secondary
                    }}>
                    <FormGroup>
                        <FormControlLabel
                            sx={{fontSize: "0.85rem"}}
                            required
                            control={
                                <Checkbox
                                    sx={{
                                        color: "white",
                                        '&.Mui-checked': {
                                            color: "white",
                                        }
                                    }}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={t("common.acceptTerms")}
                        />
                    </FormGroup>
                    <Button
                        type={"submit"}
                        disabled={!isChecked}
                        sx={{
                            color: theme.palette.text.secondary,
                            textDecoration: "underline",
                            '&:hover': {
                                color: theme.palette.background[500],
                                textDecoration: "underline",
                            },
                            '&:active': {
                                color: theme.palette.text.secondary,
                                textDecoration: "underline",
                            },
                            '&:focus': {
                                color: theme.palette.text.secondary,
                                textDecoration: "underline",
                            },
                            '&:disabled': {
                                color: theme.palette.background[600],
                                textDecoration: "underline",
                            },
                        }}
                        onClick={handleSaveClick}
                    >{t("common.save")}</Button>
                </Stack>
            ) : (
                <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    sx={{
                        backgroundColor: theme.palette.primary[900],
                        px: 2,
                        py: 1,
                        color: theme.palette.text.secondary
                    }}>
                    <Button
                        onClick={() => setOpen(false)}
                        type={"button"}
                        sx={{
                            color: theme.palette.text.secondary,
                            textDecoration: "underline",
                            '&:hover': {
                                color: theme.palette.background[500],
                                textDecoration: "underline",
                            },
                            '&:active': {
                                color: theme.palette.text.secondary,
                                textDecoration: "underline",
                            },
                            '&:focus': {
                                color: theme.palette.text.secondary,
                                textDecoration: "underline",
                            },
                            '&:disabled': {
                                color: theme.palette.background[600],
                                textDecoration: "underline",
                            },
                        }}
                    >{t("common.back")}</Button>
                </Stack>
            )}
        </Dialog>
    );
}

export default MissingTermsPopup;