import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Stack, Unstable_Grid2 as Grid, Box } from "@mui/material";
import { StyledCircularProgress } from "../../../components/StyledComponents/StyledCircularProgress";
import { getAddressesForUser } from "../../../services/AddressService";
import { useAccountContext } from "../../../context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { CryptoExchangeData } from "./CryptoExchangeData";
import ExchangeBox from "./components/ExchangeBox";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import SvgAsset from "../../../components/SvgAsset/SvgAsset";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid var(--dcdcdc, #DCDCDC)",
  borderBottomRightRadius: "16px",
  borderBottomLeftRadius: "16px",
}));

export default function ExchangeSettings() {
  const { dataSnackbar } = useSnackbarContext();

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { currentAccount } = useAccountContext();

  //start Test
  const { isLoading: isLoadingExchanges, refetch: refetchExchange } = useQuery({
    queryKey: ["getExchangesDataForUser", currentAccount.accountId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const data = await getAddressesForUser(
        token,
        user?.sub,
        currentAccount.accountId
      );
      return data;
    },
    enabled: isAuthenticated && !!currentAccount.accountId,
  });
  //end Test

  useEffect(() => {
    if (dataSnackbar.action === "AddExchange") {
      refetchExchange();
    }
  }, [dataSnackbar, refetchExchange]);

  return (
    <Box sx={{ width: "100%" }}>
      {!isLoadingExchanges ? (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 2 }}
        >
          {CryptoExchangeData.map((item, index) => (
            <Grid xs={12} md={6} lg={4} key={index}>
              <Item>
                <Stack
                  sx={{
                    bgcolor: item.cryptoExchangeColor,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "75px",
                    mb: 4,
                  }}
                >
                  <a target="_blank" href={item.cryptoExchangeLink}>
                    <SvgAsset
                      name={item.cryptoExchangeLogo}
                      style={item.styleIcon}
                    />
                  </a>
                </Stack>

                <ExchangeBox
                  exchange={item.exchange}
                  typeUpload={item.typeUpload}
                  locationConnectUrl={item.locationConnectUrl}
                />
              </Item>
            </Grid>
          ))}
        </Grid>
      ) : (
        <StyledCircularProgress />
      )}
    </Box>
  );
}
