import React, {useMemo, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {useGetUserData} from "src/hooks/UserHook";
import {useAccountContext} from "src/context/AccountContext";
import {useSnackbarContext} from "src/context/SnackbarContext";
import moment from "moment";
import {Snackbar, useTheme} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Header from "./components/Authenticated/Header";
import RegisterUser from "../../pages/RegisterUser/RegisterUser";
import SubscriptionPage from "../../pages/RegisterUser/SubscriptionPage";
import {styled} from "@mui/material/styles";
import {embedChat, updateUser} from "src/common/helpcrunch";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import CustomTutorial from "./components/CustomTutorial";
import RootHeaderNotAuthent from "./components/NotAuthenticated/RootHeaderNotAuthent";
import RootFooterNotAuthent from "src/components/RootLayout/components/NotAuthenticated/RootFooterNotAuthent";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LayoutRoot = styled("div")(({theme}) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    justifyContent: "center",
}));

const LayoutContainer = styled("div")({
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
});

export default function RootLayout() {
    const userData = useGetUserData();
    const visibleTutorialSession = sessionStorage.getItem(
        "visibleTutorialSession"
    );
    const {isAuthenticated} = useAuth0();
    const {currentAccount} = useAccountContext();
    const {dataSnackbar, setDataSnackbar} = useSnackbarContext();
    const {t} = useTranslation();
    const theme = useTheme()
    const [visibleTutorial, setVisibleTutorial] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;

    useMemo(() => {
        if (Number(visibleTutorialSession) === 1) {
            setVisibleTutorial(true);
        } else {
            setVisibleTutorial(false);
        }
    }, [visibleTutorialSession]);

    const handleClose = () => {
        setDataSnackbar({
            isOpen: false,
            severity: "success",
            message: "",
            action: null,
        });
    };

    function RenderContent() {
        if (userData.isLoading && userData.data?.registered) {
            return (
                <RegisterUser
                    userData={userData.data}
                    refetchUserData={userData.refetch}
                />
            );
        } else if (userData.data?.displayWizard && visibleTutorial) {
            return (
                <CustomTutorial
                    userId={userData.data?.id}
                    onCloseTutorial={() => {
                        setVisibleTutorial(false);
                        sessionStorage.setItem("visibleTutorialSession", 0);
                    }}
                />
            );
        } else if (
            currentAccount?.account &&
            (!currentAccount.account?.account?.subscriptionExpireDate ||
                moment().isAfter(
                    currentAccount.account?.account?.subscriptionExpireDate
                ))
        ) {
            return <SubscriptionPage accountId={currentAccount.accountId}/>;
        }

        return (
            <>
                <Helmet>
                    <title>{t("transactions.pageHelmet")}</title>
                    <meta name="Blokc" content="Blokc"/>
                </Helmet>
                <Outlet/>
            </>
        );
    }

    if (isAuthenticated) {
        if (userData.data && currentAccount?.account) {
            let name = currentAccount?.account?.account?.name;
            if (!name) {
                name = "Noname";
            }

            updateUser({
                user_id: userData.data.auth0Userid,
                name: name,
                email: userData.data?.email,
                phone: userData.data?.phone,
                company: userData.data?.place,
            });
        }
        return (
            <LayoutRoot>
                <LayoutContainer>
                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                        open={dataSnackbar.isOpen}
                        onClose={handleClose}
                        autoHideDuration={3000}
                    >
                        <Alert
                            onClose={handleClose}
                            severity={dataSnackbar?.severity || "success"}
                            sx={{width: "100%"}}
                        >
                            {dataSnackbar?.message || ""}
                        </Alert>
                    </Snackbar>

                    {<Header accountData={userData.data}/>}
                    {RenderContent()}
                </LayoutContainer>
            </LayoutRoot>
        );
    } else {
        embedChat();
        return (
            <LayoutRoot>
                <LayoutContainer>
                    <RootHeaderNotAuthent/>
                    {RenderContent()}
                    <RootFooterNotAuthent/>
                </LayoutContainer>
            </LayoutRoot>
        );
    }
}
