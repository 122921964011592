import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Container, Stack, Typography } from "@mui/material";

export default function AboutUsPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("solution.pageHelmet")}</title>
        <meta name="Blokc" content="Blokc" />
      </Helmet>
      <Container
        maxWidth="xxl"
        sx={
          isMobile
            ? { flexGrow: 1, backgroundColor: "white", paddingX: "0px" }
            : {
                flexGrow: 1,
                backgroundColor: "white",
              }
        }
      >
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Stack sx={{ width: { xs: "90%", md: "30%" } }} py="140px">
            <Typography
              sx={{
                textAlign: "center",
                color: "#000",
                fontSize: { xs: "36px", sm: "48px" },
                fontWeight: "600",
                lineHeight: { xs: "36px", sm: "48px", md: "77px" },
              }}
            >
              {t("solution.titlePage")}
            </Typography>
            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                mb: "30px",
              }}
            >
              {t("solution.desceptionPage")}
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              py: "100px",
              px: { md: "160px" },
              backgroundColor: "#F7F9FF",
            }}
          >
            {!!t("solution.contentPage1") && (
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {t("solution.contentPage1")}
              </Typography>
            )}

            {!!t("solution.contentPage2") && (
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {t("solution.contentPage2")}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
