import {DialogTitle, styled, useTheme} from "@mui/material";

export default function StyledDialogTitle(props) {
  const theme = useTheme()
  const StyledDialogTitle = styled(DialogTitle)(() => ({
    backgroundColor: theme.palette.primary[900],
    color: theme.palette.text.secondary,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }));

  return <StyledDialogTitle {...props} />;
}
