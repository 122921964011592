import { axios, APIBASE_B4BAPPLICATION } from "./ApiConfig";

export const getTransactionsForUser = async (token, auth0Id, accountId) =>
  axios(token, auth0Id)
    .get(
      `${APIBASE_B4BAPPLICATION}/api/transactions/transactionsForAccount/${accountId}`
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });

export const getExchangeTransactionsForUser = async (
  token,
  auth0Id,
  accountId
) =>
  axios(token, auth0Id)
    .get(
      `${APIBASE_B4BAPPLICATION}/api/exchange/transactionsForAccount/${accountId}`
    )
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });

export const updateTransaction = async (token, auth0Id, transactionId, data) =>
  axios(token, auth0Id).put(
    `${APIBASE_B4BAPPLICATION}/api/transactions/${transactionId}`,
    data
  );

export const updateTransactionType = async (token, auth0Id, txid, data) =>
  axios(token, auth0Id).put(
    `${APIBASE_B4BAPPLICATION}/api/transactions/${txid}/transactionType`,
    data
  );

export const markAsScam = async (token, auth0Id, txid) =>
  axios(token, auth0Id).put(
    `${APIBASE_B4BAPPLICATION}/api/transactions/${txid}/markAsScam`
  );
export const updateCostPrice = async (token, auth0Id, txid, data) =>
  axios(token, auth0Id).put(
    `${APIBASE_B4BAPPLICATION}/api/transactions/${txid}/costPrice`,
    data
  );

export const updateSalesPrice = async (token, auth0Id, txid, data) =>
  axios(token, auth0Id).put(
    `${APIBASE_B4BAPPLICATION}/api/transactions/${txid}/salesPrice`,
    data
  );

export const getCoinOptions = async (token, auth0Id) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/transactions/coins`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });

export const getSpecialAddress = async (token, auth0Id) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/transactions/specialaddress`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });

export const getTransactionTypeOptions = async (token, auth0Id) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/transactions/transactionTypes`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {};
    });
