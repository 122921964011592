import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import moment from "moment/moment";
import TableActionsTransactions from "./TableActionsTransactions";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-premium";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SvgAsset from "../../../components/SvgAsset/SvgAsset";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { separateCommaTrans } from "../../../common/numberFormat";

function getDetailPanelContent(apiRef, id) {
  if (apiRef) {
    const seleteds = apiRef?.current?.getExpandedDetailPanels() || [];
    if (seleteds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
}

export const transactionColumns = (
  t,
  refetchPage,
  handleClick,
  apiRef
) => {
  return [
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      maxWidth: 5,
      hide: true,
    },
    {
      field: "coin",
      headerName: t("transactions.coin"),
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderHeader: () => <strong>{t("transactions.coin")}</strong>,
      renderCell: (params) => {
        let isItemSelected = getDetailPanelContent(apiRef, params.id);
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ minWidth: { xs: 150, md: 0 } }}
          >
            <IconButton
              onClick={() => handleClick(params)}
              aria-label="expand row"
              size="small"
            >
              {isItemSelected ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            <SvgAsset
              name={params.row.coin?.icon}
              style={{ width: "32px", height: "32px", marginRight: "10px" }}
            />

            {params.row.coin?.name}
          </Stack>
        );
      },
      valueGetter: (params) => {
        return params.row.coin?.name;
      },
    },
    {
      field: "transactionDate",
      type: "dateTime",
      headerName: t("transactions.date"),
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 100,
      disableExport: true,
      renderHeader: () => <strong>{t("transactions.date")}</strong>,
      valueFormatter: ({ value }) => {
        return moment(value).format("DD.MM.YYYY");
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: t("transactions.amount"),
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 150,
      renderHeader: () => <strong>{t("transactions.amount")}</strong>,
      valueGetter: (params) => {
        if (!params.row.amount) {
          return "-";
        } else {
          return separateCommaTrans(params.row.amount, 0, 5);
        }
      },
    },
    {
      field: "transactionType",
      headerName: t("transactions.transactionType"),
      headerClassName: "super-app-theme--header",
      align: "left",
      flex: 1,
      minWidth: 150,
      renderHeader: () => <strong>{t("transactions.transactionType")}</strong>,
      valueFormatter: ({ value }) => {
        return t("transactions.transactionTypes." + value);
      },
    },
    {
      field: "transactionFailed",
      headerName: t("transactions.status"),
      headerClassName: "super-app-theme--header-status",
      align: "left",
      flex: 1,
      minWidth: 100,
      renderHeader: () => <strong>{t("transactions.status")}</strong>,
      renderCell: (params) => {
        if (!params.row.transactionFailed) {
          return (
            <Tooltip
              sx={{ bgcolor: "red" }}
              title={t("transactions.transactionCompleted")}
            >
              <Stack
                sx={{
                  borderRadius: "16px",
                  bgcolor: "rgba(16, 163, 101, 0.1)",
                  py: "4px",
                  px: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgba(16, 163, 101, 1)",
                  }}
                >
                  {t("transactions.active")}
                </Typography>
              </Stack>
            </Tooltip>
          );
        } else if (params.row.transactionFailed) {
          return (
            <Tooltip title={t("transactions.transactionFailed")}>
              <Stack sx={{ bgcolor: "rgba(211, 47, 47, 0.1)" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  {t("transactions.inactive")}
                </Typography>
              </Stack>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "txHash",
      type: "string",
      headerName: t("transactions.txHash"),
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "left",
      minWidth: 550,
      renderHeader: () => <strong>{t("transactions.txHash")}</strong>,
      valueGetter: (params) => {
        return params.row.txHash;
      },
    },
    {
      field: "amountSwappedFrom",
      headerName: t("transactions.amountSwappedFrom"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (!params.row.coinSwappedFrom) {
          return "-";
        } else {
          return params.row.amountSwappedFrom;
        }
      },
    },
    {
      field: "swappedFrom",
      headerName: t("transactions.coinSwappedFrom"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (!params.row.coinSwappedFrom) {
          return "-";
        } else {
          return params.row.coinSwappedFrom?.name;
        }
      },
    },
    {
      field: "amountSwappedTo",
      headerName: t("transactions.amountSwappedTo"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (!params.row.coinSwappedFrom) {
          return "-";
        } else {
          return params.row.amount;
        }
      },
    },
    {
      field: "swappedTo",
      headerName: t("transactions.coinSwappedTo"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (!params.row.coinSwappedFrom) {
          return "-";
        } else {
          return params.row.coin?.name;
        }
      },
    },

    //Sacrifice
    {
      field: "sacrificedTo",
      headerName: t("transactions.sacrificedTo"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.sacrifices?.length === 0) {
          return "-";
        } else {
          return params.row.sacrifices[0].sacrificedTo;
        }
      },
    },
    {
      field: "noOfCoinsSacrificed",
      headerName: t("transactions.amountSacrificed"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.sacrifices?.length === 0) {
          return "-";
        } else {
          return (
            params.row.sacrifices[0].noCoinSacrificed +
            " " +
            params.row.coin?.name
          );
        }
      },
    },

    //Stake
    {
      field: "stakeType",
      headerName: t("transactions.stakeType"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return params.row.stakes[0].type;
        }
      },
    },
    {
      field: "stakeId",
      headerName: t("transactions.stakeId"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return params.row.stakes[0].stakeId;
        }
      },
    },
    {
      field: "stakeEnded",
      headerName: t("transactions.stakeEnded"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return params.row.stakes[0].ended ? t("common.yes") : t("common.no");
        }
      },
    },
    {
      field: "amountStaked",
      headerName: t("transactions.amountStaked"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return (
            params.row.stakes[0].numberOfCoinStaked +
            " " +
            params.row.coin?.name
          );
        }
      },
    },
    {
      field: "interest",
      headerName: t("transactions.interest"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return params.row.stakes[0].interest + " " + params.row.coin?.name;
        }
      },
    },
    {
      field: "penalty",
      headerName: t("transactions.penalty"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return params.row.stakes[0].penalty + " " + params.row.coin?.name;
        }
      },
    },
    {
      field: "coinsPaidAtEnd",
      headerName: t("transactions.coinsPaidAtEnd"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.stakes?.length === 0) {
          return "-";
        } else {
          return (
            params.row.stakes[0].coinPayedAtEnd + " " + params.row.coin?.name
          );
        }
      },
    },

    // Prices
    {
      field: "amountInCoin",
      headerName: t("transactions.amountInCoin"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.amountInCoin;
      },
    },
    {
      field: "amountInUSD",
      headerName: t("transactions.amountInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.amountInUSD?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      field: "amountInNOK",
      headerName: t("transactions.amountInNOK"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.amountInNOK?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },

    // Fees
    {
      field: "feeInCoin",
      headerName: t("transactions.feeInCoin"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.feeInCoin;
      },
    },
    {
      field: "feeInUSD",
      headerName: t("transactions.feeInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.feeInUSD?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      field: "feeInNOK",
      headerName: t("transactions.feeInNOK"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.feeInNOK?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      field: "feeInBlockchainCoin",
      headerName: t("transactions.feeInBlockchainCoin"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.prices.feeInBlockchainCoin;
      },
    },

    // Exchangerates
    {
      field: "exchangeRatesBitcoin",
      headerName: t("transactions.exchangeRatesBitcoinInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[0]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesEthereum",
      headerName: t("transactions.exchangeRatesEthereumInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[1]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesHex",
      headerName: t("transactions.exchangeRatesHexInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[2]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesDoge",
      headerName: t("transactions.exchangeRatesDogeInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[3]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesXrp",
      headerName: t("transactions.exchangeRatesXrpInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[4]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesAda",
      headerName: t("transactions.exchangeRatesAdaInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[5]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
        }
      },
    },
    {
      field: "exchangeRatesPolkadot",
      headerName: t("transactions.exchangeRatesPolkadotInUSD"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        if (params.row.exchangeRates?.length === 0) {
          return "-";
        } else {
          return params.row.exchangeRates[6]?.price?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      },
    },
    {
      field: "result",
      headerName: t("transactions.result"),
      flex: 1,
      hide: true,
      valueFormatter: ({ value }) => {
        if (value?.length === 0) {
          return "-";
        } else {
          return value.map((result) => {
            return (
              " " +
              t("transactions.id") +
              ": " +
              result.id +
              ", " +
              t("transactions.coin") +
              ": " +
              result.realisedCoin?.name +
              ", " +
              t("transactions.realisationDate") +
              ": " +
              moment(result.realisationDate).format("DD.MM.YYYY, HH:mm:ss") +
              ", " +
              t("transactions.numberOfCoinsRealised") +
              ": " +
              result.numberOfCoinsRealised?.toLocaleString(undefined, {
                maximumFractionDigits: 5,
              }) +
              ", " +
              t("transactions.costPrCoinRealised") +
              ": " +
              result.costPrCoinRealised +
              ", " +
              t("transactions.priceAtRealization") +
              ": " +
              result.priceAtRealization +
              ", " +
              t("transactions.resultPrCoinRealised") +
              ": " +
              result.resultPrCoinRealised +
              ", " +
              t("transactions.realisationFee") +
              ": " +
              result.realisationFee +
              ", " +
              t("transactions.totalResult") +
              ": " +
              result.totalResult +
              " "
            );
          });
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "...",
      flex: 1,
      disableExport: true,
      maxWidth: 50,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <MoreHorizIcon />,
      renderCell: (params) => {
        return (
          <TableActionsTransactions params={params} refetchPage={refetchPage} />
        );
      },
    },
  ];
};
