import React, {useRef} from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    IconButton,
    List,
    ListItemAvatar,
    Skeleton,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useAccountContext} from "src/context/AccountContext";
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import SwiperCore, {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import ChevronUpIcon from '@mui/icons-material/ExpandLess';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import 'swiper/swiper-bundle.css';
import {StyledCardHeader} from "src/pages/Overview/styles";
import {getMessageForAccount} from "src/services/PortfolioService";
import CommentIcon from "@mui/icons-material/Comment";
import {format_HHmmss, format_YYYYMMDD, formatDate} from "src/common/dateFormat";

SwiperCore.use([Pagination]);

const SkeletonLoading = () => {
    return (
        <List>
            {[1, 2, 3, 4, 5].map((mess) => (
                <Stack
                    key={mess}
                    alignItems="flex-start"
                    sx={{
                        borderRadius: 2,
                        flexDirection: "column",
                        mb: 2,
                        width: '100%'
                    }}
                >
                    <ListItemAvatar>
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                        />
                    </ListItemAvatar>

                    <Stack sx={{width: "100%"}}>
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="60%"
                            style={{marginBottom: 6}}
                        />
                        <Skeleton animation="wave" height={10} width="80%"/>
                        <Skeleton animation="wave" height={10} width="80%"/>
                    </Stack>
                </Stack>
            ))}
        </List>
    );
};

export const MessagesBox = () => {
    const {currentAccount} = useAccountContext();
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();
    const theme = useTheme();
    const swiperRef = useRef(null);

    const {data: messages = [], isLoading: isLoadingMessages} = useQuery({
        queryKey: ["getMessageForUser", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            return await getMessageForAccount(token, user?.sub, currentAccount.accountId);
        },
    });

    if (isLoadingMessages) {
        return (
            <Card
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows.md,
                    borderRadius: "1.5rem",
                    border: `1px solid ${theme.palette.primary[50]}`,
                    width: "100%",
                    height: "100%",
                }}
            >
                <StyledCardHeader title={t("overview.messages")}/>
                <CardContent>
                    <SkeletonLoading/>
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Stack
                p={3}
                direction={"column"}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows.md,
                    borderRadius: "1.5rem",
                    border: `1px solid ${theme.palette.primary[50]}`,
                    width: "100%",
                }}
            >
                <Stack direction={"row"}
                       sx={{width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography variant={"subtitle1"}>{t("overview.messages")}</Typography>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <IconButton
                            onClick={() => {
                                if (swiperRef.current) {
                                    swiperRef.current.slidePrev();
                                }
                            }}
                        >
                            <ChevronUpIcon color="inherit"/>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (swiperRef.current) {
                                    swiperRef.current.slideNext();
                                }
                            }}
                        >
                            <ChevronDownIcon color="inherit"/>
                        </IconButton>
                    </Stack>
                </Stack>
                <Box>
                    <Swiper
                        direction="vertical"
                        slidesPerView={3}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        style={{height: "26rem"}}
                    >
                        {messages && messages.map((message) => (
                            <SwiperSlide key={message?.id}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mr: 2
                                        }}
                                    >
                                        <Avatar sx={{backgroundColor: "#FFC845"}}>
                                            <CommentIcon/>
                                        </Avatar>
                                    </Box>
                                    <Box sx={{flex: 1}}>
                                        <Typography
                                            sx={{display: "inline"}}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {formatDate(message?.date, format_YYYYMMDD, true)}
                                        </Typography>
                                        <Typography
                                            sx={{display: "inline", marginLeft: 1}}
                                            variant="body2"
                                            color="#888B8D"
                                            mb={0}

                                        >
                                            {`${formatDate(message?.date, format_HHmmss, true)}`}
                                        </Typography>

                                        <Divider sx={{my: 0}}/>
                                        <Box>
                                            <Typography
                                                sx={{display: "inline", maxWidth: "10ch"}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                                mt={1}
                                                maxWidth={"2rem"}
                                            >
                                                {`${t("overview.message")}: ${message?.message}`}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            </Stack>
        );
    }
};