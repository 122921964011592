import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getTransactionTypeOptions,
  updateTransactionType,
} from "../../../services/Transactions";
import {
  StyledDialogButtonCancel,
  StyledDialogButtonOK,
} from "../../../components/StyledComponents/StyledDialogButtons";
import StyledDialogContent from "../../../components/StyledComponents/StyledDialogContent";
import StyledDialogTitle from "../../../components/StyledComponents/StyledDialogTitle";
import { StyledCircularProgress } from "../../../components/StyledComponents/StyledCircularProgress";

export default function ChangeTransactionType(props) {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isEditTransactionModalOpen, setIsEditTransactionModalOpen] =
    useState(false);

  const { data: transactionTypes, isLoading: isLoadingTransactionTypes } =
    useQuery({
      queryKey: ["getTransactionTypes"],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        const data = await getTransactionTypeOptions(token, user?.sub);
        return data;
      },
      enabled: isEditTransactionModalOpen,
    });

  const saveTransactionUpdate = useMutation(["updateTransactionType"], {
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      updateTransactionType(token, user?.sub, props.itemId.id, data);
    },
    onSuccess: async () => {
      await props.refetchPage();
      reset();
    },
    onError: () => {
      alert(t("common.error"));
    },
  });

  const handleOpenModal = () => {
    setIsEditTransactionModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditTransactionModalOpen(false);
    props.setAnchorEl(null);
    reset();
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      transactionType: props.itemId.transactionType,
    },
  });

  const onSubmit = async (data) => {
    saveTransactionUpdate.mutate(data);
    setIsEditTransactionModalOpen(false);
    props.setAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        sx={{ color: "#000", textTransform: "none" }}
        onClick={handleOpenModal}
      >
        {t("exchangeTransactions.changeTransactionType")}
      </Button>
      <Dialog open={isEditTransactionModalOpen} onClose={handleCloseModal}>
        <StyledDialogTitle>
          {t("exchangeTransactions.editTransaction")}
        </StyledDialogTitle>
        {!isLoadingTransactionTypes ? (
          <StyledDialogContent sx={{ width: 500 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} sx={{ marginTop: 3 }}>
                <Controller
                  render={() => (
                    <FormControl
                      sx={{ width: 1 }}
                      error={!!errors.transactionType}
                    >
                      <InputLabel id="select-transactionType">
                        {t("exchangeTransactions.transactionType")}
                      </InputLabel>
                      <Select
                        defaultValue={props.itemId.transactionType}
                        labelId="select-transactionType"
                        input={
                          <OutlinedInput
                            label={t("exchangeTransactions.transactionType")}
                          />
                        }
                        {...register("transactionType", {
                          required: t("exchangeTransactions.transactionTypeError"),
                        })}
                      >
                        {transactionTypes?.map((transactionType) => (
                          <MenuItem
                            key={transactionType}
                            value={transactionType}
                          >
                            {t(
                              "transactions.transactionTypes." + transactionType
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.transactionType}>
                        {errors.transactionType?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                  name="transactionType"
                  control={control}
                />
              </Stack>
            </form>
          </StyledDialogContent>
        ) : (
          <StyledDialogContent sx={{ width: 500 }}>
            <StyledCircularProgress />
          </StyledDialogContent>
        )}
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledDialogButtonCancel onClick={handleCloseModal}>
            {t("common.close")}
          </StyledDialogButtonCancel>
          <StyledDialogButtonOK onClick={handleSubmit(onSubmit)}>
            {t("common.save")}
          </StyledDialogButtonOK>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
