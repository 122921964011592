import { Button } from "@mui/material";
import { useState } from "react";
import { StyledMenuItem } from "../../../components/StyledComponents/StyledMenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteAddressModal from "./DeleteAddressModal";
import StyledMenu from "../../../components/StyledComponents/StyledMenu";

export default function TableActionsAddresses(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClickOpenMenu}>
        <MoreHorizIcon sx={{ color: "#000000" }} />
      </Button>
      <StyledMenu
        id="edit-delete-address-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{ textShadow: "none" }}
      >
        <StyledMenuItem>
          <DeleteAddressModal
            itemId={props.itemId}
            refetchAddresses={props.refetchAddresses}
            setAnchorEl={setAnchorEl}
          />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
