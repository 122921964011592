import { default as common } from './common.json';
import { default as frontPage } from './frontPage.json';
import { default as overview } from './overview.json';
import { default as registerUser } from './registerUser.json';
import { default as settings } from './settings.json';
import { default as transactions } from './transactions.json';
import { default as components } from './components.json';
import { default as exchangeTransactions } from './exchangeTransactions.json'
import { default as reports } from './reports.json'
import { default as help } from './help.json'
import { default as errorPage } from './frontPage.json'
import { default as prices } from "./prices.json";
import { default as aboutUs } from "./aboutUs.json";
import { default as solution } from "./solution.json";
const nb = {
  common,
  frontPage,
  overview,
  registerUser,
  settings,
  transactions,
  components,
  exchangeTransactions,
  reports,
  help,
  errorPage,
  prices,
  aboutUs,
  solution,
};

export default nb;