import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { deleteAddressForUser } from '../../../services/AddressService';
import { useAccountContext } from '../../../context/AccountContext';
import { StyledDialogButton } from '../../../components/StyledComponents/StyledDialogButtons';
import DeleteIcon from '@mui/icons-material/Delete';
import StyledDialogContent from '../../../components/StyledComponents/StyledDialogContent';
import StyledDialogTitle from '../../../components/StyledComponents/StyledDialogTitle';
import PropTypes from 'prop-types';

export default function DeleteAddressModal(props) {
  const { t } = useTranslation();
  const [isDeleteAddressModalOpen, setIsDeleteAddressModalOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const { user, getAccessTokenSilently } = useAuth0();
  const { currentAccount } = useAccountContext();

  const deleteAddress = useMutation(['deleteAddress'],
    {
      mutationFn: async () => {
        const token = await getAccessTokenSilently();
        await deleteAddressForUser(token, user?.sub, currentAccount.accountId, itemId)
      },
      onSuccess: async () => {
        await props.refetchAddresses();
      }
    }
  )

  const handleOpenModal = () => {
    setItemId(props.itemId.id)
    setIsDeleteAddressModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsDeleteAddressModalOpen(false);
    props.setAnchorEl(null);
  }

  const handleDeleteAddress = () => {
    deleteAddress.mutate();
    setIsDeleteAddressModalOpen(false);
    props.setAnchorEl(null);
  }

  return (
    <Stack>
      <Button sx={{ color: 'var(--b4bDarblue)' }} onClick={handleOpenModal}><DeleteIcon /></Button>
      <Dialog open={isDeleteAddressModalOpen} onClose={handleCloseModal}>
        <StyledDialogTitle>{t('settings.addressSettingsPage.deleteAddress')}</StyledDialogTitle>
        <StyledDialogContent>
          <Stack spacing={3} sx={{ marginTop: 3 }}>
            {t('settings.addressSettingsPage.deleteAddressWarning')}
          </Stack>
        </StyledDialogContent>
        <DialogActions>
          <StyledDialogButton onClick={handleCloseModal}>{t('settings.cancel')}</StyledDialogButton>
          <StyledDialogButton onClick={handleDeleteAddress}>{t('settings.delete')}</StyledDialogButton>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

DeleteAddressModal.propTypes = {
  itemId: PropTypes.object,
  refetchAddresses: PropTypes.func,
}