import {APIBASE_B4BAPPLICATION, axios} from "src/services/ApiConfig";

export const getCurrencies = async (token, auth0Id) =>
    axios(token, auth0Id)
        .get(`${APIBASE_B4BAPPLICATION}/api/transactions/currencies`)
        .then((response) => {
            return response;
        })
        .catch((e) => {
            return e.response.data;
        });

export const getAccount = async (token, auth0Id, accountId) => {
    axios(token, auth0Id).get(`${APIBASE_B4BAPPLICATION}/api/account/${accountId}`)
        .then((response) => {
            console.log(response);
            return response
        })
        .catch((e) => {
            return e.response.data;
        })
}

export const acceptTerms = async (token, auth0Id, accountId) => {
    return axios(token, auth0Id).put(`${APIBASE_B4BAPPLICATION}/api/account/${accountId}/acceptTerms`, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
            return e.response.data;
        });
}

export const changeCurrency = async (token, auth0Id, accountId, currencyName) => {
    return axios(token, auth0Id).put(`${APIBASE_B4BAPPLICATION}/api/account/${accountId}/updateDefaultCurrency/${currencyName}`, null)
        .then((response) => {
            return response;
        })
        .catch((e) => {
            return e.response.data;
        });
}