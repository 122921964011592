import { SvgIcon } from "@mui/material";

import SsidChartIcon from "@mui/icons-material/SsidChart";
import HomeIcon from "@mui/icons-material/Home";
import MovingIcon from "@mui/icons-material/Moving";
import ReorderSharpIcon from "@mui/icons-material/ReorderSharp";
import SettingsIcon from "@mui/icons-material/Settings";

export const itemsAppBar = [
  {
    title: "components.overview",
    path: "/overview",
    icon: (
      <SvgIcon fontSize="small">
        <HomeIcon />
      </SvgIcon>
    ),
  },
  {
    title: "components.transactions",
    path: "/transactions",
    icon: (
      <SvgIcon fontSize="small">
        <SsidChartIcon />
      </SvgIcon>
    ),
  },
  {
    title: "components.exchangeTransactions",
    path: "/exchangeTransactions",
    icon: (
      <SvgIcon fontSize="small">
        <MovingIcon />
      </SvgIcon>
    ),
  },
  {
    title: "components.reports",
    path: "/reports",
    icon: (
      <SvgIcon fontSize="small">
        <ReorderSharpIcon />
      </SvgIcon>
    ),
  },
  {
    title: "components.settings",
    path: "/settings",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
  },
];

export const getHeaderTitle = (pathname) => {
  if ("/overview" === pathname) return "components.overview";
  if ("/transactions" === pathname) return "components.transactions";
  if ("/exchangeTransactions" === pathname)
    return "components.exchangeTransactions";
  if ("/reports" === pathname) return "components.reports";
  if ("/settings/accountDetails" === pathname) return "components.settings";
  if ("/settings/blockchainAddress" === pathname) return "components.settings";
  if ("/settings/blockchainExchange" === pathname) return "components.settings";
  if ("/settings/sacrificePayments" === pathname) return "components.settings";
  return "";
};
