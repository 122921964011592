import {useAuth0} from "@auth0/auth0-react";
import {Stack, Typography, useTheme} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {StyledCircularProgress} from "../../../components/StyledComponents/StyledCircularProgress";
import {getSacrificePayments} from "src/services/SacrificePayments";
import {useAccountContext} from "../../../context/AccountContext";
import StyledDataGrid from "../../../components/StyledComponents/StyledDataGrid";
import AddSacrificePaymentsModal from "./SacrificePayments/AddSacrificePaymentsModal";
import {Columns} from "./SacrificePayments/Columns";

export default function SacrificePayments(props) {
    const {isTutorial = false} = props;
    const {t} = useTranslation();
    const theme = useTheme()
    const {isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const [pageSize, setPageSize] = useState(10);
    const {currentAccount} = useAccountContext();

    const {
        data: dataSacrificePayments,
        isLoading: isLoadingUsers,
        refetch: refetchSacrificePayments,
    } = useQuery({
        queryKey: ["getSacrificePayments", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const data = await getSacrificePayments(
                token,
                user?.sub,
                currentAccount.accountId,
                currentAccount.accountId
            );
            return data;
        },
        enabled: isAuthenticated && !!currentAccount.accountId,
    });

    return (
        <>
            {!isLoadingUsers ? (
                <Stack spacing={2} direction={"column"} maxWidth={"xl"} p={3} sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows.sm,
                    borderRadius: "1.5rem",
                    border: `1px solid ${theme.palette.primary[50]}`
                }}>
                    <Stack direction={{xs: "column", sm: "row"}} justifyContent="space-between" spacing={2}>
                        {!isTutorial && (
                            <Typography
                                variant={"subtitle1"}
                            >
                                {t(
                                    "settings.sacrificePaymentsSettingsPage.yourSacrificePayments"
                                )}
                            </Typography>
                        )}

                        <AddSacrificePaymentsModal
                            refetchSacrificePayments={refetchSacrificePayments}
                        />
                    </Stack>
                    <Stack direction="column">
                        <div style={{height: "auto", width: "100%"}}>
                            <StyledDataGrid
                                columns={Columns(t, refetchSacrificePayments)}
                                rows={dataSacrificePayments?.data || []}
                                autoHeight={true}
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                disableSelectionOnClick
                                pagination={true}
                            />
                        </div>
                    </Stack>
                </Stack>
            ) : (
                <StyledCircularProgress/>
            )}
        </>
    );
}
