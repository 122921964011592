import {
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import {Stack} from "@mui/system";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {addInviteForUser} from "src/services/UserService";
import {useAccountContext} from "src/context/AccountContext";
import {StyledDialogButton} from "../../../../../components/StyledComponents/StyledDialogButtons";
import StyledDialogContent from "../../../../../components/StyledComponents/StyledDialogContent";
import StyledDialogTitle from "../../../../../components/StyledComponents/StyledDialogTitle";
import AddIcon from "@mui/icons-material/Add";

export default function InviteUserModal() {
    const {buildAuthorizeUrl} = useAuth0();
    const {t} = useTranslation();
    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
    const {user, getAccessTokenSilently} = useAuth0();
    const {currentAccount} = useAccountContext();
    const theme = useTheme()

    const handleOpenModal = () => {
        setIsInviteUserModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsInviteUserModalOpen(false);
        reset();
    };

    const {
        handleSubmit,
        reset,
        control,
        formState: {errors},
        register,
    } = useForm({});

    const onSubmit = async (data) => {
        const loginUrl = await buildAuthorizeUrl({redirect_uri: "signup"});
        const params = {
            emailOfUserToInvite: data?.email,
            roleDto: data?.roleType.toUpperCase(),
            urlRedirect: loginUrl,
        };
        const token = await getAccessTokenSilently();
        try {
            addInviteForUser(token, user?.sub, currentAccount.accountId, params);
        } catch (err) {
            console.log(err);
        }

        handleCloseModal();
        reset();
    };

    const roleTypes = [
        {
            id: 1,
            roleType: "Admin",
        },
        {
            id: 2,
            roleType: "Viewer",
        },
    ];

    return (
        <>
            <Button
                onClick={handleOpenModal}
                sx={{
                    backgroundColor: theme.palette.primary[900],
                    color: 'white',
                    padding: '10px 10px',
                    fontSize: '0.85rem',
                    fontWeight: 500,
                    borderRadius: '9999px',
                    textTransform: "capitalize",
                    boxShadow: theme.shadows.sm,
                    '&:hover': {
                        backgroundColor: theme.palette.primary[600],
                    },
                    '&:focusVisible': {
                        outline: '2px solid',
                        outlineColor: theme.palette.primary[600],
                        outlineOffset: 2,
                    },
                }}
                startIcon={<AddIcon/>}
            >
                {t("settings.accountDetailsPage.addUser")}
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={isInviteUserModalOpen}
                onClose={handleCloseModal}
            >
                <StyledDialogTitle>
                    {t("settings.accountDetailsPage.addUser")}
                </StyledDialogTitle>
                <StyledDialogContent
                    sx={{
                        alignItems: "center",
                        alignContent: "center",
                        px: {xs: "16px", md: "64px"},
                        py: {xs: "32px", md: "64px"},
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} sx={{marginTop: 3}} direction="column">
                            <Stack direction="row" justifyContent="center">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            InputLabelProps={{
                                                sx: {
                                                    color: theme.palette.background[900],
                                                }
                                            }}
                                            {...field}
                                            fullWidth
                                            label={t("settings.accountDetailsPage.email")}
                                            {...register("email", {
                                                required: t("settings.accountDetailsPage.emailError"),
                                            })}
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack direction="row" justifyContent="center">
                                <Controller
                                    render={() => (
                                        <FormControl
                                            sx={{width: 1}}
                                            error={!!errors.transactionType}
                                        >
                                            <InputLabel id="select-roleType"
                                                        sx={{color: theme.palette.background[900]}}>
                                                {t("settings.accountDetailsPage.role")}
                                            </InputLabel>
                                            <Select
                                                defaultValue={""}
                                                fullWidth
                                                labelId="select-roleType"
                                                input={
                                                    <OutlinedInput
                                                        label={t("settings.accountDetailsPage.role")}
                                                    />
                                                }
                                                {...register("roleType", {
                                                    required: t("settings.accountDetailsPage.roleError"),
                                                })}
                                            >
                                                {roleTypes?.map((role) => (
                                                    <MenuItem key={role.id} value={role.roleType}>
                                                        {role.roleType}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error={!!errors.roleType}>
                                                {errors.roleType?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    name="roleType"
                                    control={control}
                                />
                            </Stack>
                        </Stack>
                    </form>
                </StyledDialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleCloseModal}>
                        {t("settings.accountDetailsPage.cancel")}
                    </StyledDialogButton>
                    <StyledDialogButton onClick={handleSubmit(onSubmit)}>
                        {t("settings.accountDetailsPage.addUser")}
                    </StyledDialogButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
