import {Box, Container, Stack, Typography} from "@mui/material";
import screenshots from "src/assets/png/screenshots.png";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {useTranslation} from "react-i18next";


export default function Investments() {
    const {t} = useTranslation();
    return (
        <Box
            component={"section"}
            sx={{
                py: 8, // Padding top and bottom
                backgroundColor: "#DBE2E9",
                overflow: "hidden"
            }}
        >
            <Container maxWidth="xxl" padding={{xxl: "0px"}}>
                <Grid container spacing={4} py={8} justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Stack
                            mt={{xs: 5, lg: 10, xl: 11, xxl: 15.3}}
                            textAlign={{xs: 'center', lg: 'left'}}
                            maxWidth={"md"}
                            marginX={"auto"}
                        >
                            <Typography
                                variant={"h2"}
                                maxWidth={"20ch"}
                                mx={{xs: "auto", lg: "0px"}}
                                gutterBottom
                            >
                                {t("frontPage.section4header")}
                            </Typography>
                            <Typography
                                variant={"subtitle2"}
                                maxWidth={"40ch"}
                                mx={{xs: "auto", lg: "0px"}}
                            >
                                {t("frontPage.section4content")}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} lg={6} display="flex" justifyContent="center">
                        <Box
                            component="img"
                            src={screenshots}
                            alt="screenshots"
                            sx={{
                                maxWidth: {xs: "47rem", lg: "54.324rem"},
                                width: "100%",
                                height: "auto",
                                display: "block",
                                margin: "0 auto",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}