import { Button, styled } from "@mui/material";

export function StyledDialogButton(props) {
  const ButtonStyled = styled(Button)(() => ({
    color: "var(--b4bTurquoise)",
  }));

  return <ButtonStyled {...props} />;
}

export function StyledDialogButtonCancel(props) {
  const ButtonStyled = styled(Button)(() => ({
    minWidth: "10rem",
    minHeight: "48px",
    backgroundColor: "white",
    border: "solid 1px #000",
    borderRadius: "32px",
    "&:hover": {
      backgroundColor: "#000",
      color: "white",
      borderColor: "#000",
    },
    color: "#000",
    fontSize: "20px",
    fontWeight: "400",
    textTransform: "none",
  }));

  return <ButtonStyled {...props} />;
}

export function StyledDialogButtonOK(props) {
  const ButtonStyled = styled(Button)(() => ({
    minWidth: "10rem",
    minHeight: "48px",
    backgroundColor: "#000",
    border: "solid 1px white",
    borderRadius: "32px",
    "&:hover": {
      backgroundColor: "white",
      color: "#000",
      borderColor: "#000",
    },
    color: "white",
    fontSize: "20px",
    fontWeight: "400",
    textTransform: "none",
  }));

  return <ButtonStyled {...props} />;
}
