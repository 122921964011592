import React, {useEffect, useMemo, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";

import {
    Box,
    CardContent,
    Container,
    Stack,
    Typography,
    Unstable_Grid2 as Grid,
    Divider, useTheme,
} from "@mui/material";
import {FilledButton} from "src/components/StyledComponents/StyledButtons";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {getAllProductsFromPricingTable} from "./service";

export default function PricesPage() {
    const {t} = useTranslation();
    const theme = useTheme()
    const {loginWithRedirect} = useAuth0();
    const [allProducts, setProducts] = useState([]);
    const [valueTime, setValueTime] = useState("year");

    useEffect(() => {
        getAllProductsFromPricingTable().then((allProducts) => {
            setProducts(allProducts.pricing_table_items);
        });
    }, []);

    const handleDataProducts = useMemo(() => {
        if (!allProducts) return [];
        const productsShows = [];

        for (let i = 0; i < allProducts.length; i++) {
            if (valueTime !== allProducts[i].recurring.interval) continue;
            productsShows.push(allProducts[i]);
        }

        return productsShows;
    }, [allProducts, valueTime]);

    return (
        <>
            <Helmet>
                <title>{t("prices.pageHelmet")}</title>
                <meta name="Blokc" content="Blokc"/>
            </Helmet>
            <Box
                component="main"
                backgroundColor={theme.palette.background.paper}
                sx={{
                    flexGrow: 1,
                }}
            >
            <Container
                maxWidth="xxl"
                sx={{flexGrow: 1, paddingX: {xxl: "0px"}}}
            >
                <Box compontent={"section"} pt={8}>
                    <Stack
                        sx={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                        }}
                    >
                        <Stack m={"0 auto"} maxWidth={"80ch"} textAlign={"center"} pb={6}>
                            <Typography
                                variant={"h1"}
                                gutterBottom
                            >
                                {t("prices.titlePage")}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant={"h6"}
                                component={"p"}
                            >
                                {t("prices.desceptionPage")}
                            </Typography>
                        </Stack>

                        <Stack
                            direction={"row"}
                            sx={{
                                backgroundColor: theme.palette.primary[50],
                                marginBottom: 5,
                                borderRadius: "1.5rem",
                            }}
                        >
                            {[
                                {title: t("prices.perYear"), value: "year"},
                                {title: t("prices.perMonth"), value: "month"},
                            ].map((item) => {
                                const bgcolor =
                                    valueTime === item.value ? "#002554" : "transparent";

                                const color = valueTime === item.value ? "#FFF" : "#000";

                                return (
                                    <Stack
                                        key={item.value}
                                        onClick={() => setValueTime(item.value)}
                                        sx={{
                                            height: 50,
                                            width: {xs: 150, md: 300},
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            borderRadius: "1.5rem",
                                            bgcolor: bgcolor,
                                            px: "10px",
                                        }}
                                    >
                                        <Typography
                                            variant={"h6"}
                                            sx={{
                                                color: color,
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Stack>
                                );
                            })}
                        </Stack>

                        <Grid container spacing={3} py={12} rowSpacing={{xs: 10, md: 3}}>
                            {handleDataProducts?.map((product, index) => (
                                <Grid
                                    item
                                    key={product.product_id}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    sx={{
                                        px: "13px",
                                        position: "relative",
                                    }}
                                >
                                    {product.is_highlight && (
                                        <Typography
                                            fontSize={"18px"}
                                            fontWeight={"500"}
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                left: 0,
                                                top: {xs: "-10px", md: "-39px"},
                                                textAlign: "center",
                                                alignContent: "center",
                                                backgroundColor: "#FFC845",
                                                width: "80%",
                                                height: "50px",
                                                mx: "auto",
                                                borderTopRightRadius: "1.5rem",
                                                borderTopLeftRadius: "1.5rem",

                                            }}
                                        >
                                            {t(`prices.${product.highlight_text}`)}
                                        </Typography>
                                    )}
                                    <Stack
                                        sx={{
                                            alignItems: "center",
                                            borderRadius: "20px",
                                            height: "100%",
                                            backgroundColor: "#F7F9FF",
                                        }}
                                    >
                                        <CardContent sx={{textAlign: "center"}}>
                                            <Box sx={{p: 2}}>
                                                <Typography
                                                    variant={"h3"}
                                                    fontWeight={500}
                                                >
                                                    {t(`prices.package${index}.title`)}
                                                </Typography>
                                                <Divider color="#000000" sx={{marginTop: 3}}/>
                                            </Box>

                                            <Box sx={{p: 2}} mt={3}>
                                                <Typography
                                                    variant={"h2"}
                                                    sx={{textAlign: "center"}}
                                                >
                                                    {`${product.amount / 100} NOK`}
                                                </Typography>

                                                <Typography
                                                    variant={"subtitle2"}
                                                    fontWeight={"400"}
                                                    my={3}
                                                >
                                                    {t("prices.annually")}
                                                </Typography>

                                                <Typography
                                                    variant={"body1"}
                                                    color={theme.palette.text.gray}
                                                >
                                                    {t(`prices.package${index}.description`)}
                                                </Typography>

                                                <FilledButton
                                                    sx={{
                                                        height: 50,
                                                        minHeight: "12px",
                                                        fontWeight: "500",
                                                        fontSize: "1rem",
                                                        width: "100%",
                                                        my: 3,
                                                    }}
                                                    onClick={() =>
                                                        loginWithRedirect({screen_hint: "signup"})
                                                    }
                                                >
                                                    {t("prices.subscribe")}
                                                </FilledButton>
                                            </Box>

                                            <Box sx={{p: 2}}>
                                                <Typography
                                                    variant={"subtitle2"}
                                                    textAlign={"left"}
                                                    mb={1}
                                                >
                                                    {t("prices.includes")}
                                                </Typography>

                                                {product.feature_list?.map((feature, idx) => (
                                                    <Box key={idx}>
                                                        <Box py={2}>
                                                            <Stack
                                                                direction={"row"}
                                                                alignItems={"center"}
                                                                justifyContent={"start"}
                                                            >
                                                                <CheckCircleOutlineOutlinedIcon
                                                                    sx={{color: "green"}}
                                                                    fontSize={"small"}
                                                                />
                                                                <Typography
                                                                    variant={"body1"}
                                                                    ml={2}
                                                                    textAlign={"left"}
                                                                >
                                                                    {t(`prices.package${index}.includes.${idx}`)}
                                                                </Typography>
                                                            </Stack>
                                                        </Box>
                                                        <Divider/>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Box>
            </Container>
            </Box>
        </>
    );
}
