import {
  axios,
  APIBASE_B4BAPPLICATION,
  APIBASE_B4BHARVESTER,
} from "./ApiConfig";

export const getAvailableBlockchains = async (token, auth0Id) =>
  axios(token, auth0Id)
    .get(`${APIBASE_B4BAPPLICATION}/api/account/availableBlockchains`)
    .then((response) => {
      return response.data;
    });

export const uploadFile = async (token, auth0Id, accountId, type, data) =>
  axios(token, auth0Id).post(
    `${APIBASE_B4BHARVESTER}/api/exchange/${accountId}/${type}`,
    data,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
