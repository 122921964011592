import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import {Auth0Provider} from "@auth0/auth0-react";
import {LicenseInfo} from "@mui/x-license-pro";
import {QueryClientProvider, QueryClient} from "@tanstack/react-query";
import {HelmetProvider} from "react-helmet-async";
import {I18nextProvider} from "react-i18next";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {AccountContextProvider} from "./context/AccountContext";
import {SnackbarContextProvider} from "./context/SnackbarContext";
import {ThemeProvider, createTheme, responsiveFontSizes} from "@mui/material/styles";
import {theme} from "./theme"

import "./index.css";
import {CssBaseline} from "@mui/material";

LicenseInfo.setLicenseKey(
    "6b58abff0e407e95bd7ab7336eef1d4cTz03ODY2NCxFPTE3MzE1MjE2MjgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <HelmetProvider>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_TENANT}
                    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                    cacheLocation={"localstorage"}
                    useRefreshTokens={true}
                    redirectUri={`${window.location.origin}/overview`}
                >
                    <QueryClientProvider client={queryClient}>
                        <AccountContextProvider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline enableColorScheme/>
                                <SnackbarContextProvider>
                                    <App/>
                                </SnackbarContextProvider>
                            </ThemeProvider>
                        </AccountContextProvider>
                        <ReactQueryDevtools initialIsOpen/>
                    </QueryClientProvider>
                </Auth0Provider>
            </HelmetProvider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
