import { axios } from "./ApiConfig";
import {
  URL_GET_USER_BY_AUTH_ID,
  URL_GET_USER_BY_ACCOUNT_ID,
  URL_SAVE_USER,
  URL_UPDATE_DISPLAY_WIZARD,
} from "./config";
import { APIBASE_B4BAPPLICATION } from "./ApiConfig";

export const getUserByAuthId = async (token, auth0Id, accountId) => {
  const URL = URL_GET_USER_BY_AUTH_ID(auth0Id);
  return axios(token, auth0Id, accountId)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const getUserByAccountId = async (token, auth0Id, accountId) => {
  const URL = URL_GET_USER_BY_ACCOUNT_ID(accountId);
  return axios(token, auth0Id, accountId)
    .get(URL)
    .then((response) => {
      return response.data;
    });
};

export const saveUser = async (token, auth0Id, accountId, userId, userData) => {
  const URL = URL_SAVE_USER(userId);
  return axios(token, auth0Id, accountId).put(URL, userData);
};

export const updateDisplayWizard = async (
  token,
  auth0Id,
  userId,
  data
) => {
  const URL = URL_UPDATE_DISPLAY_WIZARD(userId);
  return axios(token, auth0Id).put(URL, data, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const addInviteForUser = async (token, auth0Id, accountId, data) =>
  axios(token, auth0Id).post(
    `${APIBASE_B4BAPPLICATION}/api/account/${accountId}/inviteUser`,
    data
  );
