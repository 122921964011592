import {Button, styled, useTheme} from "@mui/material";

export function StyledButtonPrimary(props) {
    const ButtonStyled = styled(Button)(() => ({
        backgroundColor: "var(--b4bTurquoise)",
        color: "white",
        border: "solid 1px var(--b4bTurquoise)",
        "&:hover": {
            backgroundColor: "white",
            color: "var(--b4bTurquoise)",
            borderColor: "var(--b4bTurquoise)",
        },
    }));

    return <ButtonStyled {...props} />;
}

export const FilledButton = styled(Button)((props) => ({
    backgroundColor: '#002554',
    color: 'white',
    padding: '10px 16px',
    fontSize: '1rem',
    fontWeight: 500,
    borderRadius: '9999px',
    textTransform: "capitalize",
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    '&:hover': {
        backgroundColor: "#7D9BC1",
    },
    '&:focusVisible': {
        outline: '2px solid',
        outlineColor: '#002554',
        outlineOffset: 2,
    },
    ...props
}))

export const OutlinedButton = styled(Button)((props) => ({
    backgroundColor: "#ffffff",
    color: "#000000",
    padding: '10px 16px',
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: "capitalize",
    borderRadius: '9999px',
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    border: `1px solid`,
    borderColor: "#002554",
    '&:hover': {
        backgroundColor: "#7D9BC1",
        borderColor: "#7D9BC1",
        color: "#ffffff"
    },
    '&:focusVisible': {
        outline: '2px solid',
        outlineColor: '#002554',
        outlineOffset: 2,
    },
    ...props
}));

export function StyledButtonWhite(props) {
    const ButtonStyled = styled(Button)(() => ({
        backgroundColor: "white",
        border: "solid 1px #222222",
        borderRadius: "32px",
        "&:hover": {
            backgroundColor: "#000",
            color: "white",
            borderColor: "#000",
        },
        "&:disabled": {
            backgroundColor: "#E4E4E480",
            border: "solid 0px #E4E4E480",
            color: "#22222280",
        },
        color: "#000",
        fontSize: "20px",
        fontWeight: "400",
        textTransform: "none",
    }));

    return <ButtonStyled {...props} />;
}

export function StyledButtonBlack(props) {
    const ButtonStyled = styled(Button)(() => ({
        backgroundColor: "#113158",
        border: "solid 1px #113158",
        borderRadius: "32px",
        "&:hover": {
            backgroundColor: "white",
            color: "#113158",
            borderColor: "#222222",
        },
        "&:disabled": {
            backgroundColor: "#11315880",
            border: "solid 0px #11315880",
            color: "white",
        },
        color: "white",
        fontSize: "20px",
        fontWeight: "400",
        textTransform: "none",
    }));

    return <ButtonStyled {...props} />;
}
