import { CircularProgress, styled, Backdrop } from "@mui/material";

export function StyledCircularProgress(props) {
  const CircularProgressStyled = styled(Backdrop)(() => ({
    color: "var(--b4bDarkPurple)",
  }));

  return (
    <CircularProgressStyled
      {...props}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </CircularProgressStyled>
  );
}
