import { Stack } from "@mui/material";
import BlockchainTable from "./BlockchainTable";

export default function BlockchainAddress() {
  return (
    <>
      <Stack>
        <BlockchainTable />
      </Stack>
    </>
  );
}
