import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import "./styled.scss";

const BaseDialog = ({
  fullWidth,
  maxWidth,
  open,
  width,
  children,
  height,
  title,
}) => {
  const useStyles = () => ({
    root: {
      "&.MuiDialog-paperWidthSm": {
        width,
        maxWidth: width,
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "calc(100% - 24px)",
        minHeight: height || "auto",
      },
    },
  });

  return (
    <>
      <Dialog
        className={classNames(`${useStyles().root}`)}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
      >
        <DialogTitle
          className={
            title === "success" ? "dialog-title-success" : "dialog-title-error"
          }
        >
          {title.toUpperCase()}
        </DialogTitle>
        <DialogContent className="p-0 mb-4">{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default BaseDialog;
