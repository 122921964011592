import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Box, Container, Stack, Typography, useTheme} from "@mui/material";
import {
    StyledButtonWhite,
    StyledButtonBlack, OutlinedButton, FilledButton,
} from "src/components/StyledComponents/StyledButtons";
import {useTranslation} from "react-i18next";
import {logoutHelpCrunch} from "src/common/helpcrunch";
import headerimage from "src/assets/svg/headerpicture.svg";
import headerimageMobile from "src/assets/png/headerpicturemobile.png";
import {BrowserView, MobileView} from "react-device-detect";

const TOP_NAV_HEIGHT = 32;

export default function HeaderFrontPage(props) {
    const {loginWithRedirect} = useAuth0();
    const {t} = useTranslation();
    const theme = useTheme()

    return (
        <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
            <Box component="header" maxWidth="xxl" sx={{margin: "0px auto"}} pb={4} pt={8}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        backgroundImage: {xs: `url(${headerimageMobile})`, lg: `url(${headerimage})`},
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                        overflow: "hidden",
                        borderRadius: "1.5rem"
                    }}
                >
                    <Stack
                        spacing={2}
                        sx={{
                            padding: 4,
                            paddingTop: 35,
                            maxWidth: {
                                lg: "50%",
                                xl: "%",
                                xxl: "42%",
                            },
                            color: {xs: "#fff", lg: "#212427"}
                        }}
                    >
                        <Typography
                            variant={"h1"}
                            fontWeight={600}
                            component={"h1"}
                        >
                            {t("frontPage.section1header")}
                        </Typography>
                        <Typography
                            variant={"h6"}
                            component={"p"}
                        >
                            {t("frontPage.section1content")}
                        </Typography>

                        <Stack
                            sx={{
                                minHeight: TOP_NAV_HEIGHT,
                                mx: 2,
                                pb: 3,
                                flexDirection: {sx: "column", sm: "row"},
                            }}
                        >
                            <OutlinedButton
                                sx={{
                                    width: {sx: "100%"},
                                    marginRight: {sm: 2},
                                    paddingLeft: {sm: 6},
                                    paddingRight: {sm: 6},
                                    marginBottom: {xs: 2},
                                    minWidth: 200,
                                }}
                                onClick={() => {
                                    logoutHelpCrunch();
                                    loginWithRedirect();
                                }}
                            >
                                {t("common.logIn")}
                            </OutlinedButton>
                            <FilledButton
                                sx={{
                                    width: {sx: "100%"},
                                    marginRight: {sm: 2},
                                    paddingLeft: {sm: 6},
                                    paddingRight: {sm: 6},
                                    marginBottom: {xs: 2},
                                    minWidth: 200,
                                }}
                                onClick={() => loginWithRedirect({screen_hint: "signup"})}
                            >
                                {t("common.signUp")}
                            </FilledButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}
