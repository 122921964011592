import { default as _axios } from "axios";

const url = `https://merchant-ui-api.stripe.com/pricing-table/${process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}?key=${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;

let config = {
  method: "get",
  maxBodyLength: Infinity,
  url: url,
  headers: {
    accept: "application/json",
    "content-type": "application/x-www-form-urlencoded",
  },
};

export const getAllProductsFromPricingTable = async () => {
  return _axios
    .request(config)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
