const getNameOfCoin = (coinName) => {
  switch (coinName) {
    case "ada.svg":
      return "ADA.svg";
    case "btc.svg":
      return "BTC.svg";
    case "doge.svg":
      return "DOGE.svg";
    case "dot.svg":
      return "DOT.svg";
    case "eth.svg":
      return "ETH.svg";
    case "uni.svg":
      return "UNI.svg";
    case "xrp.svg":
      return "XRP.svg";
    case "usdc.svg":
      return "USDC.svg";
    case "usdt.svg":
      return "USDT.svg";

    default:
      return coinName;
  }
};

export default function SvgAsset(props) {
  try {
    return (
      <img
        src={require(`../../assets/svg/${props.name}`)}
        alt={props.alt}
        className={`svg ${props.className || ""}`}
        id={props.id}
        onClick={props.onClick}
        style={props?.style || { maxWidth: "32px", maxHeight: "32px" }}
      />
    );
  } catch {
    const name = getNameOfCoin(props.name);
    try {
      return (
        <img
          src={require(`../../assets/svg/${name}`)}
          alt={props.alt}
          className={`svg ${props.className || ""}`}
          id={props.id}
          onClick={props.onClick}
          style={props?.style || { maxWidth: "32px", maxHeight: "32px" }}
        />
      );
    } catch {
      return <></>;
    }
  }
}
