import styled from "@emotion/styled";
import { MenuItem } from "@mui/material";

export function StyledMenuItem(props) {
  const StyledMenuItem = styled(MenuItem)(() => ({
    textTransform: "none",
  }));

  return (
    <StyledMenuItem onClick={props.onClick}>{props.children}</StyledMenuItem>
  );
}
