import React, { useEffect } from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function BtnExportData({
  data,
  onPrint,
  onCalculationsPDF,
  currentYearDownload,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("currentYearDownload ", currentYearDownload);
  }, [currentYearDownload]);

  return (
    <div>
      <Button
        onClick={() => onPrint(data)}
        sx={{
          height: 40,
          width: "100%",
          marginTop: "20px",
          color: "#090113",
          border: "1px solid #090113",
          borderRadius: "24px",
          textTransform: "none",
          fontWeight: "700",
          "&:hover": {
            background:
              "radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%)",
            color: "#083d56",
            fontWeight: "700",
          },
        }}
        variant="outlined"
        endIcon={<SaveAltIcon />}
      >
        {t("reports.downloadPdf", { data: data })}
      </Button>
      <Button
        onClick={() => onCalculationsPDF(data)}
        sx={{
          height: 40,
          width: "100%",
          marginTop: "20px",
          color: "#090113",
          border: "1px solid #090113",
          borderRadius: "24px",
          textTransform: "none",
          fontWeight: "700",
          "&:hover": {
            background:
              "radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%)",
            color: "#083d56",
            fontWeight: "700",
          },
        }}
        variant="outlined"
        endIcon={<SaveAltIcon />}
      >
        {t("reports.downloadCalPDF", { data: data })}
      </Button>
    </div>
  );
}
