import React from 'react';
import {Box, Stack, Typography, useTheme} from "@mui/material";
import SvgAsset from "src/components/SvgAsset/SvgAsset";
import {useTranslation} from "react-i18next";
import CurrencySelector from "src/pages/Settings/AccountDetails/components/CurrencySelector";
import Divider from "@mui/material/Divider";

function SubscriptionDetails() {
    const {t} = useTranslation();
    const theme = useTheme()

    return (
        <Stack spacing={2} direction={"column"} maxWidth={"xl"} p={3} sx={{
            width: "100%",
            backgroundColor: "#fff",
            boxShadow: theme.shadows.sm,
            borderRadius: "1.5rem",
            border: `1px solid ${theme.palette.primary[50]}`
        }}>
            <Typography
                variant={"subtitle1"}
            >
                {t("settings.accountDetailsPage.subscriptionDetails")}
            </Typography>

            <SvgAsset
                name={"subscriptionIcon.svg"}
                style={{width: "100%"}}
            />
            <Typography
                sx={{fontSize: "16px", fontWeight: "400", marginTop: 2}}
            >
                {t("settings.accountDetailsPage.subscriptionDesception")}
            </Typography>

            <Box component={"a"}
                 href={process.env.REACT_APP_STRIPE_PAYMENT_RECEIPTS_DEV}
                 target={"_blank"}
                 sx={{
                     width: "100%",
                     py: 1,
                     px: 0,
                     mt: 2,
                     color: theme.palette.text.secondary,
                     textDecoration: "none",
                     bgcolor: theme.palette.primary[400],
                     padding: '10px 16px',
                     fontSize: '1rem',
                     fontWeight: 500,
                     borderRadius: '9999px',
                     textTransform: "capitalize",
                     boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                     '&:hover': {
                         bgcolor: theme.palette.primary[400],
                     },
                     '&:focusVisible': {
                         outline: '2px solid',
                         outlineColor: '#002554',
                         outlineOffset: 2,
                     },
                 }}>
                <Typography
                    variant={"body1"}
                    sx={{
                        textAlign: "center",
                        fontWeight: "600",
                    }}
                >
                    {t("settings.accountDetailsPage.stripePaymentsReceipts")}
                </Typography>
            </Box>
            <Divider/>
            <CurrencySelector/>
        </Stack>
    );
}

export default SubscriptionDetails;