import {Link, Outlet, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {Box, Container, Link as LinkMUI, Stack, Typography, Unstable_Grid2 as Grid, useTheme,} from "@mui/material";

import {titleSetting} from "src/constant/setting.const";


export default function Settings() {
    const location = useLocation();
    const activePage = location.pathname.split("settings/")[1];
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>{t("settings.pageHelmet")}</title>
                <meta name="Blokc" content="Blokc"/>
            </Helmet>
            <Box
                sx={{
                    flexGrow: 1,
                    py: 8,
                    mx: "auto"
                }}
                component={"main"}
            >
                <Container maxWidth={"xxl"}>
                    <Grid container>
                        <Grid xs={12} lg={8} direction={"column"}>
                            <Grid container sx={{
                                bgcolor: theme.palette.primary[50],
                                marginBottom: 5,
                                borderRadius: "24px",
                                boxShadow: 1
                            }}>
                                {titleSetting.map((item, index) => {
                                    const title = titleSetting.filter(
                                        (itemCheck) => itemCheck === item
                                    )[0];
                                    const bgcolor = activePage === item ? theme.palette.primary[900] : "transparent";
                                    const color = activePage === item ? theme.palette.text.secondary : theme.palette.text.primary;

                                    return (
                                        <Grid item xs={6} sm={3}>
                                            <LinkMUI
                                                key={index}
                                                component={Link}
                                                to={item}
                                                sx={{
                                                    flex: 1,
                                                    minHeight: 50,
                                                }}
                                                underline="none"
                                            >
                                                <Stack
                                                    sx={{
                                                        height: "100%",
                                                        justifyContent: "center",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        borderRadius: "24px",
                                                        bgcolor: bgcolor,
                                                        px: "0.5px",
                                                        py: "1rem"
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontSize: "16px",
                                                            fontWeight: "600",
                                                            color: color,
                                                        }}
                                                    >
                                                        {t(`settings.tabs.${title}`)}
                                                    </Typography>
                                                </Stack>
                                            </LinkMUI>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>

                        <Grid xs={12} lg={12}>
                            <Outlet/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
