import * as React from "react";
import PropTypes from "prop-types";
import {Box, Card, CardContent, Stack} from "@mui/material";
import {useAccountContext} from "../../../context/AccountContext";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from "@tanstack/react-query";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {portfolioValueGraph} from "../../../services/PortfolioService";

import {Area, AreaChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {t} from "i18next";
import {format_YYYYMMDD, formatDate} from "../../../common/dateFormat";
import {StyledCardHeader, StyledSelect} from "../styles";
import Lottie from "lottie-react";
import LoadingAnimation from "src/assets/iconJson/LoadingAnimation.json";
import IconNoData from "src/assets/iconJson/IconNoData.json";

let today = new Date();

export const OverviewPortfolio = (props) => {
    const {sx, title = "", subheader = ""} = props;

    const [coins, setCoins] = React.useState([]);
    const [coin, setCoin] = React.useState("");
    const [dataOfCoinDates, setDataOfCoinDates] = React.useState([]);
    const {currentAccount} = useAccountContext();
    const {isAuthenticated, isLoading, user, getAccessTokenSilently} =
        useAuth0();
    const [keyData, setKeyData] = React.useState("totalValueForAllCoinsNok");
    const [keyDate, setKeyDate] = React.useState("WEEK");
    const [endDateState, setEndDate] = React.useState(
        formatDate(new Date().setMonth(today.getMonth() - 3), format_YYYYMMDD)
    );

    const dateOptions = [
        {
            name: t("overview.optionsDay.days"),
            value: "DAY",
            endDate: formatDate(
                new Date(new Date().setDate(today.getDate() - 30)),
                format_YYYYMMDD
            ),
        },
        {
            name: t("overview.optionsDay.month3"),
            value: "WEEK",
            endDate: formatDate(
                new Date().setMonth(today.getMonth() - 3),
                format_YYYYMMDD
            ),
        },
        {
            name: t("overview.optionsDay.month6"),
            value: "MONTH1",
            endDate: formatDate(
                new Date().setMonth(today.getMonth() - 6),
                format_YYYYMMDD
            ),
        },
        {
            name: t("overview.optionsDay.year"),
            value: "MONTH2",
            endDate: formatDate(
                new Date().setFullYear(today.getFullYear() - 1),
                format_YYYYMMDD
            ),
        },
        {name: t("overview.optionsDay.max"), value: "YEAR", endDate: ""},
    ];

    const {
        data: valueGraph,
        isLoading: loadingValueGraphPortfolio,
    } = useQuery({
        queryKey: ["portfolioValueGraph", currentAccount.accountId, keyDate],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const data = await portfolioValueGraph(
                token,
                user?.sub,
                currentAccount.accountId,
                keyDate,
                endDateState,
                formatDate(new Date(), format_YYYYMMDD)
            );
            const valueAtTimeListTemp = data?.valueAtTimeList || [];
            let dataTemp = [];
            for (let valueTime of valueAtTimeListTemp) {
                if (
                    valueTime.totalValueForAllCoinsNok === 0 &&
                    valueTime.totalValueForAllCoinsUsd === 0
                )
                    continue;
                dataTemp.push({
                    ...valueTime,
                    date:
                        keyDate === "YEAR"
                            ? new Date(valueTime.date).getFullYear()
                            : keyDate === "DAY"
                                ? formatDate(valueTime.date, "DD")
                                : valueTime.date,
                });
            }

            return {valueAtTimeList: dataTemp};
        },
        enabled: !isLoading && isAuthenticated && !!currentAccount.accountId,
        onSuccess: (data) => {
            let coins = {};
            for (let dataDate of data?.valueAtTimeList) {
                if (dataDate.valuePerCoin?.length > 0) {
                    for (let coin of dataDate.valuePerCoin) {
                        if (coins[coin.coin.id]) continue;
                        coins[coin.coin.id] = coin;
                    }
                }
            }
            setCoins(Object.values(coins));
        },
    });

    React.useEffect(() => {
        setCoin("");
        setDataOfCoinDates([]);
    }, [currentAccount]);

    React.useEffect(() => {
        let dataOfCoin = [];
        let dataCoinOfDate = {};
        if (valueGraph && valueGraph.valueAtTimeList && coin !== "") {
            for (let dataDate of valueGraph?.valueAtTimeList) {
                dataCoinOfDate = {
                    date: dataDate.date,
                    totalValueForAllCoinsNok: 0,
                    totalValueForAllCoinsUsd: 0,
                };
                if (dataDate.valuePerCoin?.length > 0) {
                    for (let coinIn of dataDate.valuePerCoin) {
                        if (coinIn.coin.id !== coin) continue;
                        dataCoinOfDate.totalValueForAllCoinsNok = coinIn.valueNok;
                        dataCoinOfDate.totalValueForAllCoinsUsd = coinIn.valueUsd;
                    }
                }
                dataOfCoin.push(dataCoinOfDate);
            }
            setDataOfCoinDates(dataOfCoin);
        }
    }, [coin, valueGraph]);

    const handleChangeCoin = (event) => {
        setCoin(event.target.value);
    };

    const handleCurrency = (event) => {
        setKeyData(event.target.value);
    };

    const handleDate = (event) => {
        let endDate = "";
        for (let i = 0; i < dateOptions.length; i++) {
            if (dateOptions[i].value !== event.target.value) continue;
            endDate = dateOptions[i].endDate;
        }
        setEndDate(endDate);
        setKeyDate(event.target.value);
        // setTimeout(() => {
        //   refetchPage();
        // }, 500);
    };

    return (
        <Card sx={sx} variant="outlined">
            <StyledCardHeader
                action={
                    <Box sx={{width: {xs: "8rem", xsm: "15rem", md: "35rem"}}}>
                        <Stack direction={"row"} flexWrap={"wrap"} gap={{xs: 1, sm: 2}}
                               justifyContent={"flex-end"}>
                            <FormControl
                                sx={{minWidth: {xs: 50, md: 100, lg: 150}, borderWidth: 0.5}}
                                size="small"
                                color="info"
                            >
                                <StyledSelect value={keyData} onChange={handleCurrency}>
                                    {[
                                        {
                                            name: t("overview.usd"),
                                            value: "totalValueForAllCoinsUsd",
                                        },
                                        {
                                            name: t("overview.nok"),
                                            value: "totalValueForAllCoinsNok",
                                        },
                                    ].map((currency, index) => (
                                        <MenuItem key={index + "Currency"} value={currency.value}>
                                            {currency.name}
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                            </FormControl>
                            <FormControl
                                sx={{minWidth: {xs: 50, md: 100, lg: 150}, borderWidth: 0.5}}
                                size="small"
                                color="info"
                            >
                                <StyledSelect value={coin} onChange={handleChangeCoin}>
                                    <MenuItem value="">
                                        <em>{t("overview.allCoins")}</em>
                                    </MenuItem>
                                    {coins?.length > 0 &&
                                        coins?.map((coin) => (
                                            <MenuItem key={coin.coin.id} value={coin.coin.id}>
                                                {coin.coin.name}
                                            </MenuItem>
                                        ))}
                                </StyledSelect>
                            </FormControl>

                            <FormControl
                                sx={{minWidth: {xs: 50, md: 100, lg: 150}, borderWidth: 0.5}}
                                size="small"
                                color="info"
                            >
                                <StyledSelect value={keyDate} onChange={handleDate}>
                                    {dateOptions.map((date, index) => (
                                        <MenuItem key={index + "date"} value={date.value}>
                                            {date.name}
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                            </FormControl>
                        </Stack>
                    </Box>
                }
                title={title}
                subheader={subheader}
            />
            <CardContent>
                {loadingValueGraphPortfolio && (
                    <Stack
                        sx={{
                            width: "100%",
                            height: "10px",
                            position: "relative",
                            top: 150,
                        }}
                    >
                        <Lottie
                            animationData={LoadingAnimation}
                            style={{height: "100px"}}
                        />
                    </Stack>
                )}

                {!loadingValueGraphPortfolio &&
                    valueGraph.valueAtTimeList?.length === 0 && (
                        <Stack
                            sx={{
                                width: "100%",
                                height: "10px",
                                position: "relative",
                                top: 150,
                            }}
                        >
                            <Lottie animationData={IconNoData} style={{height: "100px"}}/>
                        </Stack>
                    )}

                <ResponsiveContainer width={"100%"} height={400}>
                    <AreaChart
                        width={"80%"}
                        height={"100%"}
                        data={
                            coin === "" ? valueGraph?.valueAtTimeList || [] : dataOfCoinDates
                        }
                        margin={{
                            left: 30,
                            right: 15,
                            bottom: keyDate === "YEAR" || keyDate === "DAY" ? 50 : 85,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#FCCB06" stopOpacity={0.19}/>
                                <stop offset="100%" stopColor="#FCCB06" stopOpacity={0.0285}/>
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="date"
                            angle={keyDate === "YEAR" || keyDate === "DAY" ? 0 : -90}
                            textAnchor="end"
                        >
                            <Label
                                value={t("overview.date")}
                                offset={keyDate === "YEAR" || keyDate === "DAY" ? -25 : -85}
                                position="insideBottom"
                            />
                        </XAxis>
                        <YAxis
                            label={{
                                value:
                                    keyData === "totalValueForAllCoinsUsd"
                                        ? t("overview.usd")
                                        : t("overview.nok"),
                                angle: -90,
                                position: "insideLeft",
                                offset: -15,
                            }}
                        />
                        <CartesianGrid strokeDasharray="6 6"/>
                        <Tooltip/>
                        <Area
                            type="monotone"
                            dataKey={keyData}
                            stroke="#FCCB06"
                            fillOpacity={1}
                            fill="url(#colorUv)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

OverviewPortfolio.protoTypes = {
    chartSeries: PropTypes.array.isRequired,
    sx: PropTypes.object,
    categories: PropTypes.array,
};
