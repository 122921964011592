import {useAuth0} from "@auth0/auth0-react";
import {Stack, Typography, useTheme} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {StyledCircularProgress} from "../../../components/StyledComponents/StyledCircularProgress";
import {getAddressesForUser} from "../../../services/AddressService";
import {useAccountContext} from "../../../context/AccountContext";
import StyledDataGrid from "../../../components/StyledComponents/StyledDataGrid";
import AddAddressModal from "./AddAddressModal";
import {blockchainColumnColumns} from "./BlockchainColumn";
import {isMobile} from "react-device-detect";

export default function BlockchainTable(props) {
    const {isTutorial = false} = props;
    const {t} = useTranslation();
    const {isAuthenticated, user, getAccessTokenSilently} = useAuth0();
    const [pageSize, setPageSize] = useState(10);
    const {currentAccount} = useAccountContext();
    const theme = useTheme()

    const {
        data: addressesData,
        isLoading: isLoadingAddresses,
        refetch: refetchAddresses,
    } = useQuery({
        queryKey: ["getAddressesForUser", currentAccount.accountId],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const data = await getAddressesForUser(
                token,
                user?.sub,
                currentAccount.accountId
            );
            return data;
        },
        enabled: isAuthenticated && !!currentAccount.accountId,
    });

    return (
        <>
            {!isLoadingAddresses ? (
                <Stack maxWidth={"xl"} spacing={2} direction="column" sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "1.5rem",
                    boxShadow: theme.shadows.sm,
                    border: `1px solid ${theme.palette.primary[50]}`
                }} p={3}>
                    <Stack direction="row" justifyContent="space-between">
                        {!isTutorial && (
                            <Typography
                                variant={"h5"}
                            >
                                {t("settings.addressSettingsPage.yourBlockchainAddress")}
                            </Typography>
                        )}

                        {!isMobile && (
                            <AddAddressModal refetchAddresses={refetchAddresses}/>
                        )}
                    </Stack>
                    {isMobile && <AddAddressModal refetchAddresses={refetchAddresses}/>}

                    <Stack direction="column">
                        <div style={{height: "auto", width: "100%"}}>
                            <StyledDataGrid
                                columns={blockchainColumnColumns(t, refetchAddresses)}
                                rows={addressesData?.data || []}
                                autoHeight={true}
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                disableSelectionOnClick
                                pagination={true}
                            />
                        </div>
                    </Stack>
                </Stack>
            ) : (
                <StyledCircularProgress/>
            )}
        </>
    );
}
