import {useAuth0} from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  useTheme,
} from "@mui/material";
import {Stack} from "@mui/system";
import {useMutation, useQueries} from "@tanstack/react-query";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {addSacrificePayments} from "src/services/SacrificePayments";
import {useAccountContext} from "../../../../context/AccountContext";
import StyledDialogContent from "../../../../components/StyledComponents/StyledDialogContent";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import {getCoinOptions, getSpecialAddress} from "src/services/Transactions";
import {useSnackbarContext} from "src/context/SnackbarContext";

export default function AddSacrificePaymentsModal(props) {
    const {t} = useTranslation();
    const theme = useTheme();
    const {user, getAccessTokenSilently} = useAuth0();
    const [selectedCoinId, setSelectedCoinId] = useState("");
    const [selectedSpecialAddressId, setSelectedSpecialAddressId] = useState("");
    const [isAddSacrificePaymentsModalOpen, setIsAddSacrificePaymentsModalOpen] =
        useState(false);
    const {currentAccount} = useAccountContext();
    const {setDataSnackbar} = useSnackbarContext();

    const [{data: availableCoins}, {data: specialAddress}] = useQueries({
        queries: [
            {
                queryKey: ['getAvailableCoins'],
                queryFn: async () => {
                    const token = await getAccessTokenSilently();
                    const data = await getCoinOptions(token, user?.sub);
                    return data;
                },
                enabled: isAddSacrificePaymentsModalOpen,
            },

            {
                queryKey: ['getSpecialAddress'],
                queryFn: async () => {
                    const token = await getAccessTokenSilently();
                    const data = await getSpecialAddress(token, user?.sub);
                    return data;
                },
                enabled: isAddSacrificePaymentsModalOpen,
            },
        ],
    });

    const addNewSacrificePayments = useMutation(["addSacrificePayments"], {
        mutationFn: async (data) => {
            const token = await getAccessTokenSilently();
            const dataResult = await addSacrificePayments(
                token,
                user?.sub,
                currentAccount.accountId,
                data
            );
            if (dataResult.status === 200) {
                setTimeout(() => {
                    setDataSnackbar({
                        isOpen: true,
                        severity: "success",
                        message: "success",
                        action: null,
                    });
                }, 2000);
            } else {
                setTimeout(() => {
                    setDataSnackbar({
                        isOpen: true,
                        severity: "error",
                        message: "Error",
                        action: null,
                    });
                }, 2000);
            }
        },
        onSuccess: async () => {
            await props.refetchSacrificePayments();
            reset(setSelectedCoinId(""), {
                amount: "",
                receivedDate: new Date().toISOString(),
            }, setSelectedSpecialAddressId(""));
        },
    });

    const handleOpenAddSacrificePaymentsModal = () => {
        setIsAddSacrificePaymentsModalOpen(true);
    };

    const handleCloseAddSacrificePaymentsModal = () => {
        setIsAddSacrificePaymentsModalOpen(false);
        reset(setSelectedCoinId(""), setSelectedSpecialAddressId(""), {
                amount: "",
                receivedDate: new Date().toISOString(),
            },
        )
    };

    const handleChangeCoinId = (e) => {
        setSelectedCoinId(e);
    };

    const handleChangeSpecialAddress = (e) => {
        setSelectedSpecialAddressId(e);
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        register,
    } = useForm({
        defaultValues: {
            amount: "",
            coinId: null,
            receivedDate: new Date().toISOString(),
        },
    });

    const onSubmit = async (data) => {
        var receivedDateFormat = new Date(data.receivedDate).toISOString();

        let getObjectSelected = availableCoins.filter(
            (item) => item.name === data.coinId
        )[0];
        delete data.coinId;
        const updateData = Object.assign(data, {
            amount: data.amount * 1,
            coinId: getObjectSelected.id,
            receivedDate: receivedDateFormat ?? new Date().toISOString(),
            specialAddressId: selectedSpecialAddressId
        });
        addNewSacrificePayments.mutate(updateData);
        setIsAddSacrificePaymentsModalOpen(false);
    };

    const StyledNumberField = styled(TextField)(() => ({
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& input[type=number]": {
            MozAppearance: "textfield",
        },
        width: "15rem",
    }));

    return (
        <Stack>
            <Button
                onClick={handleOpenAddSacrificePaymentsModal}
                sx={{
                    backgroundColor: theme.palette.primary[900],
                    color: 'white',
                    paddingY: '10px',
                    fontSize: '0.85rem',
                    fontWeight: 500,
                    borderRadius: '9999px',
                    textTransform: "capitalize",
                    boxShadow: theme.shadows.sm,
                    '&:hover': {
                        backgroundColor: theme.palette.primary[600],
                    },
                    '&:focusVisible': {
                        outline: '2px solid',
                        outlineColor: theme.palette.primary[600],
                        outlineOffset: 2,
                    },
                }}
                startIcon={<AddIcon/>}
            >
                {t("settings.sacrificePaymentsSettingsPage.addSacrificePayments")}
            </Button>

            {(
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={isAddSacrificePaymentsModalOpen}
                    onClose={handleCloseAddSacrificePaymentsModal}
                >
                    <StyledDialogContent
                        sx={{
                            alignItems: "center",
                            alignContent: "center",
                            px: "50px",
                            py: "50px",
                        }}
                    >
                        <Stack sx={{flex: 1}}>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        lineHeight: "16px",
                                        textAlign: "center",
                                        color: "#000000",
                                        mb: 2,
                                    }}
                                >
                                    {t(
                                        "settings.sacrificePaymentsSettingsPage.addSacrificePaymentsTitle"
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        textAlign: "center",
                                        color: "#626262",
                                    }}
                                >
                                    {t(
                                        "settings.sacrificePaymentsSettingsPage.addSacrificePaymentsDes"
                                    )}
                                </Typography>
                            </Stack>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={2}
                                    columns={12}
                                    my={"48px"}
                                    sx={{
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid>
                                        <Controller
                                            name="amount"
                                            control={control}
                                            render={({field}) => (
                                                <StyledNumberField
                                                    {...field}
                                                    type="number"
                                                    label={t("sacrificePayments.amount")}
                                                    {...register("amount", {
                                                        required: t("sacrificePayments.amountError"),
                                                    })}
                                                    error={!!errors.amount}
                                                    helperText={errors.amount?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Controller
                                            render={() => (
                                                <FormControl>
                                                    <InputLabel id="select-coinId">
                                                        {t("sacrificePayments.coinId")}
                                                    </InputLabel>
                                                    <Select
                                                        defaultValue={selectedCoinId}
                                                        sx={{width: "15rem"}}
                                                        labelId="select-coinId"
                                                        input={
                                                            <OutlinedInput
                                                                label={t("sacrificePayments.coinId")}
                                                            />
                                                        }
                                                        {...register("coinId", {
                                                            onChange: (e) =>
                                                                handleChangeCoinId(e.target.value),
                                                            required: t("sacrificePayments.coinIdError"),
                                                        })}
                                                    >
                                                        {availableCoins
                                                            ?.sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((blockchain) => (
                                                                <MenuItem
                                                                    key={blockchain.id}
                                                                    value={blockchain.name}
                                                                >
                                                                    {t(blockchain.name)}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    <FormHelperText error={!!errors.coinId}>
                                                        {errors.coinId?.message}
                                                    </FormHelperText>
                                                </FormControl>
                                            )}
                                            name="coinId"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Controller
                                            render={() => (
                                                <FormControl>
                                                    <InputLabel id="select-special-address">
                                                        {t("sacrificePayments.specialAddress")}
                                                    </InputLabel>
                                                    <Select
                                                        defaultValue={selectedSpecialAddressId}
                                                        sx={{width: "15rem"}}
                                                        labelId="select-special-address"
                                                        input={
                                                            <OutlinedInput
                                                                label={t("sacrificePayments.specialAddress")}
                                                            />
                                                        }
                                                        {...register("specialAddress", {
                                                            onChange: (e) =>
                                                                handleChangeSpecialAddress(e.target.value),
                                                            required: t("sacrificePayments.specialAddressError"),
                                                        })}
                                                    >
                                                        {specialAddress
                                                            ?.sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((item) => (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {`${t(item.description)}`}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    <FormHelperText error={!!errors.coinId}>
                                                        {errors.coinId?.message}
                                                    </FormHelperText>
                                                </FormControl>
                                            )}
                                            name="coinId"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid>
                                        <Controller
                                            name="receivedDate"
                                            control={control}
                                            render={({field}) => (
                                                <FormControl>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            {...field}
                                                            ampm={false}
                                                            label={t("sacrificePayments.receivedDate")}
                                                            disableFuture={true}
                                                            renderInput={(field) => (
                                                                <TextField
                                                                    {...field}
                                                                    type="datetime-local"
                                                                    {...register("receivedDate", {
                                                                        required: t(
                                                                            "sacrificePayments.receivedDateError"
                                                                        ),
                                                                    })}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText error={!!errors.receivedDate}>
                                                            {errors.receivedDate?.message}
                                                        </FormHelperText>
                                                    </LocalizationProvider>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                            <Stack
                                gap={2}
                                direction={"row"}
                                justifyContent={"center"}
                                sx={{
                                    flexShrink: 0,
                                    alignSelf: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={handleCloseAddSacrificePaymentsModal}
                                    sx={{
                                        px: "32px",
                                        height: 48,
                                        color: "#090113",
                                        border: "1px solid #090113",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            backgroundColor: "#000000",
                                            color: "#fff",
                                            border: "1px solid #000000",
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    {t("settings.cancel")}
                                </Button>
                                <Button
                                    sx={{
                                        px: "32px",
                                        height: 48,
                                        color: "#FFFFFF",
                                        border: "1px solid #000000",
                                        borderRadius: "24px",
                                        textTransform: "none",
                                        bgcolor: "#000000",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            color: "#000000",
                                            border: "1px solid #000000",
                                        },
                                    }}
                                    size="small"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t("settings.submit")}
                                </Button>
                            </Stack>
                        </Stack>
                    </StyledDialogContent>
                </Dialog>
            )}
        </Stack>
    );
}

AddSacrificePaymentsModal.propTypes = {
    refetchSacrificePayments: PropTypes.func,
};
