import TableActionsAddresses from "./TableActionsAddresses";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Stack } from "@mui/material";
import SvgAsset from "../../../components/SvgAsset/SvgAsset";

export const blockchainColumnColumns = (t, refetchAddresses) => {
  return [
    {
      field: "blockchain",
      headerName: t("settings.addressSettingsPage.blockchain"),
      width: 200,
      valueFormatter: ({ value }) => {
        if (value === 0) {
          return "-";
        }
        return value.blockchain;
      },
      renderCell: (params) => {
        return (
          <Stack direction={"row"} alignItems="center">
            <SvgAsset
              name={params.row.blockchain.blockchain}
              style={{ width: "32px", height: "32px", marginRight: "10px" }}
            />

            {params.row.blockchain.blockchain}
          </Stack>
        );
      },
    },
    {
      field: "description",
      headerName: "",
      width: 100,
    },

    {
      field: "address",
      headerName: t("settings.addressSettingsPage.address"),
      minWidth: 600,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("settings.actions"),
      maxWidth: 50,
      align: "center",
      renderHeader: () => <MoreHorizIcon />,
      renderCell: (params) => {
        return (
          <TableActionsAddresses
            itemId={params.row}
            refetchAddresses={refetchAddresses}
          />
        );
      },
    },
  ];
};
