import {
    Box,
    CardActionArea,
    CardContent,
    Container,
    Stack,
    Typography,
    Unstable_Grid2 as Grid,
    useTheme
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {dataContent, dataTeams} from "src/constant/aboutUs.const";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import React from "react";
import Divider from "@mui/material/Divider";

export default function AboutUsPage() {
    const {t} = useTranslation();
    const theme = useTheme()

    return (
        <>
            <Helmet>
                <title>{t("aboutUs.pageHelmet")}</title>
                <meta name="Blokc" content="Blokc"/>
            </Helmet>
            <Box
                component="main"
                backgroundColor={theme.palette.background.paper}
                sx={{
                    flexGrow: 1,
                }}
            >
                <Container maxWidth={"xxl"} px={{xxl: "0px"}}>
                    <Box maxWidth="xxl" pt={8}>
                        <Stack sx={{margin: "0px auto", textAlign: "center"}} py={3}>
                            <Typography
                                gutterBottom
                                variant={"h1"}
                            >
                                {t("aboutUs.titlePage")}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant={"h6"}
                                component={"p"}
                                maxWidth={"40ch"}
                                mx={"auto"}
                            >
                                {t("aboutUs.desceptionPage")}
                            </Typography>
                        </Stack>
                    </Box>
                </Container>
                <Box
                    component={"section"}
                    sx={{
                        py: {xs: 3, md: 10},
                        mt: 16,
                        backgroundColor: theme.palette.background[250],
                    }}
                >
                    <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
                        <Grid container sx={{width: "100%", flexGrow: 1}}>
                            {dataContent.map((item, index) => {
                                return (
                                    <Grid
                                        item
                                        key={index}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        xs={12}
                                        lg={4}
                                        sm={6}
                                    >
                                        <Stack
                                            sx={{
                                                alignItems: "center",
                                                border: "solid 0px #000",
                                                borderRadius: 2.5,
                                                mb: {xs: 2},
                                                height: "92%",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant={"h5"}
                                                >
                                                    {t(item.contentHeader)}
                                                </Typography>
                                                <Divider/>
                                                <Typography
                                                    pt={1}
                                                    variant={"body1"}
                                                >
                                                    {t(item.contentBodyUpper)}
                                                    {" "}
                                                    {t(item.contentBodyLower)}
                                                </Typography>
                                            </CardContent>
                                        </Stack>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Container>
                </Box>
                <Container maxWidth={"xxl"} px={{xxl: "0px"}}>
                    <Stack py={12}>
                        <Typography
                            variant={"h2"}
                            gutterBottom
                        >
                            {t("aboutUs.ourTeam")}
                        </Typography>
                        <Grid
                            container
                            rowSpacing={5}
                            columnSpacing={{xs: 0, md: 2}}
                        >
                            {dataTeams.map((item, index) => {
                                return (
                                    <Grid
                                        item
                                        key={index}
                                        xs={12}
                                        lg={4}
                                        sm={6}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "stretch",
                                        }}
                                    >
                                        <Card sx={{px: 1}}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="320"
                                                    image={item.avatar}
                                                    alt="green iguana"
                                                    sx={{borderRadius: 2}}
                                                />
                                                <CardContent sx={{px: 0}}>
                                                    <Typography
                                                        gutterBottom
                                                        sx={{
                                                            textAlign: "left",
                                                            fontSize: "24px",
                                                            fontWeight: "600",
                                                            lineHeight: "29.05px",
                                                        }}
                                                    >
                                                        {t(item.contentHeader)}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            textAlign: "left",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            lineHeight: "19.36px",
                                                            color: "#000000",
                                                            my: 1,
                                                        }}
                                                    >
                                                        {t(item.contentBodyUpper)}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            textAlign: "left",
                                                            fontSize: "16px",
                                                            fontWeight: "400",
                                                            lineHeight: "19.36px",
                                                            color: "#000000",
                                                        }}
                                                    >
                                                        {t(item.contentBodyLower)}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}

{/* <Stack
                    sx={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    <Stack sx={{width: {xs: "90%", md: "30%"}}} py="140px">
                        <Typography
                            sx={{
                                textAlign: "center",
                                color: "#000",
                                fontSize: {xs: "36px", sm: "48px"},
                                fontWeight: "600",
                                lineHeight: {xs: "36px", sm: "48px", md: "77px"},
                            }}
                        >
                            {t("aboutUs.titlePage")}
                        </Typography>
                        <Typography
                            gutterBottom
                            sx={{
                                textAlign: "center",
                                color: "#000",
                                fontSize: "20px",
                                fontWeight: "400",
                                mb: "30px",
                            }}
                        >
                            {t("aboutUs.desceptionPage")}
                        </Typography>
                    </Stack>

                </Stack>
**/
}
