import React from "react";
import BaseDialog from 'src/components/dialog/BaseDialog'

const ConfirmDialog = ({ open, children, width, title }) => {
  const handler = (open) => {}
  return (
    <>
      <BaseDialog
        breakPointDown="sm"
        idTitle="confirm-dialog"
        handler={handler}
        hideHeader
        hideFooter
        open={open}
        width={width}
        title={title}
      >
        <div className="px-2rem">{children}</div>
      </BaseDialog>
    </>
  );
};

export default ConfirmDialog;
