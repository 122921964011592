import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {Box, Container, Stack, Typography, styled} from "@mui/material";
import {useTranslation} from "react-i18next";
import logo from 'src/assets/png/logo.png'


function StyledTextFooter(props) {
    const TypographyStyled = styled(Typography)(() => ({
        color: "#fff",
        fontSize: "16px",
        fontWeight: "400",
    }));

    return <TypographyStyled {...props} />;
}

export default function RootFooterNotAuthent() {
    const {t} = useTranslation();
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: "#001846",
                p: 3,
            }}
        >
            <Container maxWidth="xxl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Stack
                            sx={{
                                width: "50%",
                                alignItems: "left",
                                justifyContent: "left",
                                color: "#fff",
                            }}
                        >
                            <img src={logo} alt="logo"/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={9} container spacing={2}>
                        <Grid item xs={12} sm={4} lg={3}>
                            <StyledTextFooter gutterBottom>{t('frontPage.aboutUs')}</StyledTextFooter>
                            <StyledTextFooter gutterBottom>{t('frontPage.prices')}</StyledTextFooter>
                            <StyledTextFooter gutterBottom>{t('common.logIn')}</StyledTextFooter>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3}>
                            <StyledTextFooter gutterBottom>{t('frontPage.contactUs')}</StyledTextFooter>
                            <StyledTextFooter gutterBottom>{t('frontPage.companyName')}</StyledTextFooter>
                            <StyledTextFooter gutterBottom>
                                {t('frontPage.companyAddress')}
                            </StyledTextFooter>
                            <StyledTextFooter gutterBottom>{t('frontPage.companyCountry')}</StyledTextFooter>
                        </Grid>
                    </Grid>
                </Grid>

                <Stack
                    sx={{
                        height: "2px",
                        width: "100%",
                        bgcolor: "#fff",
                        my: "74px",
                    }}
                />
                <Box mt={5}>
                    <Typography variant="body2" color="#fff" align="left">
                        {"Copyright © "}
                        <Link color="inherit" href="https://blokc.no">
                            {`${new Date().getFullYear()} by Blokc AS.`}
                        </Link>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
