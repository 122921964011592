import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-premium";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableActionsExchangeTransactions from "./TableActionsExchangeTransactions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatDate } from "../../../common/dateFormat";
import SvgAsset from "../../../components/SvgAsset/SvgAsset";
import { IconButton, Stack } from "@mui/material";

function getDetailPanelContent(apiRef, id) {
  if (apiRef) {
    const seleteds = apiRef?.current?.getExpandedDetailPanels() || [];
    if (seleteds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
}

export const exchangeTransactionsColumns = (
  t,
  apiRef = null,
  handleClick = () => { },
  refetchPage
) => {
  return [
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerClassName: "super-app-theme--header",
      disableExport: true,
      maxWidth: 5,
      hide: true,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "coin",
      headerName: t("exchangeTransactions.coin"),
      flex: 1,
      minWidth: 150,
      disableExport: true,
      renderHeader: () => <strong>{t("exchangeTransactions.coin")}</strong>,
      renderCell: (params) => {
        let isItemSelected = getDetailPanelContent(apiRef, params.id);
        return (
          <Stack direction={"row"} alignItems="center">
            <IconButton
              onClick={() => handleClick(params)}
              aria-label="expand row"
              size="small"
            >
              {isItemSelected ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>

            <SvgAsset
              name={
                params.row.to.coin
                  ? params.row.to.coin.icon
                  : params.row.to.currency
                    ? params.row.to.currency.icon
                    : ""
              }
              style={{ width: "32px", height: "32px", marginRight: "10px" }}
            />

            {params.row.to.coin
              ? params.row.to.coin.name
              : params.row.to.currency
                ? params.row.to.currency.name
                : t('exchangeTransactions.unknown')}
          </Stack>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "transactionTimestamp",
      headerName: t("exchangeTransactions.transactionTimestamp"),
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return params.row.transactionDate;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "transactionDate",
      headerName: t("exchangeTransactions.date"),
      flex: 1,
      minWidth: 120,
      disableExport: true,
      renderHeader: () => <strong>{t("exchangeTransactions.date")}</strong>,
      valueGetter: (params) => {
        return formatDate(params.row.transactionDate, "DD.MM.YYYY");
      },
    },
    //TODO: Hente data fra BE for hvilken børs
    {
      headerClassName: "super-app-theme--header",
      field: "number",
      headerName: t("exchangeTransactions.number"),
      flex: 1,
      minWidth: 150,
      renderHeader: () => <strong>{t("exchangeTransactions.number")}</strong>,
      valueGetter: (params) => {
        return params.row.to.amount;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "transactionType",
      headerName: t("exchangeTransactions.transactionType"),
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <strong>{t("exchangeTransactions.transactionType")}</strong>
      ),
      valueFormatter: ({ value }) => {
        return t("transactions.transactionTypes." + value);
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "amountFrom",
      headerName: t("exchangeTransactions.amountFrom"),
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <strong>{t("exchangeTransactions.amountFrom")}</strong>
      ),
      valueGetter: (params) => {
        return params.row.from.coin
          ? params.row.from.amount + " " + params.row.from.coin.name
          : params.row.from.currency
            ? params.row.from.amount + " " + params.row.from.currency.name
            : "-";
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "amountTo",
      headerName: t("exchangeTransactions.amountTo"),
      flex: 1,
      minWidth: 150,
      renderHeader: () => <strong>{t("exchangeTransactions.amountTo")}</strong>,
      valueGetter: (params) => {
        return params.row.to.coin
          ? params.row.to.amount + " " + params.row.to.coin.name
          : params.row.to.currency
            ? params.row.to.amount + " " + params.row.to.currency.name
            : "-";
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "fee",
      headerName: t("exchangeTransactions.fee"),
      flex: 1,
      minWidth: 100,
      renderHeader: () => <strong>{t("exchangeTransactions.fee")}</strong>,
      valueGetter: (params) => {
        return params.row.fee.amount;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "...",
      flex: 1,
      disableExport: true,
      maxWidth: 50,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <MoreHorizIcon />,
      renderCell: (params) => {
        return (
          <TableActionsExchangeTransactions
            params={params}
            refetchPage={refetchPage}
          />
        );
      },
    },
  ];
};
