import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ExchangeTransactionsTable from "./components/ExchangeTransactionsTable";
import {Box, Container} from "@mui/material";

export default function ExchangeTransactions() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("exchangeTransactions.pageHelmet")}</title>
        <meta name="Blokc" content="Blokc" />
      </Helmet>
        <Box
            sx={{
                flexGrow: 1,
                py: 8,
                mx: "auto"
            }}
            component={"main"}
        >
            <Container maxWidth={"xxl"}>
      <ExchangeTransactionsTable filter={[]} />
            </Container>
        </Box>
    </>
  );
}
