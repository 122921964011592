import '../styles.css'
import {Box, Container, Stack, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from "swiper/react";
import {useState} from "react";
import 'swiper/swiper-bundle.css';
import {EffectCoverflow, Pagination} from "swiper";
import {section3Texts} from "src/pages/NotAuthenticated/Frontpage/FrontpageTexts";
import "../styles.css"
import mannImage from "src/assets/png/mann.png";

function isLightColor(color) {
    // Check the format of the color, HEX or RGB?
    let r = color[1];
    let g = color[2];
    let b = color[3];
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return true;
    } else {
        return false;
    }
}

export default function Carousel() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const {t} = useTranslation();
    const theme = useTheme()

    return (
        <Box component={"section"} sx={{
            pt: 1,
            mt: 5,
        }}>
            <Container maxWidth={"xxl"} padding={{xxl: "0px"}}>
                <Typography
                    mt={5}
                    variant={"h2"}
                >
                    {t("frontPage.section3header")}
                </Typography>

                <Box>
                    <Swiper
                        grabCursor={true}
                        draggable={true}
                        centeredSlides={true}
                        breakpoints={{
                            340: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            765: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                        }}
                        loop={true}
                        effect={"coverflow"}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            slideShadows: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        style={{overflow: ""}}
                        modules={[EffectCoverflow, Pagination]}
                        onSlideChange={(e) => setCurrentSlide(e.realIndex)}
                        className="swiper_container"
                    >
                        {section3Texts.map((item, index) => {
                            const realIndex = currentSlide % section3Texts.length;
                            const prevIndex = (realIndex - 1 + section3Texts.length) % section3Texts.length;
                            const nextIndex = (realIndex + 1) % section3Texts.length;

                            let color = theme.palette.primary[50];
                            if (index === realIndex) {
                                color = "#001846";
                            }
                            if (index === prevIndex || index === nextIndex) {
                                color = theme.palette.primary[100];
                            }


                            return (
                                <SwiperSlide key={index}>
                                    <Stack
                                        sx={{
                                            width: "30rem",
                                            height: "30rem",
                                            mt: "2rem",
                                            borderRadius: "50%",
                                            backgroundColor: color,
                                            opacity: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            mx: "auto",
                                            color: isLightColor(color) ? "#000000" : "#ffffff",
                                        }}
                                    >
                                        {item.icon}
                                        <Typography
                                            gutterBottom
                                            variant={"h5"}
                                            maxWidth={"20ch"}
                                            mt={1}
                                        >
                                            {t(item.contentHeader)}
                                        </Typography>
                                        <Typography
                                            variant={"body1"}
                                            sx={{
                                                width: "30ch",
                                            }}
                                        >
                                            {t(item.contentBody)}
                                        </Typography>
                                    </Stack>
                                </SwiperSlide>
                            )
                        })}
                        <SwiperSlide>
                            <Stack
                                sx={{
                                    width: "30rem",
                                    height: "30rem",
                                    mt: "2rem",
                                    borderRadius: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundImage: `url(${mannImage})`,
                                    backgroundSize: "cover",
                                    overflow: "hidden",
                                }}
                            ></Stack>
                        </SwiperSlide>
                        <div className="slider-controler">
                            <div className="swiper-pagination"></div>
                        </div>
                    </Swiper>
                </Box>
            </Container>
        </Box>
    )
}