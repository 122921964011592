import TableActionsSacrificePayments from "./TableActionsSacrificePayments";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment/moment";

export const Columns = (t, refetchSacrificePayment) => {
  return [
    {
      field: "amount",
      headerName: t("settings.sacrificePaymentsSettingsPage.amount"),
      width: 200,
      valueFormatter: ({ value }) => {
        return value;
      }
    },
    {
      field: "coin",
      headerName: t("settings.sacrificePaymentsSettingsPage.name"),
      width: 200,
      valueFormatter: ({ value }) => {
        return value.name;
      },
    },

    {
      field: "receivedDate",
      headerName: t("settings.sacrificePaymentsSettingsPage.receivedDate"),
      minWidth: 600,
      flex: 1,
      valueFormatter: ({ value }) => {
        return moment(value).format("DD.MM.YYYY");
      },
    },
    {
      field: "actions",
      headerName: t("settings.actions"),
      maxWidth: 50,
      align: "center",
      renderHeader: () => <MoreHorizIcon />,
      renderCell: (params) => {
        return (
          <TableActionsSacrificePayments
            itemId={params.row.id}
            refetchSacrificePayment={refetchSacrificePayment}
          />
        );
      },
    },
  ];
};
